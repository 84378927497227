import React from 'react';

export default {
    "privacy":{
        es:{
            "title":"Aviso de privacidad",
            "pars":[
                {"text":"Estimado cliente:"},
                {"text":"En virtud de que GALDISA, S.A. DE C.V., es responsable de recabar y tratar datos personales que ha obtenido o que llegue a obtener; hemos implementado mecanismos para garantizar la seguridad de sus datos personales (los  \"Datos Personales\"); por tal razón, hacemos el siguiente:"},
                {"text":"Aviso de Privacidad"},
                {"text":"Con fundamento en los artículos 15, 16 y demás aplicables de la Ley Federal de Protección de Datos Personales en Posesión de Particulares (la \"Ley\") y su reglamento hacemos de su conocimiento que GALDISA, S.A. DE C.V. (\"GALDISA\"), con domicilio en PIco de Verapaz 449-A-203 Jardines en la Montaña, código postal 14210 México, D.F, y página web http://www.galdisa.com/, es responsable de recabar, usar y proteger Datos Personales, en cumplimiento de los principios de licitud, calidad, consentimiento, información, finalidad, lealtad, proporcionalidad y responsabilidad."},
                {"text":"Nuestros clientes proporcionan Datos Personales a GALDISA, de manera directa, indirecta, personal o a través de sus subsidiarias, filiales, afiliadas, controladoras y/o aliadas comerciales."},
                {"text":"Los Datos Personales se recopilan a través de: sucursal(es); teléfono; correo electrónico y/o página web, y podrán incluir: (a) nombre(s) y apellidos; (b) dirección de correo electrónico; (c) alta de Registro Federal de Contribuyentes; y/o cualquier modificación; (d) clave del Registro Federal de Contribuyentes y/o cualquier modificación; (e) domicilio particular o dirección del evento, en su caso; y (f) números telefónicos (de trabajo, casa y móvil)."},
                {"text":"GALDISA no tiene habilitado algún elemento tecnológico para recabar información sobre usted como lo son cookies o web beacons."},
                {"text":"Los fines primarios aplicables a los Datos Personales (los \"Fines Primarios\") serán: (a) identificarlo como cliente o representante legal, interesado en nuestros productos; (b) conocer sus necesidades; (c) contactarlo y poder dar seguimiento a su interés en la compra de nuestros productos; (d) entrega de pedidos a domicilio; (e) elaboración de documentos, cotizaciones, facturas, recibos, y documentación relacionada con la relación; (f) atención de quejas y aclaraciones y cualquier actividad relacionada; (g) almacenamiento, y (h) dar cumplimiento a términos y condiciones que hayamos establecido con usted."},
                {"text":"Los fines secundarios aplicables a los Datos Personales (los \"Fines Secundarios\") serán: (a) informarle de nuevas promociones; (b) estadística; (c) mercadeo y (d) prospección."},
                {"text":"La confidencialidad de los Datos Personales está garantizada y los mismos están protegidos por medidas de seguridad administrativas, técnicas y físicas, para evitar daño, pérdida, alteración, destrucción, uso, acceso o divulgación indebida, por ejemplo los Datos Personales se encuentran en una base de datos interna, cuya administración es a través de claves de acceso que cambian en forma periódica y cuyo acceso está restringido a personas autorizadas; convenios de confidencialidad con su personal, entre otras."},
                {"text":"Usted tiene derecho al acceso, rectificación y cancelación de sus Datos Personales, a oponerse al tratamiento de los mismos (en su conjunto \"Derechos ARCO\"). Para ello, es necesario que usted o su representante legal presente una solicitud por escrito del ejercicio del Derecho ARCO dirigida al área de atención a clientes de GALDISA responsable de la protección de Datos Personales, ubicada en el domicilio antes indicado, o bien, se comunique vía correo electrónico con el área de atención a clientes de GALDISA a info@galdisa.com (\"Solicitud\"); debiendo recibir en ambos casos acuse de recibo, para que GALDISA quede vinculado al respecto. Dicha Solicitud deberá contener la siguiente información: (a) nombre(s) y apellidos de usted y su representante, en su caso; (b) dirección de correo electrónico para recibir notificaciones; (c) copia simple de la identificación oficial con fotografía de usted o su representante, en su caso, la representación legal de la persona que realiza la Solicitud a su nombre; (d) existencia de la representación, mediante instrumento público o carta poder firmada ante dos testigos, en su caso; (e) descripción clara y precisa de los Datos Personales respecto de los cuales busque ejercer algunos de los Derechos ARCO; (f) cualquier elemento o documento que facilite la localización de sus Datos Personales; y (g) firma de la Solicitud de usted o su representante."},
                {"text":"En caso de solicitar el ejercicio del derecho de: (a) Rectificación, deberá indicar las modificaciones a realizar y proporcionar la documentación que acredite y sustente la petición; y (b) Acceso; GALDISA proporcionara los Datos Personales vía correo electrónico y/o mediante cita en sucursal, a elección de GALDISA."},
                {"text":"En un plazo máximo de 20 días hábiles contados a partir del acuse de recepción de la Solicitud, se deberá atender la petición e informársele sobre la procedencia o improcedencia de la misma mediante un aviso enviado al correo electrónico proporcionado para recibir la notificación. En caso de resultar procedente su Solicitud, GALDISA deberá hacerla efectiva dentro de los 15 días hábiles siguientes, contados a partir de la recepción vía correo electrónico de la procedencia de su Solicitud."},
                {"text":"En adición, al procedimiento para el ejercicio del Derecho ARCO, usted tiene derecho, con relación a los Fines Secundarios a: (a) revocar o manifestar su negativa para tratar sus Datos Personales; y/o (b) ser incluido en el \"Listado de Exclusión\" habilitado y propio de GALDISA que nos permite limitar el uso y divulgación de Datos Personales. Para ambos efectos basta nos envíe, la solicitud para revocar o ser excluidos, la cual deberá contener: (a) nombre (s) y apellidos; y (b) cuenta de correo electrónico para responder su solicitud, está deberá ser dirigida al área de atención a clientes de GALDISA al siguiente correo electrónico info@galdisa.com, debiendo recibir acuse de recibo para que GALDISA quede vinculado al respecto. El acuse de recibo, incluye constancia de revocación y/o inscripción al \"Listado de Exclusión\". GALDISA tiene un plazo máximo para dar respuesta de 5 días hábiles"},
                {"text":"Toda la documentación deberá ser enviada en formato de archivo PDF, legible y completa, para que GALDISA pueda atender la Solicitud."},
                {"text":"Si usted considera que su derecho de protección de datos personales ha sido lesionado por GALDISA o presume que en el tratamiento de sus datos personales existe alguna violación a las disposiciones previstas en la Ley, podrá interponer su queja o denuncia correspondiente ante el Instituto Federal de Acceso a la Información y Protección de Datos, IFAI (www.ifai.gob.mx), dentro de los 15 días siguientes a la fecha en que reciba la respuesta de GALDISA o a partir de que concluya el plazo de 20 días contados a partir de la fecha del acuse de recepción de la Solicitud de ejercicio de derechos."},
                {"text":["Importante: Cualquier modificación a este Aviso de Privacidad se hará de su conocimiento en este mismo sitio de Internet:", <a href="http://www.galdisa.com/" target="_blank">http://www.galdisa.com</a>," en la parte inferior en el apartado Aviso de Privacidad, sin que sea necesario comunicarle dicha modificación al respecto a usted en forma individual."]}
            ],
            "refs":[]
        },
        en:{
            "title":"The Privacy notice",
            "pars": [ 
                {"text":"Dear customer"},
                {"text":"Galdisa, S.A. de C.V., is responsible for collecting and processing personal data that it has obtained or will obtain; we have implemented mechanisms to guarantee the security of your personal data (The “personal Data”); for this reason, we do the following:"},
                {"text":"Based on articles 15, 16 and other applicable articles of the Ley Federal de Protección de Datos Personales en Posesión de Particulares (the \"Law\") and its regulations, we inform you that GALDISA, S.A. DE C.V. (\"GALDISA\"), with address at Pico de Verapaz 449-A-203 Jardines en la Montaña, ZIP code 14210 Mexico, CDMX, and website http://www.galdisa.com/, is responsible for collecting, using and Protecting Personal Data, in compliance with the principles of legality, quality, consent, information, purpose, loyalty, proportionality and responsibility."},
                {"text":"Our clients provide us Personal Data, directly, indirectly, personally or through its subsidiaries, affiliates, office/or business partners and holding."},
                {"text":"Personal Data is collected through: branch ; telephone; email and / or website, and may include: (a) name and surname; (b) email address; (c) registration with Registro Federal de Contribuyente (RFC); and / or any modification; (d) private address or address of the event, if applicable; and (e) telephone numbers (office, home and mobile phone)."},
                {"text":"GALDISA does not have any technological element enabled to collect information about you, such as cookies or web beacons."},
                {"text":"The primary purposes applicable to Personal Data (the \"Primary Purposes\") will be: (a) identify you as a customer or legal representative, interested in our products; (b) know your needs; (c) contact you and be able to follow up on your interest in purchasing our products; (d) home delivery of orders; (e) preparation of documents, quotes, invoices, receipts, and documentation related to the relationship; (f) attention to complaints and clarifications and any related activity; (g) storage, and (h) comply with terms and conditions that we have established with you."},
                {"text":"The secondary purposes applicable to Personal Data (the \"Secondary Purposes\") will be: (a) inform you of new specialoffers; (b) statistics; (c) marketing and (d) prospecting."},
                {"text":"The confidentiality of Personal Data is guaranteed and they are protected by administrative, technical and physical security measures, to avoid damage, loss, alteration, destruction, use, access or improper disclosure, for example Personal Data is in an international data base, whose administration is through passwords that change periodically and whose access is restricted to authorized persons; confidentiality agreements with the staff, among others."},
                {"text":"You have the right to access, rectify and cancel your Personal Data, to oppose the treatment thereof (collectively \"ARCO Rights\"). For this, it is necessary that you or your legal representative submit a written request to exercise  the ARCO Law addressed to GALDISA is customer service area responsible for the protection of Personal Data, located at the above address, or contact via email with  GALDISA is customer service area at info@galdisa.com (\"Request\"); in both cases it must receive acknowledgment of receipt, so that GALDISA is linked to it. That Request must contain the following information: (a) name (s) and surnames of you and your representative, if applicable; (b) email address to receive notifications; (c) a simple copy your official ID or representative ID, if applicable, the legal representation of the person making the Request on your behalf; (d) existence of the representation, by public instrument or power of attorney signed before two witnesses, if applicable; (e) clear and precise description of the Personal Data with respect to which it seeks to exercise some of the ARCO Rights; (f) any element or document that facilitates the location of your Personal Data; and (g) The signature from you or your representative."},
                {"text":"In case of requesting the exercise of the right to: (a) Rectification, you must indicate the modifications to be made and provide the documentation that confirm and support the request; and (b) Access; GALDISA will provide Personal Data via email and / or by appointment at a branch, office at the choice of GALDISA."},
                {"text":"Within a maximum period of 20 business days from the acknowledgment of receipt of the Request, the request must be attended to and informed about its origin or inadmissibility by means of a notice sent to the email provided to receive the notification. In the event that your Request is appropriate, GALDISA must make it effective within the following 15 working days, counted from the receipt by email of the origin of your Request."},
                {"text":"In addition, to the procedure for the exercise of the ARCO Right, you have the right, in relation to Secondary Purposes, to: (a) revoke or express your refusal to process your Personal Data; and / or (b) be included in GALDISA's own \"Exclusion List\" that allows us to limit the use and disclosure of Personal Data. For both purposes, just send us the request to revoke or be excluded, which must contain: (a) name (s) and surnames; and (b) email account to respond  your request, it must be directed to the customer service area of GALDISA at the following email: info@galdisa.com, and must receive acknowledgment of receipt so that GALDISA is linked in this regard. The acknowledgment of receipt includes proof of revocation and / or registration to the \"Exclusion List\". GALDISA has a maximum term to respond to 5 business days"},
                {"text":"All the documentation must be sent in a legible and complete PDF file format, so that GALDISA can attend to the Request."},
                {"text":"If you consider that your right to protection of personal data has been violated by GALDISA or presume that in the treatment of your personal data there is a violation of the provisions of the Law, you can file your corresponding complaint or complaint with the Instituto Federal de Acceso a la Informacion y Protección de Datos Personales, IFAI (www.ifai.gob.mx), within 15 days from the date on which it you receive the response from GALDISA or in the end of the period of 20 days from the date of the acknowledgment of receipt of the Request."},
                {"text":["Important: Any modification to this Privacy Notice on this Internet site:", <a href="http://www.galdisa.com/" target="_blank">http://www.galdisa.com</a>," at the bottom in the Privacy Notice section, without being necessary to notify you  individually of such modification."]}
            ],
            "refs":[]

        }
    },
}