import React from 'react';


export const languages = [
    "en",
    "es"
];

export const LanguageContext = React.createContext({
    selectedLanguage: 'es',
    
});