import React from 'react';
export default {
    en:{
        somos:`We are an international company in the food industry that is dedicated to the planting, manufacturing, and distribution of products such as ingredients, snacks, peanut derivatives, grains and seeds for companies that produce and market food.`,
        proceso_title:['Productive', <br/>, 'process'],
        proceso_text:['We have a vast knowledge in each stage of the value chain through the vertical integration of our processes'],
        seguridad_title:['Food', <br/>, 'safety'],
        seguridad_text:['Our peanut manufacturing operations are certified under the safety standard FSSC 22000, Star K (Kosher), and we also hold the seal “México Calidad Suprema” (Mexican Supreme Quality) from SADER and SE'],
        more:'More',
        certifications_title: `Certifications`
    },
    es:{
        somos: `Somos una empresa internacional del sector agroalimentario que se dedica a la siembra, fabricación y distribución de productos como ingredientes, botanas, derivados del cacahuate, granos y semillas para compañías que producen y comercializan alimentos.`,
        proceso_title:['Proceso', <br/>, 'productivo'],
        proceso_text:['Tenemos vasto conocimiento en cada etapa de la cadena de valor, a través de la integración vertical de nuestro procesos'],
        seguridad_title:['Seguridad', <br/>, 'alimentaria'],
        seguridad_text:['Nuestras operaciones en manufactura de cacahuate están certificadas bajo la norma de inocuidad FSSC 22000, Star K (Kosher), USDA Organic. Ostentamos el sello México Calidad Suprema de la SADER y SE.'],
        more:'Ver más',
        certifications_title: `Certificaciones`
    }
};