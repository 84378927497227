import React from 'react';
import './App.css';

import {Link} from 'react-router-dom';

class Blog extends React.Component{
    render(){
        return(
          <div className="blog-post" style={{color:'#4d4d4d', textAlign:'left', ...this.props.style}} >
            <div style={{width:'100%', height:'200px'}}>
              <img src={this.props.imagen} style={{width:'100%', height:'100%', objectFit:'cover'}}alt='Imagen de blog'/>
            </div>
            <div className='blog-text'>
              <p className='texto-green texto-bold texto-m' style={{fontSize:'1.25rem'}}>{this.props.titulo}</p>
              <p className='texto-normal texto-s' style={{color:'#4d4d4d'}}>{this.props.descripcion}</p>
            </div>
            <Link className="LinkButton" style={{width:'50%'}} to={`/blog/${this.props.post}`}>{this.props.selectedLanguage === `es` ? "Ver más" : "More"}</Link>
          </div>
        )
    }}
    export default Blog;