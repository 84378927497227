import React from 'react';
export default {
    en:{
        news: `News`,
        servicios: 'Services',
        programa_title: 'Field program',
        programa_text: 'The commercial activity Galdisa has generated an economic income for more than 300 Mexicans families in the northern region of the country.',
        agroindustria_title: 'Agribusiness',
        agroindustria_text: 'Galdisa´s agricultural programs grant financing, machinery, training and technical support for our agronomists.',
        ciclo: 'Crop Cycle',
        campo: {
            text: 'Agricultural programs',
            l1: 'Agricultural',
            l2: 'Programs'
        },
        reciba:{
            text: 'Field reception',
            l1: 'Field',
            l2: 'reception'
        },
        planta:{
            text: 'Shelling and sorting plant',
            l1: "Shelling and",
            l2: "sorting",
            l3: 'plant'
        },
        procesadora:{
            text: 'Processing plant',
            l1: 'Processing',
            l2: 'plant'
        },
        seleccion:{
            text: 'Sorting plant',
            l1: 'Sorting',
            l2: 'plant'
        },
        distribucion:{
            text: 'Distribution centers',
            l1: 'Distribution',
            l2: 'centers'
        },
        materia:{
            text: 'Warehousing of raw materials',
            l1: 'Warehousing',
            l2: 'of raw',
            l3: 'materials'
        },
        oficina:{
            text: 'Corporate office',
            l1: 'Corporate',
            l2: 'office'
        },
        map_text:{
            l1:'The strategic location of our offices, plants and warehouses in Mexico and United States,',
            l2:'allow us to serve you with promptness in any part of the globe. We have a wide network',
            l3:'of distribution and strategic alliances with the main transportation companies.',
        },
        comercializacion: `Infrastructure`
    },
    es:{
        news: `Noticias de la industria`,
        servicios: 'Servicios',
        programa_title: 'Programa de campo',
        programa_text: 'La actividad comercial de Galdisa ha generado un recurso económico para cerca de 300 familias mexicanas en el norte del país.',
        agroindustria_title: 'Agroindustria',
        agroindustria_text: 'Los programas agrícolas de Galdisa otorgan financiamiento, capacitación y apoyo técnico de nuestros ingenieros agrónomos.',
        ciclo:'Ciclo de cultivo',
        campo: {
            text: 'Programas agrícolas',
            l1: 'Programas',
            l2: 'agrícolas'
        },
        reciba:{
            text: 'Reciba del campo',
            l1: 'Reciba',
            l2: 'del campo'
        },
        planta:{
            text: 'Planta descascadora y selección',
            l1: "Planta",
            l2: "descascadora",
            l3: 'y selección'
        },
        procesadora:{
            text: 'Planta procesadora',
            l1: 'Planta',
            l2: 'procesadora'
        },
        seleccion:{
            text: 'Planta de selección',
            l1: 'Planta de',
            l2: 'selección'
        },
        distribucion:{
            text: 'Centro de distribución',
            l1: 'Centro de',
            l2: 'distribución'
        },
        materia:{
            text: 'Almacén de materia prima',
            l1: 'Almacén de',
            l2: 'materia',
            l3: 'prima'
        },
        oficina:{
            text: 'Oficina corporativa',
            l1: 'Oficina',
            l2: 'corporativa'
        },
        map_text:{
            l1:'La localización estratégica de nuestras oficinas, plantas y bodegas en México y Estados Unidos,',
            l2:'nos permite atenderle con rapidez en cualquier parte del mundo. Contamos con una amplia red',
            l3:'de distribución propia y alianzas estratégicas con las principales empresas de transporte.',
        },
        comercializacion: `Infraestructura`

    }
};