export default {
    en:{
        aboutus:`ABOUT GALDISA`,
        somos:`WHO WE ARE`,
        historia: `HISTORY`,
        filosofia: `CORPORATE PHILOSOPHY`,
        proceso: `PRODUCTION PROCESS`,
        seguridad: `FOOD SAFETY`,
        sustentabilidad: `SUSTAINABILITY`,
        certificaciones: `CERTIFICATIONS`,
        productos: `PRODUCTS`,
        natural: `RAW PEANUTS`,
        procesados: `PROCESSED INGREDIENTS`,
        botanas: `SNACKS`,
        granel: `OTHER BULK PRODUCTS`,
        adicional: `ADDITIONAL INFORMATION`,
        servicios: `SERVICES`,
        nuestros: `OUR SERVICES`,
        programa: `GROWER PROGRAM`,
        ciclo: `CROP CYCLE`,
        infraestructura: `INFRASTRUCTURE`,
        contacto: `CONTACT`,
        cotizacion: `REQUEST A QUOTE`,
        trabajo: `CAREERS`
    },
    es:{
        aboutus:`ACERCA DE GALDISA`,
        somos:`QUIÉNES SOMOS`,
        historia: `HISTORIA DE GALDISA`,
        filosofia: `FILOSOFÍA`,
        proceso: `PROCESO PRODUCTIVO`,
        seguridad: `SEGURIDAD ALIMENTARIA`,
        sustentabilidad: `SUSTENTABILIDAD`,
        certificaciones: `CERTIFICACIONES`,
        productos: `PRODUCTOS`,
        natural: `CACAHUATE NATURAL`,
        procesados: `INGREDIENTES PROCESADOS`,
        botanas: `BOTANAS Y SNACKS`,
        granel: `OTROS PRODUCTOS A GRANEL`,
        adicional: `INFORMACIÓN ADICIONAL`,
        servicios: `SERVICIOS`,
        nuestros: `NUESTROS SERVICIOS`,
        programa: `PROGRAMA DE CAMPO`,
        ciclo: `CICLO DE CULTIVO`,
        infraestructura: `INFRAESTRUCTURA`,
        contacto: `CONTACTO`,
        cotizacion: `SOLICITAR COTIZACIÓN`,
        trabajo: `TRABAJA CON NOSOTROS`
    }
};