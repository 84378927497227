import React from "react";
import './App.css';
export default class Modal extends React.Component {
    onClose = e => {
        this.props.onClose && this.props.onClose(e);
      };
    render() {
        if (!this.props.show) {
          return null;
        }
        return (
            <div className="Modal" onClick={()=>{this.onClose()}}>
              <div style={{
                position:'relative',
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                justifyContent:'center',
                
              }} className='modal-wrapper'>
                {this.props.children}
                <button

                  onClick={e => {
                    this.onClose(e);
                  }}
                  style={{
                    position:'absolute',
                    top:'1rem',
                    right:'1rem',
                    cursor:'pointer',
                    fontSize:'1.5rem',
                    backgroundColor:'transparent',
                    ...this.props.closeStyle
                  }}
                >                  
                  x
                </button>
              </div>
            </div>
            
          );
    
  }
}