import React from 'react';
import './App.css';
import Boton from'./Boton.js';
import { isUndefined } from 'util';


class BarraOpciones extends React.Component{
constructor(props){
  super(props)
  this.state = { texto: "" }
  this.handleChangeText=this.handleChangeText.bind(this);
  }
  
  handleChangeText=(texto2)=>{
    this.setState({texto:texto2})
  //console.log('barra opc'+texto2);   
    }

  // componentDidMount() {    
  //   //obtenemos el primer elemento para mostrar por dafault su click
  //   var arreglo=this.props.conceptos
  //   var elemento=arreglo[0];    
  //   this.setState({texto:elemento.detalle})
  //  }
 
  
    render(){ 
      if (isUndefined(this.props.conceptos)==false){
        var altura;
        if(this.props.altura!=undefined)
            {altura=this.props.altura}
        return(
          
          <div className="Banner2rows" style={{minHeight:this.props.altura,  backgroundImage:'url('+this.props.imagen+')',color:'white'}}>
            <this.props.childComponent color={this.props.color} color2={this.props.color2} texto={this.state.texto} titulo={this.props.titulo} alineacion={this.props.alineacion} textColor={this.props.textColor}/>
              {/* se muestra el hijo,en este caso SOLOTEXTO */}
              <div className="BarraOpciones" style={{height:'80px', alignItems:'flex-start',justifyContent:'space-between', width:'60%', margin:'0 auto'}}>                  
                {this.props.conceptos.map((concepto)=>
                <Boton key={concepto.key.toString()} classes={this.props.classes} id={concepto.key} texto={concepto.texto} detalle={concepto.detalle} textColor={this.props.textColor}
                  clickHandler={this.handleChangeText}/>
                )}

              </div> 
          </div>
        )
              }else
              {return(<div className="BarraOpciones">NO SE DEFINIO LA LISTA DE ELEMENTOS</div>)}
      }}

export default BarraOpciones;