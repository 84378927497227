import React from 'react';
import './App.css';

import { HashLink } from 'react-router-hash-link';

import Modal from './Modal.js';
import BannerPrincipal from './BannerPrincipal.js';
import Cuadricula from './Cuadricula.js';
import BannerH from './BannerHorizontal.js';
import BannerHeader from './BannerHeader.js';
import Blog from './Blog.js';
import SoloTexto from './SoloTexto';
import Separador from './Separador';
import BarraOpciones from './BarraOpciones.js';
import {Link} from 'react-router-dom';
import Certificaciones from './Certificaciones.js';
import TimeLine from './TimeLine.js';
import Cotizar from './Cotizar.js';

import encabezado from './images/1x/banner1.jpg';
import galdisa_logo from './images/1x/galdisa_logo.png';
import banner1 from './images/1x/banner1.jpg';
import banner2 from './images/1x/galdisa_banner2.jpg';
import banner3 from './images/1x/galdisa_banner3.jpg';
import banner4 from './images/1x/banner4.jpg';
import banner6 from './images/1x/galdisa_banner6.jpg';
import bannerBlog from './images/1x/galdisa_blog_blog_header.jpg';
import prodvarios from './img_productosvarios.jpg';
import goldfritz from './img_goldfritz.jpg';
import fondoservicios from './images/1x/galdisa_servicios_bg.jpg';
import Siembra from './images/1x/galdisa_icono_siembra.png';
import ProveedorIngredientes from './images/1x/galdisa_icono_proveedor.png';
import ProdMedida from './images/1x/galdisa_icono_productos.png';
import MarcaBlanca  from './images/1x/galdisa_icono_marca.png';
import ventajasbg from './images/1x/galdisa_ventajas_bg.jpg';

import banner_ciclo1 from './images/1x/galdisa_servicios_ciclo_cultivo_1.jpg';
import banner_ciclo2 from './images/1x/galdisa_servicios_ciclo_cultivo_2.jpg';
import banner_ciclo3 from './images/1x/galdisa_servicios_ciclo_cultivo_3.jpg';
import banner_ciclo4 from './images/1x/galdisa_servicios_ciclo_cultivo_4.jpg';
import banner_ciclo5 from './images/1x/galdisa_servicios_ciclo_cultivo_5.jpg';

import comercializacion1 from './images/1x/servicios_comercializacion_1.png'
import comercializacion2 from './images/1x/servicios_comercializacion_1.png'
import comercializacion3 from './images/1x/servicios_comercializacion_1.png'
import comercializacion4 from './images/1x/servicios_comercializacion_1.png'
import comercializacion5 from './images/1x/servicios_comercializacion_1.png'
import comercializacion6 from './images/1x/servicios_comercializacion_1.png'
import comercializacion7 from './images/1x/servicios_comercializacion_1.png'
import comercializacion8 from './images/1x/servicios_comercializacion_1.png'

import cacahuate_background from './images/1x/galdisa_productos_fondo.png';
import proceso_productivo from './images/1x/galdisa_productos_proceso.png';
import cacahuate_38_42 from './images/1x/galdisa_productos_cacahuate_38_42.png';
import cacahuate_mitades from './images/1x/galdisa_productos_mitades.png';
import cacahuate_virginia from './images/1x/galdisa_productos_cacahuate_virginia.png';
import cacahuate_runner from './images/1x/galdisa_productos_cacahuate_runner.png';
import cacahuate_valencia from './images/1x/galdisa_productos_cacahuate_valencia.png';
import cacahuate_espanol from './images/1x/galdisa_productos_cacahuate_español.png';
import progscampo from './images/1x/galdisa_servicios_programa_campo.jpg';
import agroindustria from './images/1x/galdisa_servicios_agro_industria.jpg';
import mapa from './images/1x/galdisa_servicios_mapa.png'
import blog1Img from './images/1x/galdisa_blogImg1_mid.jpg';
import blog2Img from './images/1x/galdisa_blogImg2_mid.jpg';
import blog3Img from './images/1x/galdisa_blogImg3_mid.jpg';
import blog4Img from './images/1x/galdisa_blogImg4_mid.jpg';

import blog_1 from './images/1x/galdisa_blog_blog1.jpg';
import blog_2 from './images/1x/galdisa_blog_blog2.jpg';
import blog_3 from './images/1x/galdisa_blogImg5.jpg';
import blog_4 from './images/1x/galdisa_blogImg7.jpg';
import blog9 from './images/1x/galdisa_blogImg8.jpg';
import blog10 from './images/1x/galdisa_blogImg9.jpg';
import blog11 from './images/1x/galdisa_blogImg11.jpg';
import blog12 from './images/1x/galdisa_blogImg12.jpg';
import blog14 from './images/1x/galdisa_blogImg14.jpg';
import blog15 from './images/1x/galdisa_blogImg15.jpg';
import blog16 from './images/1x/galdisa_blogImg16.jpg';
import blog17 from './images/1x/galdisa_blogImg17.jpg';
import blog18 from './images/1x/galdisa_blogImg18.jpg';
import blog19 from './images/1x/galdisa_blogImg19.jpg';
import blog20 from './images/1x/galdisa_blogImg20.jpg';
import blog21 from './images/1x/galdisa_blogImg21.jpg';
import blog22 from './images/1x/galdisa_blogImg22.jpg';
import blog23 from './images/1x/galdisa_blogImg23.jpg';
import blog24 from './images/1x/galdisa_blogImg24.jpg';
import blog25 from './images/1x/galdisa_blogImg25.jpg';
import blog26 from './images/1x/galdisa_blogImg26.jpg';
import blog27 from './images/1x/galdisa_blogImg27.jpg';
import blog28 from './images/1x/galdisa_blogImg28.jpg';
import blog29 from './images/1x/galdisa_blogImg29.jpg';
import blog30 from './images/1x/galdisa_blogImg30.jpg';
import blog31 from './images/1x/galdisa_blogImg31.jpg';
import blog32 from './images/1x/galdisa_blogImg32.jpg';

import banner_mision from './images/1x/galdisa_acerca_de_mision.jpg';
import banner_vision from './images/1x/galdisa_acerca_de_vision.jpg';
import banner_promesa from './images/1x/galdisa_acerca_de_promesa.jpg';

import banner_proceso from './images/1x/galdisa_acerca_de_proceso_productivo.jpg';
import banner_seguridad_alimentaria from './images/1x/galdisa_acerca_de_seguridad_alimentaria.jpg';
import banner_responsabilidad from './images/1x/galdisa_acerca_de_responsabilidad.jpg';
import banner_sustentabilidad from './images/1x/galdisa_acerca_de_sustentabilidad.jpg';

import cert1 from './images/1x/galdisa_acerca_de_cert_K.png';
import cert2 from './images/1x/galdisa_acerca_de_USDAlogo.gif';
import cert3 from './images/1x/galdisa_acerca_de_cert_mexico.png';
import cert4 from './images/1x/galdisa_acerca_de_cert_veritas.png';
import cert5 from './images/1x/galdisa_acerca_de_cert_FSSC.png';
import cert6 from './images/1x/galdisa_acerca_de_cert_FDA.png';

import timeline1 from './images/1x/galdisa_acerca_de_tiempo_1960.jpg';
import timeline2 from './images/1x/galdisa_acerca_de_tiempo_1973.jpg';
import timeline3 from './images/1x/galdisa_acerca_de_tiempo_1983.jpg';
import timeline4 from './images/1x/galdisa_acerca_de_tiempo_1990.jpg';
import timeline5 from './images/1x/galdisa_acerca_de_tiempo_1993.jpg';
import timeline6 from './images/1x/galdisa_acerca_de_tiempo_1998.jpg';
import timeline7 from './images/1x/galdisa_acerca_de_tiempo_2009.jpg';
import timeline8 from './images/1x/galdisa_acerca_de_tiempo_2015.jpg';
import timeline9 from './images/1x/galdisa_acerca_de_tiempo_2017.jpg';
import timeline10 from './images/1x/galdisa_acerca_de_tiempo_2018.jpg';
import timeline_logo1990 from './images/1x/galdisa1990.jpg';
import timeline_logo2015 from './images/1x/galdisaUSA.jpg';

import cacahuate_vaina from './images/1x/productos_cacahuate_vaina.jpg';
import cacahuate_crudo from './images/1x/productos_cacahuate_crudo.jpg';
import cacahuate_blanqueado_entero from './images/1x/productos_cacahuate_blanequeado_entero.jpg';
import cacahuate_blanqueado_mitad from './images/1x/productos_cacahuate_blanqueado_mitad.jpg';

import cacahuate_horneado from './images/1x/productos_ingredientes_cacahuate_horneado.jpg';
import cacahuate_triturado from './images/1x/productos_ingredientes_cacahuate_triturado.jpg';
import mantequilla_cacahuate from './images/1x/productos_ingredientes_mantequilla_cacahuate.jpg';
import pasta_cacahuate from './images/1x/productos_ingredientes_pasta_cacahuate.jpg';

import cacahuate_bufalo from './images/1x/productos_botanas_cacahuate_bufalo.jpg';
import cacahuate_japones from './images/1x/productos_botanas_cacahuate_japones.jpg';
import cacahuate_enchilado from './images/1x/productos_botanas_cacahuate_enchilado.jpg';
import cacahuate_salado from './images/1x/productos_botanas_cacahuate_salado.jpg';
import cacahuate_botana_espanol from './images/1x/productos_botanas_cacahuate_espanol.jpg';
import cacahuate_holandes from './images/1x/productos_botanas_cacahuate_holandes.jpg';
import garbanzo_enchilado from './images/1x/productos_botanas_garbanzo_enchilado.jpg';
import haba_enchilada from './images/1x/productos_botanas_habaenchilada.jpg';
import pepita_amarilla_tostada from './images/1x/productos_pepita_amarilla_tostada.jpg';
import pepita_blanca_tostada from './images/1x/productos_pepita_blanca_tostada.jpg';
import maiz_queso from './images/1x/productos_botanas_maiz_queso.jpg';
import maiz_habanero from './images/1x/productos_botanas_maiz_habanero.jpg';
import maiz_chipotle from './images/1x/productos_botanas_maiz_chipotle.jpg';
import maiz_chile_limon from './images/1x/productos_botanas_maiz_chile_limon.jpg';


import otros_pistache from './images/1x/productos_otros_pistache.jpg';
import otros_pepita_amarilla from './images/1x/productos_otros_pepita_amarilla.jpg';
import otros_pepita_blanca from './images/1x/productos_otros_pepita_blanca.jpg';
import otros_arandano from './images/1x/productos_otros_arandano.jpg';
import otros_pepita_verde from './images/1x/productos_otros_pepita_verde.jpg';
import otros_jamaica from './images/1x/productos_otros_jamaica.jpg';
import otros_nuez_india from './images/1x/productos_otros_nuez_india.jpg';

import ficha_cacahuate_tostado from './fichas/Galdisa_Ficha_Cacahuate_Secado.pdf';
import ficha_enchilado from './fichas/Galdisa_Ficha_Cacahuate_enchilado.pdf';
import ficha_espanol from './fichas/Galdisa_Ficha_cacahuate_español.pdf';
import ficha_holandes from './fichas/Galdisa_Ficha_Cacahuate_holandés.pdf';
import ficha_japones from './fichas/Galdisa_Ficha_Cacahuate_japonés.pdf';
import ficha_pepita_amarilla_natural from './fichas/Galdisa_Ficha_Pepita_Amarilla.pdf';
import ficha_pepita_blanca_cruda from './fichas/Galdisa_Ficha_pepita_blanca_cruda.pdf';
import ficha_pepita_pama from './fichas/Galdisa_Ficha_pepita_pama.pdf';
import ficha_pepita_amarilla_tostada from './fichas/Galdisa_Ficha_pepita_amarilla_tostada.pdf';
import ficha_pepita_blanca_tostada from './fichas/Galdisa_Ficha_pepita_blanca_tostada.pdf';
import ficha_salado from './fichas/Galdisa_Ficha_cacahuate_salado.pdf';
import ficha_cacahuate_entero from './fichas/Galdisa_Ficha_Cacahuate_Blanqueado_Entero.pdf';
import ficha_cacahuate_mitad from './fichas/Galdisa_Ficha_Cacahuate_Blanqueado_Mitad.pdf';
import ficha_cacahuate_redskins from './fichas/Galdisa_Ficha_Cacahuate_Crudo_Redskins.pdf';
import ficha_cacahuate_vaina from './fichas/Galdisa_Ficha_Cacahuate_Vaina.pdf';
import ficha_garbanzo_enchilado from './fichas/Galdisa_Ficha_garbanzo_enchilado.pdf';
import ficha_haba_enchilada from './fichas/Galdisa_Ficha_haba_enchilada.pdf';
import ficha_mantequilla_cacahuate from './fichas/Galdisa_Ficha_Crema_Cacahuate.pdf';
import ficha_pasta_cacahuate from './fichas/Galdisa_Ficha_pasta_cacahuate.pdf';
import ficha_cacahuate_triturado_tostado from './fichas/Galdisa_Ficha_Cacahuate_Triturado.pdf';
import ficha_arandano from './fichas/Galdisa_Ficha_Arandano.pdf';
import ficha_cashews from './fichas/Galdisa_Ficha_NuezIndiaSalada.pdf';
import ficha_crunchy from './fichas/Galdisa_Ficha_craquen.pdf';
import ficha_hibiscus from './fichas/Galdisa_Ficha_Jamaica.pdf';
import ficha_pistachios from './fichas/Galdisa_Ficha_Pistache.pdf';

import ficha_cacahuate_tostado_en from './fichas/RoastedPeanuts.pdf';
import ficha_enchilado_en from './fichas/Galdisa_Oil roasted peanut snack with chili flavor.pdf';
import ficha_espanol_en from './fichas/Galdisa_Oil roasted redskin peanut.pdf';
import ficha_holandes_en from './fichas/Galdisa_Crunchy & Spicy peanuts.pdf';
import ficha_japones_en from './fichas/GaldisaJapanesePeanut.pdf';
import ficha_pepita_amarilla_natural_en from './fichas/Galdisa_YellowPumpkinSeeds.pdf';
import ficha_pepita_blanca_cruda_en from './fichas/Galdisa_SnowWhitePumpkinSeeds.pdf';
import ficha_pepita_pama_en from './fichas/Galdisa_PumpkinSeedsKernels.pdf';
import ficha_pepita_amarilla_tostada_en from './fichas/Galdisa_SaltedPumpkinSeeds.pdf';
import ficha_pepita_blanca_tostada_en from './fichas/Galdisa_WhiteRoastSeed.pdf';
import ficha_salado_en from './fichas/Galdisa_Oil roasted peanut snack with salt.pdf';
import ficha_cacahuate_entero_en from './fichas/Galdisa_Blanched Peanuts.pdf';
import ficha_cacahuate_mitad_en from './fichas/BlanchedSplits.pdf';
import ficha_cacahuate_redskins_en from './fichas/Redskins.pdf';
import ficha_cacahuate_vaina_en from './fichas/InshellPeanut.pdf';
import ficha_garbanzo_enchilado_en from './fichas/Galdisa_Chickpeas snacks.pdf';
import ficha_haba_enchilada_en from './fichas/Galdisa_FavaBeans.pdf';
import ficha_mantequilla_cacahuate_en from './fichas/PeanutButter.pdf';
import ficha_pasta_cacahuate_en from './fichas/PeanutPaste.pdf';
import ficha_cacahuate_triturado_tostado_en from './fichas/RoastedGranules.pdf';
import ficha_arandano_en from './fichas/Galdisa_Ficha_Blueberry.pdf';
import ficha_cashews_en from './fichas/Galdisa_Ficha_Cashews.pdf';
import ficha_crunchy_en from './fichas/Galdisa_Ficha_crunchy_flavor.pdf';
import ficha_hibiscus_en from './fichas/Galdisa_Ficha_Hibiscus.pdf';
import ficha_pistachios_en from './fichas/Galdisa_Ficha_Pistachios.pdf';

import ficha_maiz_queso_en from './fichas/Galdisa_Cheddar_cheese.pdf';
import ficha_maiz_queso_es from './fichas/Galdisa_Ficha_MaizQuesoCheddar.pdf';
import ficha_maiz_chipotle_en from './fichas/Galdisa_chipotle_corn.pdf';
import ficha_maiz_chipotle_es from './fichas/Galdisa_Ficha_MaizChipotle.pdf';
import ficha_maiz_habanero_en from './fichas/Galdisa_habanero_corn.pdf';
import ficha_maiz_habanero_es from './fichas/Galdisa_Ficha_MaizQuesoHabanero.pdf';
import ficha_maiz_limon_chile_en from './fichas/Galdisa_lemon_chili_corn.pdf';
import ficha_maiz_limon_chile_es from './fichas/Galdisa_Ficha_MaizChileLimon.pdf';


import home_naturales from './images/1x/productos_home_ingredientes.jpg';
import home_ingredientes from './images/1x/productos_home_naturales.jpg';
import home_botanas from './images/1x/productos_home_botanas.jpg';
import home_otros from './images/1x/productos_home_otros.jpg';

import video_home_high from './video/galdisa_home.mp4';
import video_home_low from './video/galdisa_home_small.mp4';

import dict_acerca from './dict/AcercaDe';
import dict_productos from './dict/Products';
import dict_servicios from './dict/Servicios';
import dict_contacto from './dict/Contacto';
import dict_conoce from './dict/Conoce';


import blogs from './data/blogs';

class Opc extends React.Component{
  constructor(props){
    super(props);   
    this.state = {
      showModal: false,
      disableSendButton: false,
      nombre: ``,
      industria: ``,
      email:``,
      producto:``,
      volumen_presentacion:``,
      mensaje:``,
      telefono:``,
      requiere_certificacion:'no',
      certificacion:'',
      errors:{}
    } 
    this.showProduct = this.showProduct.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }
  
  
    static defaultProps ={
        val:0 
      }    
  showProduct(product){
    this.setState({
      showModal:true,
      product:product
    });
  }
  submitForm(){
    console.log(this.state);
    
    const checkValue = (val)=>{
      return val && val.length > 0;
    };
    const emailValid = this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    let good = true;
    if(!emailValid){
      this.setState({emailError: true});
      this.emailInput.focus();
      good = false;
    }
    const names = [ 
      'nombre',
      'industria',
      'mensaje',
      'producto',
      'volumen_presentacion',
      'telefono'
    ];
    let errs = {};

    names.forEach((prop)=>{
      if(!checkValue(this.state[prop])){
        good = false;
        errs[prop] = true;
      }
    });
    if(!good){
      this.setState({
        errors: errs
      });  
    }

    if(!this.state.producto || this.state.producto.length === 0){
      this.setState({productError: true});
      this.productoInput.focus();
      good = false;
    }
    if(!this.state.volumen_presentacion || this.state.volumen_presentacion.length === 0){
      this.setState({volumenError: true});
      this.volumenInput.focus();
      good = false;
    
    }
    if(!good){
      return false;
    }
    //Aquí envío.
    this.setState({disableSendButton: true});
    const postData = new URLSearchParams();
    postData.append('nombre', this.state.nombre);
    postData.append('industria', this.state.industria);
    postData.append('email', this.state.email);
    postData.append('mensaje', this.state.mensaje);
    postData.append('producto', this.state.producto);
    postData.append('volumen_presentacion', this.state.volumen_presentacion);
    postData.append('telefono', this.state.telefono);
    postData.append('requiere_certificacion', this.state.requiere_certificacion);
    if(this.state.requiere_certificacion === 'yes'){
      postData.append('certificacion', this.state.certificacion);
    }
    fetch("https://www.galdisa.com/contacto_2.php", 
      {
        method:'POST',
        body: postData,
      }
    ).then((response)=>{
      if(response.ok){
        this.setState({thanks:true});
      }else{
        this.setState({errorProcess:true, disableSendButton:false});
      }
    }).catch((error)=>{
      this.setState({errorProcess:true, disableSendButton: false});
    })
    return;
  }

      render(){
        
 var val=this.props.val
 var banners = {
   en:[
    {
      imagen: banner1,
      text: <div className="textoHeader" style={{position:'absolute', top:'45%', left:'50%', display:'flex', 'flex-direction':'column', transform:'translate(-50%,0)'}}>
        <span className='texto-xl texto-harabara texto-white texto-bolder'>Quality</span>
        <span className='texto-xl texto-harabara texto-white texto-bolder' style={{textAlign:'center'}}>is our <span className='texto-bold texto-green'>passion</span></span>
      </div>
    },
    {
      imagen: banner2,
      text: <div className="textoHeader" style={{position:'absolute', top:'40%', left:'40%', display:'flex', 'flex-direction':'column', textAlign:'right'}}>
        <span className='texto-xl texto-harabara texto-white texto-bold'>Somos calidad</span>
        <span className='texto-m texto-white texto-normal'>Usamos <span className='texto-bold'>siempre semillas de <span className="texto-green">primera</span></span></span>
        <span className='texto-m texto-bold texto-green'>generación<span className='texto-normal texto-white'> y certificadas.</span></span>
      </div>,
    },
    {
      imagen: banner3,
      text: <div className="textoHeader" style={{position:'absolute', top:'43%', left:'2.5rem', display:'flex', 'flex-direction':'column', textAlign:'left', backgroundColor:`rgba(60,60,60,0.3)`}}>
        <span className='texto-xl texto-harabara texto-white texto-bold'>Commitment</span>
        <span className='texto-m texto-white texto-normal'>We encourage responsible </span>
        <span className='texto-m texto-bold texto-green'>fair trade in<span className='texto-normal texto-white'> all</span></span>
        <span className="texto-m texto-normal texto-white">our production processes</span>
      </div>,
    },
    {
      imagen: banner4,
      overlay:true,
      text: <div className="textoHeader" style={{position:'absolute', top:'50%', left:'50%', transform: 'translate(-50%, -50%)',
      display:'flex', 'flex-direction':'column', textAlign:'center'}}>
        <img src={galdisa_logo} alt="Logo" style={{width: '100%'}} />  
      </div>
    },
    {
      imagen: bannerBlog,
      text: <div className="textoHeader" style={{position:'absolute', top:'54%', left:'5%', display:'flex', textAlign:'left', 'flex-direction':'column'}}>
        <span className='texto-xl texto-harabara texto-white texto-bold'>Discover</span>
        <span className='texto-m texto-white texto-normal'><span className='texto-white texto-bold'>tips, news and trends</span></span>
      </div>
    },
    {
      imagen: banner6,
      text: <div className="textoHeader" style={{position:'absolute', top:'54%', left:'55%', display:'flex', 'flex-direction':'column'}}>
        <span className='texto-xl texto-harabara texto-white texto-bold'>Ingredientes</span>
        <span className='texto-m texto-white texto-normal'>Materias primas e ingredientes a <span className="texto-green texto-bold">la medida</span></span>
      </div>
    }
  ],
  es:[
    {
      imagen: banner1,
      text: <div className="textoHeader" style={{position:'absolute', top:'45%', left:'50%', display:'flex', 'flex-direction':'column', transform:'translate(-50%,0)'}}>
        <span className='texto-xl texto-harabara texto-white texto-bolder'>Dedicación</span>
        <span className='texto-m texto-white' style={{textAlign:'center'}}>Para que los <span className='texto-bold texto-green'>consumidores</span></span>
        <span className='texto-m texto-white' style={{textAlign:'center'}}>reciban productos de <span className='texto-bold texto-green'>calidad</span></span>
        <span className='texto-m texto-white' style={{textAlign:'center'}}>en su mesa.</span>
      </div>
    },
    {
      imagen: banner2,
      text: <div className="textoHeader" style={{position:'absolute', top:'40%', left:'40%', display:'flex', 'flex-direction':'column', textAlign:'right'}}>
        <span className='texto-xl texto-harabara texto-white texto-bold'>Somos calidad</span>
        <span className='texto-m texto-white texto-normal'>Usamos <span className='texto-bold'>siempre semillas de <span className="texto-green">primera</span></span></span>
        <span className='texto-m texto-bold texto-green'>generación<span className='texto-normal texto-white'> y certificadas.</span></span>
      </div>
    },
    {
      imagen: banner3,
      text: <div className="textoHeader" style={{position:'absolute', top:'43%', left:'2.5rem', display:'flex', 'flex-direction':'column', textAlign:'left'}}>
        <span className='texto-xl texto-harabara texto-white texto-bold'>Compromiso</span>
        <span className='texto-m texto-white texto-normal'>Incentivamos el comercio justo y</span>
        <span className='texto-m texto-bold texto-green'>socialmente responsable<span className='texto-normal texto-white'> en todos</span></span>
        <span className="texto-m texto-normal texto-white">nuestros procesos de producción</span>
      </div>
    },
    {
      imagen: banner4,
      overlay:true,
      text: <div className="textoHeader" style={{position:'absolute', top:'50%', left:'50%', transform: 'translate(-50%, -50%)',
      display:'flex', 'flex-direction':'column', textAlign:'center'}}>
        <img src={galdisa_logo} alt="Logo" style={{width: '100%'}} />  
      </div>
    },
    {
      imagen: bannerBlog,
      text: <div className="textoHeader" style={{position:'absolute', top:'54%', left:'5%', display:'flex', textAlign:'left', 'flex-direction':'column'}}>
        <span className='texto-xl texto-harabara texto-white texto-bold'>Lo que debes saber</span>
        <span className='texto-m texto-white texto-normal'>Descubre <span className='texto-green texto-bold'>tips, noticias y tendencias</span></span>
        <span className="texto-m texto-normal texto-white">que tenemos preparados para ti</span>
      </div>
    },
    {
      imagen: banner6,
      text: <div className="textoHeader" style={{position:'absolute', top:'54%', left:'55%', display:'flex', 'flex-direction':'column'}}>
        <span className='texto-xl texto-harabara texto-white texto-bold'>Ingredientes</span>
        <span className='texto-m texto-white texto-normal'>Materias primas e ingredientes a <span className="texto-green texto-bold">la medida</span></span>
      </div>
    }
  ]
};
if (val==1){
    
  let banner_sec =   {
    es: [{
      imagen: banner_mision,
      text: <div className="textoHeader" style={{width:'100%', position:'absolute', alignItems:'center', justifyContent:'flex-start', top:'2rem', left:'50%', display:'flex', transform:'translateX(-50%)', 'flexDirection':'column', height:'75%'}}>
        <span className='texto-xl texto-harabara texto-white texto-bolder'>Misión</span>
        <span className='texto-m texto-white texto-harabara' style={{marginTop:'1rem', fontKerning:'normal', letterSpacing:'.75rem'}}>Satisfacer</span>
        <span className='texto-m texto-white' style={{marginTop:'2rem',width:'60%'}}>Satisfacer al mundo con los mejores productos de cacahuate y otros alimentos que alegran la vida</span>
      </div>
    },
    {
      imagen: banner_vision,
      text: <div className="textoHeader" style={{width:'100%', position:'absolute', alignItems:'center', justifyContent:'flex-start', top:'2rem', left:'50%', display:'flex', transform:'translateX(-50%)', 'flexDirection':'column', height:'75%'}}>
        <span className='texto-xl texto-harabara texto-white texto-bolder'>Visión</span>
        <span className='texto-m texto-white  texto-harabara' style={{marginTop:'1rem', letterSpacing:'0.75rem'}}>Reconocimiento</span>
        <span className='texto-m texto-white' style={{marginTop:'2rem',width:'60%'}}>Ser reconocidos como líderes en nuestros productos, innovando y con sentido humano</span>
      </div>      },
    {
      imagen: banner_promesa,
      text: <div className="textoHeader" style={{width:'100%', position:'absolute', alignItems:'center', justifyContent:'flex-start', top:'2rem', left:'50%', display:'flex', transform:'translateX(-50%)', 'flexDirection':'column', height:'75%'}}>
        <span className='texto-xl texto-harabara texto-white texto-bolder'>Promesa</span>
        <span className='texto-m texto-white  texto-harabara' style={{marginTop:'1rem', letterSpacing:'0.75rem'}}>Calidad</span>
        <span className='texto-m texto-white' style={{marginTop:'2rem', width:'60%'}}>Nos comprometemos a ofrecer el mejor sabor y calidad de todos nuestros productos porque nuestros clientes es lo que esperan y merecen</span>
      </div>      },
    ],
    en:[{
      imagen: banner_mision,
      text: <div className="textoHeader" style={{width:'100%', position:'absolute', alignItems:'center', justifyContent:'flex-start', top:'2rem', left:'50%', display:'flex', transform:'translateX(-50%)', 'flexDirection':'column', height:'75%'}}>
        <span className='texto-xl texto-harabara texto-white texto-bolder'>Mission</span>
        <span className='texto-m texto-white texto-harabara' style={{marginTop:'1rem', letterSpacing:'0.75rem'}}>Satisfy</span>
        <span className='texto-m texto-white' style={{marginTop:'2rem',width:'60%'}}>To satisfy the world with the best peanut products and other foods that nourish and make our lives happy</span>
      </div>
    },
    {
      imagen: banner_vision,
      text: <div className="textoHeader" style={{width:'100%', position:'absolute', alignItems:'center', justifyContent:'flex-start', top:'2rem', left:'50%', display:'flex', transform:'translateX(-50%)', 'flexDirection':'column', height:'75%'}}>
        <span className='texto-xl texto-harabara texto-white texto-bolder'>Vision</span>
        <span className='texto-m texto-white texto-harabara' style={{marginTop:'1rem', letterSpacing:'0.75rem'}}>Recognition</span>
        <span className='texto-m texto-white' style={{marginTop:'2rem',width:'60%'}}>To be recognized as leaders in our products, innovating and with human approach</span>
      </div>      },
    {
      imagen: banner_promesa,
      text: <div className="textoHeader" style={{width:'100%', position:'absolute', alignItems:'center', justifyContent:'flex-start', top:'2rem', left:'50%', display:'flex', transform:'translateX(-50%)', 'flexDirection':'column', height:'75%'}}>
        <span className='texto-xl texto-harabara texto-white texto-bolder'>Commitment</span>
        <span className='texto-m texto-white texto-harabara' style={{marginTop:'1rem', letterSpacing:'0.75rem'}}>Quality</span>
        <span className='texto-m texto-white' style={{marginTop:'2rem', width:'60%'}}>We are focused in offering the best flavor and quality in our products, which is what our customers expect and deserve</span>
      </div>      },
    ]
  };
    let banner_third =   {
        es: [{
        imagen: banner_responsabilidad,
        text: <div className="textoHeader" style={{width:'100%', position:'absolute', alignItems:'center', justifyContent:'flex-start', top:'2rem', left:'50%', display:'flex', transform:'translateX(-50%)', 'flexDirection':'column', height:'75%'}}>
          <span className='texto-xl texto-harabara texto-white texto-bolder'>Responsabilidad social</span>
          <span className='texto-m texto-white' style={{marginTop:'1rem',width:'80%'}}>Nuestra actividad de abastecimiento genera recursos económicos para cerca de 300 familias mexicanas, mejorando así las técnicas de cultivo en el norte del país.</span>
        </div>
      },
      {
        imagen: banner_sustentabilidad,
        text: <div className="textoHeader" style={{width:'100%', position:'absolute', alignItems:'center', justifyContent:'flex-start', top:'2rem', left:'50%', display:'flex', transform:'translateX(-50%)', 'flexDirection':'column', height:'75%'}}>
          <span className='texto-xl texto-harabara texto-white texto-bolder'>Sustentabilidad</span>
          <span className='texto-m texto-white' style={{marginTop:'1rem',width:'80%'}}>Agradecidos con el planeta y con lo que nos proporciona, en <span className="verde texto-harabara"> GALDISA</span> implementamos acciones en pro del medio ambiente: </span>
          <ul className="sustentabilidad" style={{display:'flex', flexDirection:'row', flexWrap:'wrap', columns:'2', textAlign:'left', listStyle:'none'}}>
            <li style={{width:'50%'}}>Control biológico de plagas</li>
            <li style={{width:'50%'}}>Uso de compostas</li>
            <li style={{width:'50%'}}>Ahorro de combustibles</li>
            <li style={{width:'50%'}}>Ahorro de electricidad</li>
            <li style={{width:'50%'}}>Reciclaje</li>
            <li style={{width:'50%'}}>Generación de electricidad</li>
            <li style={{width:'50%'}}></li>
          </ul>
        </div>      
      }
    ],
    en:[{
      imagen: banner_responsabilidad,
      text: <div className="textoHeader" style={{width:'100%', position:'absolute', alignItems:'center', justifyContent:'flex-start', top:'2rem', left:'50%', display:'flex', transform:'translateX(-50%)', 'flexDirection':'column', height:'75%'}}>
        <span className='texto-xl texto-harabara texto-white texto-bolder'>Social responsibility</span>
        <span className='texto-m texto-white' style={{marginTop:'1rem',width:'80%'}}>Our supply activities generates economic resources for nearly 300 Mexican families, thus improving cultivation techniques in the north of the country.</span>
      </div>
    },
    {
      imagen: banner_sustentabilidad,
      text: <div className="textoHeader" style={{width:'100%', position:'absolute', alignItems:'center', justifyContent:'flex-start', top:'2rem', left:'50%', display:'flex', transform:'translateX(-50%)', 'flexDirection':'column', height:'75%'}}>
        <span className='texto-xl texto-harabara texto-white texto-bolder'>Sustainability</span>
        <span className='texto-m texto-white' style={{marginTop:'1rem',width:'80%'}}>At <span className="verde texto-harabara"> GALDISA</span>, we are thankful with what our planet has provided to us, therefore we have implemented programs to safeguard our environment such as : </span>
        <ul className="sustentabilidad" style={{display:'flex', flexDirection:'row', flexWrap:'wrap', columns:'2', textAlign:'left', listStyle:'none'}}>
          <li style={{width:'50%'}}>Pest control</li>
          <li style={{width:'50%'}}>Organic fertilizers</li>
          <li style={{width:'50%'}}>Fuel savings</li>
          <li style={{width:'50%'}}>Saving electricity</li>
          <li style={{width:'50%'}}>Recycling</li>
          <li style={{width:'50%'}}>Power generation</li>
          <li style={{}}></li>
        </ul>
      </div>      
    } ]
  };
  const banners_timeline = {
    es:[
      {
        imagen: timeline1,
        year:1960,
        title:'Casa Galindo',
        text:'Don Antonio Galindo, fundador de Casa Galindo, empieza la comercialización de cacahuates, semilla de calabaza, pistaches, nueces y otros frutos secos en la vieja Merced, que fue el Mercado más importante en la Ciudad de México.'
      },
      {
        imagen: timeline2,
        year:1973,
        title:'Nuestros inicios',
        text:'Cambia el nombre a “Antonio Galindo e Hijos”. Comienzan a hacer el proceso de tostado y blanqueado de cacahuate.'
      },
      {
        imagen: timeline3,
        year:1983,
        title:'Central de abastos',
        text:'Inauguración de un punto de venta en la Central de Abastos de la Ciudad de México, de una planta procesadora en el estado de Chiapas y de centros de acopio en Oaxaca y Puebla.'
      },
      {
        imagen: timeline4,
        year:1990,
        title:'Galdisa S.A DE C.V',
        text:'Fundación de la empresa Galdisa, S.A. de C.V. Productora y Comercializadora.',
        logo:timeline_logo1990,
        width:"20%"
      },
      {
        imagen: timeline5,
        year:1993,
        title:'Industrialización',
        text:'Apertura de una nueva y moderna planta de alta capacidad para el blanqueado e industrialización en el entonces Distrito Federal.'
      },
      {
        imagen: timeline6,
        year:1998,
        title:'Incursión en Chihuahua',
        text:'Creación de “Agroindustrias Galdisa” y de nuestra primera planta de descascarado en Chihuahua.'
      },
      {
        imagen: timeline7,
        year:2009,
        title:'Crecemos',
        text:'Construcción de nuestra segunda Planta de acopio en el estado de Chihuahua.'
      },
      {
        imagen: timeline8,
        year:2015,
        title:'Expansión',
        text:'Inicio de operaciones en Conroe, TX mediante oficina de ventas.',
        logo: timeline_logo2015,
        width:"30%"
      },
      {
        imagen: timeline9,
        year:2017,
        title:'Planta EUA',
        text:'El 23 de junio del 2017 en Conroe Texas, se inaugura nuestra planta procesadora de cacahuate que cuenta con tecnología de punta.'
      },
      {
        imagen: timeline10,
        year:2018,
        title:'Liderazgo',
        text:'Galdisa se consolida como líder en la selección, producción y transformación de cacahuates y semillas a nivel nacional.'
      }

    ],
    en:[
      {
        imagen: timeline1,
        year:1960,
        title:'Casa Galindo',
        text:'Don Antonio Galindo, founder of Casa Galindo, started a peanut, pumpkin seed, pistachios, walnuts and other dried fruits trading operation in the old “Merced” wholesale market, which at the time was the most important marketplace in Mexico City.'
      },
      {
        imagen: timeline2,
        year:1973,
        title:'Our founding history',
        text:'We changed our name to ¨Antonio Galindo & Sons” (Antonio Galindo e hijos). We started processing roasted and blanching peanuts.'
      },
      {
        imagen: timeline3,
        year:1983,
        title:'Central Market',
        text:'Grand opening of a new  point-of-sale in the “Central Market” (Central de Abastos) in Mexico City, of a processing plant in the state of Chiapas, and buying points in the states of Oaxaca and Puebla.'
      },
      {
        imagen: timeline4,
        year:1990,
        title:'Galdisa S.A DE C.V',
        text:'Creation of Galdisa S.A. de C.V. manufacturing and trading enterprise.',
        logo:timeline_logo1990,
        width:"20%"
      },
      {
        imagen: timeline5,
        year:1993,
        title:'Industrialization',
        text:'Opening of a new and state of the art high volume capacity plant for the blanching process in Mexico City.'
      },
      {
        imagen: timeline6,
        year:1998,
        title:'Arrival to Chihuahua',
        text:'Opening of Agroindustrias Galdisa and our first peanut shelling operation in Chihuahua.'
      },
      {
        imagen: timeline7,
        year:2009,
        title:'Growing',
        text:'Construction of our second collection plant in the state of Chihuahua.'
      },
      {
        imagen: timeline8,
        year:2015,
        title:'Growth',
        text:'We started operating in Houston, Texas. First international sales office.',
        logo: timeline_logo2015,
        width:"30%"
      },
      {
        imagen: timeline9,
        year:2017,
        title:'USA processing plant',
        text:'On June 23, 2017 in Conroe Texas, our state-of-the-art peanut processing plant will be inaugurated.'
      },
      {
        imagen: timeline10,
        year:2018,
        title:'Leadership',
        text:'Galdisa consolidates itself as the leader in the production, manufacturing and peanut processing at a national level.'
      }
    ]
  };
  return(    
    <div className="Contenedor">
      <BannerHeader selectedLanguage={this.props.selectedLanguage} descripcion='' imagen={encabezado} banners={[banners[this.props.selectedLanguage][3]]} className="HeaderPrincipal"/>
      <div style={{margin:'5rem 0', width:'100%', padding:'2rem', backgroundColor:'rgba(61,174,43,0.23)', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}} id="somos">
        <p style={{width:'70%',  color:'black'}} className="texto-m">{dict_acerca[this.props.selectedLanguage].somos}</p>
        {this.props.selectedLanguage === "es" && 
          <Link style={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}} className="texto-green" to="/conoce" rel="noopener noreferrer" target="_blank">
            <span style={{width:'70%',  color:'black'}} className="texto-m texto-green texto-bold">VER VIDEO</span>
            <span style={{width:'70%',  color:'black'}} className="texto-m texto-green texto-bold">Te queremos contar un sueño</span>
          </Link>
        }
        {this.props.selectedLanguage === "en" && 
          <Link style={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}} className="texto-green" to="/conoce" rel="noopener noreferrer" target="_blank">
            <span style={{width:'70%',  color:'black'}} className="texto-m texto-green texto-bold">WATCH VIDEO</span>
            <span style={{width:'70%',  color:'black'}} className="texto-m texto-green texto-bold">We want to tell you about our dream</span>
          </Link>
        }        
      </div>
      <TimeLine id="historia" banners={banners_timeline[this.props.selectedLanguage]}/>
      <BannerHeader selectedLanguage={this.props.selectedLanguage} id="filosofia" descripcion='' imagen={encabezado} banners={banner_sec[this.props.selectedLanguage]} style={{height:'300px'}} />
      <div id="proceso" className="Banner2cols" style={{borderTop:'10px solid #43B02A'}}>
      <BannerPrincipal descripcion='' imagen={banner_proceso}>
          <div id="triangle-left" >
          </div>
      </BannerPrincipal>
      <SoloTexto titulo={dict_acerca[this.props.selectedLanguage].proceso_title} texto={dict_acerca[this.props.selectedLanguage].proceso_text} color='verde' alineacion="center">
      <HashLink className="LinkButton"  style={{ width:"50%", margin:'0px auto', float:"center"}} scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -120) }} to="/detailproducts#proceso">{dict_acerca[this.props.selectedLanguage].more}</HashLink>
      </SoloTexto>
    </div>
    <div id="seguridad" className="Banner2cols" style={{borderBottom:'10px solid #43B02A'}}>
      <SoloTexto titulo={dict_acerca[this.props.selectedLanguage].seguridad_title} texto={dict_acerca[this.props.selectedLanguage].seguridad_text} color='verde' alineacion="center" />
      <BannerPrincipal descripcion='' imagen={banner_seguridad_alimentaria}>
          <div id="triangle-right" >
          </div>
      </BannerPrincipal>
    </div>
    <BannerHeader selectedLanguage={this.props.selectedLanguage}  id="sustentabilidad" descripcion='' imagen={encabezado} banners={banner_third[this.props.selectedLanguage]} style={{height:'350px'}}/>
    <div id="certifications" style={{width:'100%', borderBottom:'10px solid #43B02A', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
      <SoloTexto style={{width:'calc(100%)', padding:'2rem'}} titulo={dict_acerca[this.props.selectedLanguage].certifications_title} color='verde' alineacion="center"/>
      <div style={{width:'60%', alignItems:'center', justifyContent:'space-between', display:'flex', flexWrap:'wrap', flexDirection:'row', padding:'2rem'}}>
        <div style={{margin:'0 0rem', width:'30%'}}>
          <img src={cert1} style={{width:'50%'}} alt=""/>
        </div>
        <div style={{margin:'0 0rem', width:'30%'}}>
          <img src={cert2} style={{width:'50%'}} alt=""/>
        </div>
        <div style={{margin:'0 0rem', width:'30%'}}>
          <img src={cert3} style={{width:'60%'}} alt=""/>
        </div>
        <div style={{margin:'0 0rem', width:'30%'}}>
          <img src={cert4} style={{width:'60%'}} alt=""/>
        </div>
        <div style={{margin:'0 0rem', width:'30%'}}>
          <img src={cert5} style={{width:'100%'}} alt=""/>
        </div>
        <div style={{margin:'0 0rem', width:'30%'}}>
          <img src={cert6} style={{width:'50%'}} alt=""/>
        </div>
      </div> 
    </div>
    <Cotizar selectedLanguage={this.props.selectedLanguage} />


    </div>)
  }
  if (val==2){
    let cacahuates = {
      es: [
        {
          key:1, 
          nombre:['Cacahuate',<br></br>,'en vaina', <br></br>, 'tostado'], 
          imagen:cacahuate_vaina, 
          description: 'Cacahuate con cáscara que generalmente se utiliza para tostar.',
          ficha: ficha_cacahuate_vaina
        },
        {
          key:2,
          nombre:['Cacahuate',<br></br>,'runner'],
          imagen:cacahuate_crudo,
          description: 'Es un grano crudo con cutícula, tipo runner. Se comercializa en diferentes calibres. Son prácticamente libres de materia extraña, daño y defectos menores.',
          ficha: ficha_cacahuate_redskins
        },
        {
          key:3, 
          nombre:['Cacahuate',<br></br>,'blanqueado'], 
          imagen:cacahuate_blanqueado_entero,
          description: "Es un cacahuate sometido a un proceso térmico para retirar la cutícula." ,
          ficha: ficha_cacahuate_entero
        },
        {
          key:4, 
          nombre:['Cacahuate',<br></br>,'blanqueado', <br></br>, 'mitad'], 
          imagen:cacahuate_blanqueado_mitad,
          description: 'Es un cacahuate sometido a un proceso térmico para retirar la cutícula. Puede utilizarse para freir, tostar y posteriormente elaborar diversos dulces y botanas.',
          ficha: ficha_cacahuate_mitad
        },
      ],
      en:[
        {
          key:1, 
          nombre:['Inshell', <br/>, 'peanut'], 
          imagen:cacahuate_vaina, 
          description: 'Inshell peanuts fresh and clean, regularly used for roasting.',
          ficha: ficha_cacahuate_vaina_en
        },
        {
          key:2,
          nombre:['Runner',<br></br>,'peanut'],
          imagen:cacahuate_crudo,
          description: 'Raw peanut with a cuticle, runner type. It is sold in different gradings. They are practically free of foreign matter, damage and minor defects.',
          ficha: ficha_cacahuate_redskins_en
        },
        {
          key:3, 
          nombre:['Blanched',<br></br>,'peanut'], 
          imagen:cacahuate_blanqueado_entero,
          description: "Peanut that goes trough a heat process to remove the cuticle." ,
          ficha: ficha_cacahuate_entero_en
        },
        {
          key:4, 
          nombre:['Blanched',<br></br>,'peanut', <br></br>, 'splits'], 
          imagen:cacahuate_blanqueado_mitad,
          description: 'Peanut that goes trough a heat process to remove the cuticle, in splits.',
          ficha: ficha_cacahuate_mitad_en
        }
      ]
    };
    let procesados = {
      es: [
        {
          key:1, 
          nombre:['Cacahuate',<br></br>,'tostado', <br/>, 'natural'], 
          imagen:cacahuate_horneado,
          description: 'Es un cacahuate sometido a un proceso de secado, puede ser con cacahuates de variedad estándar o alto oleico.',
          ficha: ficha_cacahuate_tostado
        },
        {
          key:2, 
          nombre:['Cacahuate',<br></br>,'triturado'], 
          imagen:cacahuate_triturado,
          description: 'Elaborado a partir de un cacahuate alto olieco, tostado y trozado en dos diferentes tamaños de partículas.',
          ficha: ficha_cacahuate_triturado_tostado
        },
        {
          key:3, 
          nombre:['Pasta de',<br></br>,'cacahuate'], 
          imagen:pasta_cacahuate,
          description: 'Pasta elaborada 100% de cacahuate molido. Se produce a partir de cacahuate previamente tostado y blanqueado.',
          ficha: ficha_pasta_cacahuate
        },
        {
          key:4, 
          nombre:['Crema de',<br></br>,'cacahuate'], 
          imagen:mantequilla_cacahuate,
          description: 'Crema elaborada con cacahuate molido, adicionada con estabilizante para evitar la separación del aceite. Contiene otros ingredientes como sal y azúcar para mejorar el sabor.',
          ficha: ficha_mantequilla_cacahuate
        },
      ],
      en:[
        {
          key:1, 
          nombre:['Roasted',<br></br>,'peanuts'], 
          imagen:cacahuate_horneado,
          description: 'Peanuts are carefully dry roasted, blanched and inspected to deliver this flavorful product that our customers use for many different applications.',
          ficha: ficha_cacahuate_tostado_en
        },
        {
          key:2, 
          nombre:['Roasted',<br></br>,'granules'], 
          imagen:cacahuate_triturado,
          description: 'Peanuts are carefully dry roasted, blanched, chopped and inspected to deliver this flavorful product that our customers use for many different applications. Made from a high oleic peanuts, roasted and chopped into two different particle sizes.',
          ficha: ficha_cacahuate_triturado_tostado_en
        },
        {
          key:3, 
          nombre:['Peanut',<br></br>,'paste'], 
          imagen:pasta_cacahuate,
          description: 'Natural Peanut Butter is produced from peanuts that were previously roasted and blanched to enter a mechanical milling process from which this viscous product is obtained. No stabilizers, salt, sugar, or any other ingredients are added, in other words, paste consists of 100% ground peanuts and is widely used in the baking industry as a base to prepare fillings.',
          ficha: ficha_pasta_cacahuate_en
        },
        {
          key:4, 
          nombre:['Peanut',<br></br>,'butter'], 
          imagen:mantequilla_cacahuate,
          description: 'Peanut butter made from ground peanuts, added with stabilizer to prevent oil separation. It contains other ingredients such as salt and sugar to improve the taste.',
          ficha: ficha_mantequilla_cacahuate_en
        },
      ]
    };
    let botanas = {
      es: [
        {
          key:1, 
          nombre:['Cacahuate',<br></br>,'Craquenbuffalo'],
          imagen:cacahuate_bufalo,
          description: 'Cacahuate con cobertura frita, textura crujiente y una mezcla de chiles intensos.',
          ficha: ficha_crunchy
        },
        {
          key:2, 
          nombre:['Cacahuate',<br></br>,'japonés'], 
          imagen:cacahuate_japones,
          description:'Cacahuate con cobertura crujiente horneado a base de harina y soya.',
          ficha: ficha_japones
        },
        {
          key:3, 
          nombre:['Cacahuate',<br></br>,'enchilado'], 
          imagen:cacahuate_enchilado,
          description:'Cacahuate frito con una mezcla de chiles suaves.',
          ficha: ficha_enchilado
        },
        {
          key:4, 
          nombre:['Cacahuate',<br></br>,'con sal'], 
          imagen:cacahuate_salado,
          description:'Cacahuate frito con sal.',
          ficha: ficha_salado
        },
        {
          key:5, 
          nombre:['Cacahuate',<br></br>,'español'], 
          imagen:cacahuate_botana_espanol,
          description:'Cacahuate con cutícula frito con sal y ácido cítrico.',
          ficha: ficha_espanol

        },
        {
          key:6, 
          nombre:['Cacahuate',<br></br>,'holandés'], 
          imagen:cacahuate_holandes,
          description:'Cacahuate con cobertura frita, textura crujiente y una mezcla de chiles suaves.',
          ficha: ficha_holandes
        },
        {
          key:7,
          nombre:['Garbanzo',<br></br>, 'frito y', <br></br>, 'enchilado'],
          imagen: garbanzo_enchilado,
          description: 'Garbanzo frito, salado y con una mezcla de chiles.',
          ficha: ficha_garbanzo_enchilado
        },
        {
          key:8,
          nombre:['Haba',<br></br>, 'enchilada'],
          imagen: haba_enchilada,
          description: 'Habas fritas con cáscara, crujientes y con una mezcla de chiles suaves.',
          ficha:ficha_haba_enchilada
        },
        {
          key:9,
          nombre:['Pepita',<br></br>,'blanca tostada'],
          imagen:pepita_blanca_tostada,
          description:'Semilla de calabaza con cáscara blanca procesada. (tostada y salada).',
          ficha:ficha_pepita_blanca_tostada
        },
        {
          key:10,
          nombre:['Pepita',<br></br>,'amarilla tostada'],
          imagen:pepita_amarilla_tostada,
          description:'Semilla de calabaza con cáscara amarilla procesada. Se comercializa en su presentación natural (cruda) y procesada (tostada y salada).',
          ficha:ficha_pepita_amarilla_tostada
        },
        {
          key:11,
          nombre:['Maíz',<br></br>,'Chile-Limón'],
          imagen:maiz_chile_limon,
          description:'Maíz frito con sabor chile limón.',
          ficha:ficha_maiz_limon_chile_es
        },
        {
          key:12,
          nombre:['Maíz',<br></br>,'chipotle'],
          imagen:maiz_chipotle,
          description:'Maíz frito con sabor chipotle.',
          ficha:ficha_maiz_chipotle_es
        },
        {
          key:13,
          nombre:['Maíz',<br></br>,'queso habanero'],
          imagen:maiz_habanero,
          description:'Maíz frito con sabor queso habanero.',
          ficha:ficha_maiz_habanero_es
        },
        {
          key:14,
          nombre:['Maíz',<br></br>,'queso cheddar'],
          imagen:maiz_queso,
          description:'Maíz frito con sabor queso Cheddar.',
          ficha:ficha_maiz_queso_es
        },                                
      ],
      en:[
        {
          key:1, 
          nombre:['Peanut crunchy',<br></br>,'fire flavor'],
          imagen:cacahuate_bufalo,
          description: 'Peanut with crunchy coating oilroasted with a mix of hot chilis and salt.',
          ficha: ficha_crunchy_en
        },
        {
          key:2, 
          nombre:['Japanese style',<br></br>,'peanut snack'], 
          imagen:cacahuate_japones,
          description:'Peanut with crunchy coating baked with flour and soy.',
          ficha: ficha_japones_en
        },
        {
          key:3, 
          nombre:['Oil roasted',<br></br>,'peanut snack', <br/>,'with chili flavor'], 
          imagen:cacahuate_enchilado,
          description:'Oil roasted peanuts with a mild chili flavor.',
          ficha: ficha_enchilado_en
        },
        {
          key:4, 
          nombre:['Oil roasted',<br></br>,'peanut snack', <br/>,'with salt'], 
          imagen:cacahuate_salado,
          description:'Oil roasted peanuts with salt',
          ficha: ficha_salado_en
        },
        {
          key:5, 
          nombre:['Oilr roasted',<br></br>,'redskin peanut'], 
          imagen:cacahuate_botana_espanol,
          description:'Oil roasted peanut redskins with salt and lemon added',
          ficha: ficha_espanol_en

        },
        {
          key:6, 
          nombre:['Dutch style',<br></br>,'peanuts'], 
          imagen:cacahuate_holandes,
          description:'Peanut with crunchy coating oilroasted with a mix of mild chilis and salt.',
          ficha: ficha_holandes_en
        },
        {
          key:7,
          nombre:['Chickpeas',<br></br>, 'snacks'],
          imagen: garbanzo_enchilado,
          description: 'Oilroasted chickpeas, salted or with a mixture of peppers.',
          ficha: ficha_garbanzo_enchilado_en
        },
        {
          key:8,
          nombre:['Fava beans',<br></br>, 'snacks'],
          imagen: haba_enchilada,
          description: 'Fava beans oilroasted, crunchy added with a mixure of mild pepers.',
          ficha:ficha_haba_enchilada_en
        },
        {
          key:9,
          nombre:['Roasted and salted',<br></br>,'snowhite', <br/>, 'pumpkin seeds'],
          imagen:pepita_blanca_tostada,
          description:'Roasted white-shelled pumpkin seed, only salt added',
          ficha:ficha_pepita_blanca_tostada_en
        },
        {
          key:10,
          nombre:['Roasted and salted',<br></br>,'pumpkin seeds'],
          imagen:pepita_amarilla_tostada,
          description:'Roasted pupkin seed with salt.',
          ficha:ficha_pepita_amarilla_tostada_en
        },
        {
          key:11,
          nombre:['Lemon chili',<br></br>,'flavored corn'],
          imagen:maiz_chile_limon,
          description:'Fried corn with lemon chili flavor.',
          ficha:ficha_maiz_limon_chile_en
        },
        {
          key:12,
          nombre:['Chipotle',<br></br>,'flavored corn'],
          imagen:maiz_chipotle,
          description:'Chipotle-flavored fried corn.',
          ficha:ficha_maiz_chipotle_en
        },
        {
          key:13,
          nombre:['Habanero cheesse',<br></br>,' flavored corn'],
          imagen:maiz_habanero,
          description:'Fried corn with habanero cheese flavor.',
          ficha:ficha_maiz_habanero_en
        },
        {
          key:14,
          nombre:['Cheddar cheese',<br></br>,'flavored corn'],
          imagen:maiz_queso,
          description:'Cheddar-flavored fried corn.',
          ficha:ficha_maiz_queso_en
        },                                
      ]
    };
    let granel = {
      es: [
        {
          key:1, 
          nombre:['Pepita',<br></br>,'amarilla'],
          imagen:otros_pepita_amarilla,
          description: 'Semilla de calabaza con cáscara amarilla cruda. Se comercializa en su presentación natural (cruda) y procesada (tostada y salada).',
          ficha: ficha_pepita_amarilla_natural
        },
        {
          key:2, 
          nombre:['Pepita',<br></br>,'blanca'], 
          imagen:otros_pepita_blanca,
          description: 'Semilla de calabaza con cáscara blanca cruda. Se comercializa en su presentación natural (cruda) y procesada (tostada y salada).',
          ficha: ficha_pepita_blanca_cruda
        },
        {
          key:3, 
          nombre:['Pepita',<br></br>,'pama', <br></br>, 'sin cáscara'], 
          imagen:otros_pepita_verde,
          description: 'Semilla de calabaza sin cáscara cruda.',
          ficha: ficha_pepita_pama
        },
        {
          key:4, 
          nombre:['Arándano'], 
          imagen:otros_arandano,
          description:'Arándano deshidratado dulce.',
          ficha: ficha_arandano
        },
        {
          key:5, 
          nombre:['Pistache', <br></br>, 'tostado y', <br></br>, 'salado'], 
          imagen:otros_pistache,
          description:'Pistache con cáscara, tostado y salado.',
          ficha: ficha_pistachios
        },
        {
          key:6, 
          nombre:['Nuez',<br></br>,'de la India', <br></br>, 'salada'], 
          imagen:otros_nuez_india,
          description:'Nuez de la India frita con sal.',
          ficha: ficha_cashews
        },
        {
          key:7, 
          nombre:['Flor de Jamaica'], 
          imagen:otros_jamaica,
          description:'Flor de Jamaica seleccionada y envasada.',
          ficha: ficha_hibiscus
        },
      ],
      en:[
        {
          key:1, 
          nombre:['Yellow',<br></br>,'pumpkin seeds'],
          imagen:otros_pepita_amarilla,
          description: 'Raw Snow white pupkin seeds, carefully selected free from foreing material, damage or defect.',
          ficha: ficha_pepita_amarilla_natural_en
        },
        {
          key:2, 
          nombre:['Snow white',<br></br>,'pumpkin seeds'], 
          imagen:otros_pepita_blanca,
          description: 'Raw Snow white pupkin seeds, carefully selected free from foreing material, damage or defect.',
          ficha: ficha_pepita_blanca_cruda_en
        },
        {
          key:3, 
          nombre:['Pumpkin',<br></br>,'seed', <br></br>, 'kernels'], 
          imagen:otros_pepita_verde,
          description: 'Raw pumpkin seed kernels ',
          ficha: ficha_pepita_pama_en
        },
        {
          key:4, 
          nombre:['Cranberries'], 
          imagen:otros_arandano,
          description:'Sweet and dry cranberries',
          ficha: ficha_arandano_en
        },
        {
          key:5, 
          nombre:['Pistachios'], 
          imagen:otros_pistache,
          description:'Raw or roasted and salted Pistacios. ',
          ficha: ficha_pistachios_en
        },
        {
          key:6, 
          nombre:['Cashews'], 
          imagen:otros_nuez_india,
          description:'Roasted, or oilroastes cashews with or without salt',
          ficha: ficha_cashews_en
        },
        {
          key:7, 
          nombre:['Hibiscus', <br/>, 'flower'], 
          imagen:otros_jamaica,
          description:'Carefuly selected and cleaned hibiscus flower',
          ficha: ficha_hibiscus_en
        },
      ]
    };

    return( 
      <div className="Contenedor">
        <BannerHeader selectedLanguage={this.props.selectedLanguage} descripcion='' imagen={encabezado} banners={[banners[this.props.selectedLanguage][0]]} className="HeaderPrincipal" />      
        <div id="natural" style={{width:'100%', 'padding':'2.5rem 2rem', boxSizing:'border-box'}} >
          <SoloTexto titulo={dict_productos[this.props.selectedLanguage].natural} color='verde'/>
          <Cuadricula productos={cacahuates[this.props.selectedLanguage]} onClick={this.showProduct} />      
          <Link className="LinkButton" style={{width:'40%', margin:'0 auto', display: 'flex', alignItems:'center', justifyContent:'center'}} to="/detailproducts">{dict_productos[this.props.selectedLanguage].descubre}</Link>
        </div>
        <div id="procesados" style={{width:'100%', 'padding':'2.5rem 2rem', boxSizing:'border-box'}} >
          <SoloTexto titulo={dict_productos[this.props.selectedLanguage].procesados} color='verde'/>
          <Cuadricula productos={procesados[this.props.selectedLanguage]} onClick={this.showProduct} />      
        </div>
        <div id="botanas" style={{width:'100%', 'padding':'2.5rem 2rem', boxSizing:'border-box'}} >
          <SoloTexto titulo={dict_productos[this.props.selectedLanguage].snacks} color='verde'/>
          <Cuadricula productos={botanas[this.props.selectedLanguage]} onClick={this.showProduct} />      
        </div>
        <div id="granel" style={{width:'100%', 'padding':'2.5rem 2rem', boxSizing:'border-box'}} >
          <SoloTexto titulo={dict_productos[this.props.selectedLanguage].granel} color='verde'/>
          <Cuadricula productos={granel[this.props.selectedLanguage]} onClick={this.showProduct} />      
        </div>
        <Cotizar selectedLanguage={this.props.selectedLanguage} />
        {
          this.state.showModal && 
          <Modal 
            onClose={()=>{this.setState({showModal:false})}} 
            show={this.state.showModal}
            closeStyle={{
              color:'#3DAE2B',
              border:'none',
              outline:'none'
            }}
          >
            <div onClick={(e)=>{e.stopPropagation();}}className="productModal">
              <div className="productModalImage">
                <img src={this.state.product.imagen} alt="" />
              </div>
              <div className="productModalText">
                <h2>{this.state.product.nombre}</h2>
                <p>{this.state.product.description}</p>
                { 
                  this.state.product.ficha && <a href={this.state.product.ficha} target="_blank" rel="noopener noreferrer">
                    &darr; {dict_productos[this.props.selectedLanguage].download}
                  </a>
                }
              </div>
            </div>
          </Modal>
        }
      </div>
    );
  }
  if (val==3){
    
    return(    
      <div className="Contenedor">
        <BannerHeader selectedLanguage={this.props.selectedLanguage} descripcion='' imagen={encabezado} banners={[banners[this.props.selectedLanguage][0]]}  className="HeaderPrincipal" />
        <SoloTexto style={{width:'60%'}} texto={dict_productos[this.props.selectedLanguage].variedades} className="texto-m" color='gris' alineacion='center' titulo=""/>     
        
        <div id="producto" style={{width:'calc(100%-4rem)', padding:'2rem', background: `url(${cacahuate_background})`, backgroundSize:'cover'}}>
          <div id="tamaños" >
            <SoloTexto titulo={[<span className="texto-harabara">{dict_productos[this.props.selectedLanguage].calibres}</span>,<span className='texto-m'>&nbsp;Count per ounce (CPO)</span>]} />
            <div id="cacahuates" style={{backgroundColor:'rgba(67,176,42,.5)', display:'flex', flexDirection:'row', justifyContent:'space-around', padding:'2rem 0'}}>
              <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'flex-end'}}>
                <img src={cacahuate_38_42} style={{width:'100%'}} />
                <hr style={{
                  border: 'none',
                  height: '3px',
                  color: '#fff', /* old IE */
                  backgroundColor: '#fff', /* Modern Browsers */
                  width:'100%'
                }} />
                <span className='texto-m texto-white'>38 / 42</span>
              </div>
              <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'flex-end'}}>
                <img src={cacahuate_38_42} style={{width:'85%'}} />
                <hr style={{
                  border: 'none',
                  height: '3px',
                  color: '#fff', /* old IE */
                  backgroundColor: '#fff', /* Modern Browsers */
                  width:'100%'
                }} />
                <span className='texto-m texto-white'>40 / 50</span>
              </div>
              <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'flex-end'}}>
                <img src={cacahuate_38_42} style={{width:'70%'}} />
                <hr style={{
                  border: 'none',
                  height: '3px',
                  color: '#fff', /* old IE */
                  backgroundColor: '#fff', /* Modern Browsers */
                  width:'100%'
                }} />
                <span className='texto-m texto-white'>50 / 60</span>
              </div>
              <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'flex-end'}}>
                <img src={cacahuate_38_42} style={{width:'50%'}} />
                <hr style={{
                  border: 'none',
                  height: '3px',
                  color: '#fff', /* old IE */
                  backgroundColor: '#fff', /* Modern Browsers */
                  width:'100%'
                }} />
                <span className='texto-m texto-white'>60 / 80</span>
              </div>
              <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'flex-end'}}>
                <img src={cacahuate_38_42} style={{width:'40%'}} />
                <hr style={{
                  border: 'none',
                  height: '3px',
                  color: '#fff', /* old IE */
                  backgroundColor: '#fff', /* Modern Browsers */
                  width:'100%'
                }} />
                <span className='texto-m texto-white'>80 / 100</span>
              </div>
              <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'flex-end'}}>
                <img src={cacahuate_mitades} style={{width:'100%'}} />
                <hr style={{
                  border: 'none',
                  height: '3px',
                  color: '#fff', /* old IE */
                  backgroundColor: '#fff', /* Modern Browsers */
                  width:'100%'
                }} />
                <span className='texto-m texto-white'>MITADES</span>
              </div>
            </div>
          </div>
          <div id="tamaños" >
            <SoloTexto titulo={[<span className="texto-harabara">{dict_productos[this.props.selectedLanguage].variedad}</span>]} style={{marginTop:'4rem'}}/>
            <div id="cacahuates" className='cacahuates'>
              <div className='cacahuate' >
                <div style={{display:'flex', justifyContent:'center', alignItems:'flex-end', height:'50%', width:'calc(100%-10px-2rem)'}}>
                  <img src={cacahuate_virginia} style={{width:'100%'}} />
                </div>
                <div style={{height:'50%', paddingTop:'1.5rem', boxSizing:'border-box', display:'flex',flexDirection:'column', justifyContent:'flex-start', flexGrow:'0'}}>
                  <span className='texto-m texto-bold texto-white'>{dict_productos[this.props.selectedLanguage].virginia_title}</span>
                  <p></p>
                  <span className='texto-s texto-white'>{dict_productos[this.props.selectedLanguage].virginia_text}</span>
                </div>
              </div>
              <div className='cacahuate' >
                <div style={{display:'flex', justifyContent:'center', alignItems:'flex-end', height:'50%', width:'calc(100%-10px-2rem)'}}>
                  <img src={cacahuate_runner} style={{width:'100%'}} />
                </div>
                <div style={{height:'50%', paddingTop:'1.5rem', boxSizing:'border-box', display:'flex',flexDirection:'column', justifyContent:'flex-start', flexGrow:'0'}}>
                  <span className='texto-m texto-bold texto-white'>{dict_productos[this.props.selectedLanguage].runner_title}</span>
                  <p></p>
                  <span className='texto-s texto-white'>{dict_productos[this.props.selectedLanguage].runner_text}</span>
                </div>
              </div>
              <div className='cacahuate' >
                <div style={{display:'flex', justifyContent:'center', alignItems:'flex-end', height:'50%', width:'calc(100%-10px-2rem)'}}>
                  <img src={cacahuate_espanol} style={{width:'100%'}} />
                </div>
                <div style={{height:'50%', paddingTop:'1.5rem', boxSizing:'border-box', display:'flex',flexDirection:'column', justifyContent:'flex-start', flexGrow:'0'}}>
                <span className='texto-m texto-bold texto-white' >{dict_productos[this.props.selectedLanguage].espanol_title}</span>
                <p></p>
                <span className='texto-s texto-white'>{dict_productos[this.props.selectedLanguage].espanol_text}</span>
                </div>
               </div>
               <div className='cacahuate' >
               <div style={{display:'flex', justifyContent:'center', alignItems:'flex-end', height:'50%', width:'calc(100%-10px-2rem)'}}>
                  <img src={cacahuate_valencia} style={{width:'100%'}} />
                </div>
                <div style={{height:'50%', paddingTop:'1.5rem', boxSizing:'border-box', display:'flex',flexDirection:'column', justifyContent:'flex-start', flexGrow:'0'}}>
                  <span className='texto-m texto-bold texto-white'>{dict_productos[this.props.selectedLanguage].valencia_title}</span>
                  <p></p>
                  <span className='texto-s texto-white'>{dict_productos[this.props.selectedLanguage].valencia_text}</span>  
                </div>
              </div>

            </div>
          </div>
        </div>
        <div id="proceso" style={{width:"100%", borderBottom:'10px solid #43B02A', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center',}}>
          <SoloTexto style={{width:'calc(100%)', padding:'2rem'}} titulo={dict_productos[this.props.selectedLanguage].productivo} color='verde' alineacion="left"/>
          <div style={{width: '80%', paddingBottom:'2rem'}}>
            <div class='embed-container'><iframe title="video proceso productivo" src={dict_productos[this.props.selectedLanguage].video_url} frameborder='0' allowfullscreen></iframe></div>
          </div>
        </div>
        <Cotizar selectedLanguage={this.props.selectedLanguage} />
      </div>)
  }

  if (val==4){
    var servicios={
      es: [
          {key:1, nombre:'', titulo:['Programa',<br></br>,'de siembra'],imagen:Siembra},
          {key:2, nombre:'', titulo:['Proveedor',<br></br>,'de ingredientes'],imagen:ProveedorIngredientes},
          {key:3, nombre:'', titulo:['Productos',<br></br>,'a la medida'],imagen:ProdMedida},
          {key:4, nombre:'', titulo:['Marca blanca',<br></br>],imagen:MarcaBlanca}
      ],
      en: [
          {key:1, nombre:'', titulo:['Grower',<br></br>,'program'],imagen:Siembra},
          {key:2, nombre:'', titulo:['Ingredients',<br></br>,'supplier'],imagen:ProveedorIngredientes},
          {key:3, nombre:'', titulo:['Custom made',<br></br>,'products'],imagen:ProdMedida},
          {key:4, nombre:'', titulo:['Private label',<br></br>],imagen:MarcaBlanca}
      ]
    } ;
    let banners_ciclo =   {
      es:[{
        imagen: banner_ciclo1,
          text: <div className="textoHeader" style={{width:'100%', position:'absolute', alignItems:'center', justifyContent:'flex-start', top:'2rem', left:'50%', display:'flex', transform:'translateX(-50%)', 'flexDirection':'column', height:'75%'}}>
            <span className='texto-xl texto-harabara texto-white texto-bolder'>Promoción</span>
            <span className='texto-m texto-white' style={{marginTop:'2rem',width:'60%'}}>El programa agrícola promueve y ayuda a los agricultores a mejorar su producción con mayor eficiencia.</span>
          </div>
        },
        {
          imagen: banner_ciclo2,
          text: <div className="textoHeader" style={{width:'100%', position:'absolute', alignItems:'center', justifyContent:'flex-start', top:'2rem', left:'50%', display:'flex', transform:'translateX(-50%)', 'flexDirection':'column', height:'75%'}}>
            <span className='texto-xl texto-harabara texto-white texto-bolder'>Siembra</span>
            <span className='texto-m texto-white' style={{marginTop:'2rem',width:'60%'}}>Se realiza a temperatura y en periodos controlados para también enriquecer el suelo con los elementos necesarios.</span>
          </div>      },
        {
          imagen: banner_ciclo3,
          text: <div className="textoHeader" style={{width:'100%', position:'absolute', alignItems:'center', justifyContent:'flex-start', top:'2rem', left:'50%', display:'flex', transform:'translateX(-50%)', 'flexDirection':'column', height:'75%'}}>
            <span className='texto-l texto-harabara texto-white texto-bolder'>Desarrollo de cultivo</span>
            <span className='texto-m texto-white' style={{marginTop:'2rem', width:'60%'}}>Germinación, crecimiento, floración, fructificación y maduración </span>
          </div>      },
        {
          imagen: banner_ciclo4,
          text: <div className="textoHeader" style={{width:'100%', position:'absolute', alignItems:'center', justifyContent:'flex-start', top:'2rem', left:'50%', display:'flex', transform:'translateX(-50%)', 'flexDirection':'column', height:'75%'}}>
            <span className='texto-xl texto-harabara texto-white texto-bolder'>Cosecha</span>
            <span className='texto-m texto-white' style={{marginTop:'2rem',width:'60%'}}>Recolección de frutos de calidad (maduros y con peso correcto).</span>
          </div>      },
        {
          imagen: banner_ciclo5,
          text: <div className="textoHeader" style={{width:'100%', position:'absolute', alignItems:'center', justifyContent:'flex-start', top:'2rem', left:'50%', display:'flex', transform:'translateX(-50%)', 'flexDirection':'column', height:'75%'}}>
            <span className='texto-xl texto-harabara texto-white texto-bolder'>Reciba</span>
            <span className='texto-m texto-white' style={{marginTop:'2rem',width:'60%'}}>Es la recepción de la cosecha que entrega el productor para hacer un análisis de las condiciones del cacahuate.</span>
          </div>
        }
      ],
      en:[{
        imagen: banner_ciclo1,
          text: <div className="textoHeader" style={{width:'100%', position:'absolute', alignItems:'center', justifyContent:'flex-start', top:'2rem', left:'50%', display:'flex', transform:'translateX(-50%)', 'flexDirection':'column', height:'75%'}}>
            <span className='texto-xl texto-harabara texto-white texto-bolder'>Promoting</span>
            <span className='texto-m texto-white' style={{marginTop:'2rem',width:'60%'}}>Our agricultural programs provide machinery and training that foster and help farmers to improve production efficiently.</span>
          </div>
        },
        {
          imagen: banner_ciclo2,
          text: <div className="textoHeader" style={{width:'100%', position:'absolute', alignItems:'center', justifyContent:'flex-start', top:'2rem', left:'50%', display:'flex', transform:'translateX(-50%)', 'flexDirection':'column', height:'75%'}}>
            <span className='texto-xl texto-harabara texto-white texto-bolder'>Planting</span>
            <span className='texto-m texto-white' style={{marginTop:'2rem',width:'60%'}}>It is done at temperature and in controlled periods to also enrich the soil with the necessary elements.</span>
          </div>      },
        {
          imagen: banner_ciclo3,
          text: <div className="textoHeader" style={{width:'100%', position:'absolute', alignItems:'center', justifyContent:'flex-start', top:'2rem', left:'50%', display:'flex', transform:'translateX(-50%)', 'flexDirection':'column', height:'75%'}}>
            <span className='texto-l texto-harabara texto-white texto-bolder'>Crop development</span>
            <span className='texto-m texto-white' style={{marginTop:'2rem', width:'60%'}}>Seed germination, growth, blooming, fruition and maturation.</span>
          </div>      },
        {
          imagen: banner_ciclo4,
          text: <div className="textoHeader" style={{width:'100%', position:'absolute', alignItems:'center', justifyContent:'flex-start', top:'2rem', left:'50%', display:'flex', transform:'translateX(-50%)', 'flexDirection':'column', height:'75%'}}>
            <span className='texto-xl texto-harabara texto-white texto-bolder'>Harvest </span>
            <span className='texto-m texto-white' style={{marginTop:'2rem',width:'60%'}}>The fruits must be ripe and of the right weight to be of good quality.</span>
          </div>      },
        {
          imagen: banner_ciclo5,
          text: <div className="textoHeader" style={{width:'100%', position:'absolute', alignItems:'center', justifyContent:'flex-start', top:'2rem', left:'50%', display:'flex', transform:'translateX(-50%)', 'flexDirection':'column', height:'75%'}}>
            <span className='texto-xl texto-harabara texto-white texto-bolder'>Reception of harvest</span>
            <span className='texto-m texto-white' style={{marginTop:'2rem',width:'60%'}}>It is the reception of the crop delivered by the producer to make an analysis of the condition of the peanuts.</span>
          </div>
        }
      ]
    };
      let comercializacion = [
        {imagen: comercializacion1,
         linea1: "PROGRAMAS",
         linea2: "AGRÍCOLAS"},
        {imagen: comercializacion2,
         linea1: "RECIBA",
         linea2: "DEL CAMPO"},
        {imagen: comercializacion3,
         linea1: "PLANTA",
         linea2: "DESCASCADORA"},
        {imagen: comercializacion4,
          linea1: "PLANTA",
          linea2: "PROCESADORA"},
        {imagen: comercializacion5,
        linea1: "PLANTA DE",
        linea2: "SELECCION"},
        {imagen: comercializacion6,
        linea1: "CENTROS DE",
        linea2: "DISTRIBUCIÓN"},
        {imagen: comercializacion7,
        linea1: "ALMACÉN DE",
        linea2: "MATERIA PRIMA"},
        {imagen: comercializacion8,
        linea1: "OFICINA",
        linea2: "CORPORATIVA"}
      ]
      return(    
      <div className="Contenedor">
        <BannerHeader selectedLanguage={this.props.selectedLanguage} descripcion='' imagen={encabezado} banners={[banners[this.props.selectedLanguage][2]]} className="HeaderPrincipal" />      
        <BannerH id="servicios"  conceptos={servicios[this.props.selectedLanguage]} imagen={fondoservicios}>          
          <SoloTexto titulo={dict_servicios[this.props.selectedLanguage].servicios} color='blanco' style={{paddingLeft:'2em'}}/>
        </BannerH>
        <div id="programa" className="Banner2cols" style={{borderTop:'10px solid #43B02A'}}>
          <BannerPrincipal descripcion='' imagen={progscampo}>
              <div id="triangle-left" >
              </div>
          </BannerPrincipal>
          <SoloTexto titulo={dict_servicios[this.props.selectedLanguage].programa_title} texto={dict_servicios[this.props.selectedLanguage].programa_text} color='verde' alineacion="center">
            <Link className="LinkButton"  style={{ float:"center", paddingLeft:"25px", paddingRight:"25px", width:'30%', margin:'0 auto'}} to="/blog/programa-campo">{dict_acerca[this.props.selectedLanguage].more}</Link>
          </SoloTexto>
        </div>
        <div className="Banner2cols" style={{borderBottom:'10px solid #43B02A'}}>
          <SoloTexto titulo={dict_servicios[this.props.selectedLanguage].agroindustria_title} texto={dict_servicios[this.props.selectedLanguage].agroindustria_text} color='verde' alineacion="center" />
          <BannerPrincipal descripcion='' imagen={agroindustria}>
              <div id="triangle-right" >
              </div>
          </BannerPrincipal>
        </div>
        <div id="ciclo-cultivo" style={{width:"100%", margin:'1.5rem auto'}}>
          <SoloTexto titulo={dict_servicios[this.props.selectedLanguage].ciclo} color='verde' alineacion="center"  />
          <BannerHeader  selectedLanguage={this.props.selectedLanguage} descripcion='' imagen={encabezado} banners={banners_ciclo[this.props.selectedLanguage]} />      
        </div>
        
        <div id="comercializacion" style={{width:"100%", margin:'1.5rem auto 0 auto', borderBottom:'10px solid #43B02A'}}>
          <div style={{width:'100%', position:'relative'}}>
            <img src={mapa} style={{width:'100%'}} />
            <div id="comercializacion_title" style={{width:'100%', top: '-1rem', display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center', textAlign:'center', position: 'absolute', boxSizing:'border-box'}} >
              <SoloTexto alineacion='center' titulo={dict_servicios[this.props.selectedLanguage].comercializacion} color='verde'/>
            </div>
            <div style={{position:'absolute', display:'flex', 'flexDirection':'column', textAlign:'center', 'alignItems':'center', left:'13.5%', top: '20.0%', transform:'translate(-50%)'}}>
              <span className='texto-gray texto-bold texto-mapa'>{dict_servicios[this.props.selectedLanguage].campo.l1}</span>              
              <span className='texto-gray texto-bold texto-mapa'>{dict_servicios[this.props.selectedLanguage].campo.l2}</span>              
            </div>
            <div style={{position:'absolute', display:'flex', 'flexDirection':'column', textAlign:'center', 'alignItems':'center', left:'24.5%', top: '20.0%', transform:'translate(-50%)'}}>
              <span className='texto-gray texto-bold texto-mapa'>{dict_servicios[this.props.selectedLanguage].reciba.l1}</span>
              <span className='texto-gray texto-bold texto-mapa'>{dict_servicios[this.props.selectedLanguage].reciba.l2}</span>
            </div>
            <div style={{position:'absolute', display:'flex', 'flexDirection':'column', textAlign:'center', 'alignItems':'center', left:'34.5%', top: '20.0%', transform:'translate(-50%)'}}>
              <span className='texto-gray texto-bold texto-mapa'>{dict_servicios[this.props.selectedLanguage].planta.l1}</span>
              <span className='texto-gray texto-bold texto-mapa'>{dict_servicios[this.props.selectedLanguage].planta.l2}</span>
              <span className='texto-gray texto-bold texto-mapa'>{dict_servicios[this.props.selectedLanguage].planta.l3}</span>
            </div>
            <div style={{position:'absolute', display:'flex', 'flexDirection':'column', textAlign:'center', 'alignItems':'center', left:'45%', top: '20.0%', transform:'translate(-50%)'}}>
              <span className='texto-gray texto-bold texto-mapa'>{dict_servicios[this.props.selectedLanguage].procesadora.l1}</span>
              <span className='texto-gray texto-bold texto-mapa'>{dict_servicios[this.props.selectedLanguage].procesadora.l2}</span>
            </div>
            <div style={{position:'absolute', display:'flex', 'flexDirection':'column', textAlign:'center', 'alignItems':'center', left:'55%', top: '20.0%', transform:'translate(-50%)'}}>
              <span className='texto-gray texto-bold texto-mapa'>{dict_servicios[this.props.selectedLanguage].seleccion.l1}</span>
              <span className='texto-gray texto-bold texto-mapa'>{dict_servicios[this.props.selectedLanguage].seleccion.l2}</span>
            </div>
            <div style={{position:'absolute', display:'flex', 'flexDirection':'column', textAlign:'center', 'alignItems':'center', left:'65.5%', top: '20.0%', transform:'translate(-50%)'}}>
              <span className='texto-gray texto-bold texto-mapa'>{dict_servicios[this.props.selectedLanguage].distribucion.l1}</span>
              <span className='texto-gray texto-bold texto-mapa'>{dict_servicios[this.props.selectedLanguage].distribucion.l2}</span>
            </div>
            <div style={{position:'absolute', display:'flex', 'flexDirection':'column', textAlign:'center', 'alignItems':'center', left:'76%', top: '20.0%', transform:'translate(-50%)'}}>
              <span className='texto-gray texto-bold texto-mapa'>{dict_servicios[this.props.selectedLanguage].materia.l1}</span>
              <span className='texto-gray texto-bold texto-mapa'>{dict_servicios[this.props.selectedLanguage].materia.l2}</span>
              <span className='texto-gray texto-bold texto-mapa'>{dict_servicios[this.props.selectedLanguage].materia.l3}</span>
            </div>
            <div style={{position:'absolute', display:'flex', 'flexDirection':'column', textAlign:'center', 'alignItems':'center', left:'87%', top: '20.0%', transform:'translate(-50%)'}}>
              <span className='texto-gray texto-bold texto-mapa'>{dict_servicios[this.props.selectedLanguage].oficina.l1}</span>
              <span className='texto-gray texto-bold texto-mapa'>{dict_servicios[this.props.selectedLanguage].oficina.l2}</span>
            </div>

            <div style={{position:'absolute', display:'flex', 'flexDirection':'column', textAlign:'left', 'alignItems':'flex-start', left:'12%', top: '45.75%'}}>
              <span className='texto-green texto-harabara texto-m texto-mapa'>CHIHUAHUA</span>
            </div>


            <div style={{position:'absolute', display:'flex', 'flexDirection':'column', textAlign:'left', 'alignItems':'flex-start', left:'12%', top: '52.5%'}}>
              <span className='texto-green texto-mapa'><span className="texto-bold">-</span> {dict_servicios[this.props.selectedLanguage].campo.text}</span>
              <span className='texto-green texto-mapa'><span className="texto-bold">1</span> {dict_servicios[this.props.selectedLanguage].reciba.text}</span>
              <span className='texto-green texto-mapa'><span className="texto-bold">1</span> {dict_servicios[this.props.selectedLanguage].planta.text}</span>
              <span className='texto-green texto-mapa'><span className="texto-bold">1</span> {dict_servicios[this.props.selectedLanguage].seleccion.text}</span>
            </div>

            <div style={{position:'absolute', display:'flex', 'flexDirection':'column', textAlign:'right', 'alignItems':'flex-start', left:'5.5%', top: '64.25%'}}>
              <span className='texto-green texto-harabara texto-m texto-mapa' style={{width:'100%', textAlign:'right'}}>{this.props.selectedLanguage===`es` ? 'CIUDAD DE MÉXICO' : 'MEXICO CITY'}</span>
            </div>

            <div style={{position:'absolute', display:'flex', 'flexDirection':'column', textAlign:'left', 'alignItems':'flex-start', left:'5.5%', top: '71.50%'}}>
              <span className='texto-green texto-mapa'><span className="texto-bold">2</span> {dict_servicios[this.props.selectedLanguage].materia.text}</span>
              <span className='texto-green texto-mapa'><span className="texto-bold">2</span> {dict_servicios[this.props.selectedLanguage].distribucion.text}</span>
              <span className='texto-green texto-mapa'><span className="texto-bold">1</span> {dict_servicios[this.props.selectedLanguage].oficina.text}</span>
              <span className='texto-green texto-mapa'><span className="texto-bold">2</span> {dict_servicios[this.props.selectedLanguage].procesadora.text}</span>
            </div>
            <div style={{position:'absolute', display:'flex', 'flexDirection':'column', textAlign:'right', 'alignItems':'flex-start', left:'23%', top: '75.0%'}}>
              <span className='texto-green texto-harabara texto-m texto-mapa' style={{width:'100%', textAlign:'right'}}>{this.props.selectedLanguage===`es` ? 'ESTADO DE MÉXICO' : 'MEXICO STATE'}</span>
            </div>

            <div style={{position:'absolute', display:'flex', 'flexDirection':'column', textAlign:'left', 'alignItems':'flex-start', left:'23%', top: '81.5%'}}>
              <span className='texto-green texto-mapa'><span className="texto-bold">1</span> {dict_servicios[this.props.selectedLanguage].materia.text}</span>
              <span className='texto-green texto-mapa'><span className="texto-bold">1</span> {dict_servicios[this.props.selectedLanguage].procesadora.text}</span>
            </div>
            <div style={{position:'absolute', display:'flex', 'flexDirection':'column', textAlign:'right', 'alignItems':'flex-start', left:'78%', top: '53.75%'}}>
              <span className='texto-green texto-harabara texto-m texto-mapa' style={{width:'100%', textAlign:'right'}}>HOUSTON, TX. USA</span>
            </div>
            <div style={{position:'absolute', display:'flex', 'flexDirection':'column', textAlign:'left', 'alignItems':'flex-start', left:'78%', top: '60.5%'}}>
              <span className='texto-green texto-mapa'><span className="texto-bold">1</span> {dict_servicios[this.props.selectedLanguage].oficina.text}</span>              
              <span className='texto-green texto-mapa'><span className="texto-bold">1</span> {dict_servicios[this.props.selectedLanguage].procesadora.text}</span>              
            </div>
            <div className="servicios-comercializacion" style={{position:'absolute', display:'flex', 'flexDirection':'column', textAlign:'left', 'alignItems':'flex-start'}}>
              <span className='texto-gray texto-mapa'>{dict_servicios[this.props.selectedLanguage].map_text.l1} </span>
              <span className='texto-gray texto-mapa' >{dict_servicios[this.props.selectedLanguage].map_text.l2} </span>
              <span className='texto-gray texto-mapa'>{dict_servicios[this.props.selectedLanguage].map_text.l3}</span>
            </div>    
            
          </div> 
        </div>
        <Cotizar selectedLanguage={this.props.selectedLanguage} />
      </div>)
  }
  
  

  if (val==5){
    var noticias=[
      {key:1 },
      {key:2},
      {key:3}];
    let blogBanners=[banners[this.props.selectedLanguage][4]];
    return(    
      <div className="Contenedor">

        <BannerHeader  selectedLanguage={this.props.selectedLanguage} banners={blogBanners} className="HeaderPrincipal"/>
        <div id="noticias" style={{marginRight:'2rem', marginLeft:'2rem'}}>
          <SoloTexto titulo={dict_servicios[this.props.selectedLanguage].news} color="verde" alineacion="left"  style={{padding:'2rem', width:'calc(100% - 4rem)'}}/>
          <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'space-between', width:'100%'}} >
            {Object.keys(blogs[this.props.selectedLanguage]).reverse().map((blogKey) => {
              const blogData = blogs[this.props.selectedLanguage][blogKey];
              return <Blog selectedLanguage={this.props.selectedLanguage} post={blogKey} imagen={blogData.image} titulo={blogData.title + "..."} descripcion={blogData.desc + '...'} />
            })}
          </div>
        </div>
        <div className="Mosaico2" >
          <a href="/blog/programa-campo" className="B1 gridEl" style={{position:'relative', backgroundImage:blog_1, backgroundSize:'cover'}}>
            <img src={blog_1} style={{width:'100%', height:'100%', objectFit:'cover'}} />
            <span></span>
            <div style={{backgroundColor:'rgba(67,176,42,.5)', width:"100%", position:'absolute', bottom:'0'}}>
              <span className="blanco" style={{display:'inline-block', width:'80%',fontSize:'1.75rem'}}>{blogs[this.props.selectedLanguage]["programa-campo"].title}</span>
              <hr style={{width:'60%'}}/>
            </div>
          </a>
          <a href="/blog/leche-cacahuate" className="B2 gridEl" style={{position:'relative', backgroundImage:blog_2, backgroundSize:'cover'}}>
            <img src={blog_2} style={{width:'100%', height:'100%', objectFit:'cover'}} />
            <span></span>
            <div style={{backgroundColor:'rgba(67,176,42,.5)', width:"100%", position:'absolute', bottom:'0'}}>
              <span className="blanco" style={{display:'inline-block', width:'80%',fontSize:'1.75rem'}}>{blogs[this.props.selectedLanguage]["leche-cacahuate"].title}</span>
              <hr style={{width:'60%'}}/>
            </div>
          </a>
          <a href="/blog/beneficios-cacahuate-embarazo" className="B3 gridEl" style={{position:'relative', backgroundImage:blog_3, backgroundSize:'cover'}}>
            <img src={blog_3} style={{width:'100%', height:'100%', objectFit:'cover'}} />
            <span></span>
            <div style={{backgroundColor:'rgba(67,176,42,.5)', width:"100%", position:'absolute', bottom:'0'}}>
              <span className="blanco" style={{display:'inline-block', width:'80%',fontSize:'1.75rem'}}>{blogs[this.props.selectedLanguage]["beneficios-cacahuate-embarazo"].title}</span>
              <hr style={{width:'60%'}}/>
            </div>
          </a>
          <a href="/blog/snack-ideal-diabetes" className="B4 gridEl" style={{position:'relative', backgroundImage:blog_3, backgroundSize:'cover'}}>
            <img src={blog_4} style={{width:'100%', height:'100%', objectFit:'cover'}} />
            <span></span>
            <div style={{backgroundColor:'rgba(67,176,42,.5)', width:"100%", position:'absolute', bottom:'0'}}>
              <span className="blanco" style={{display:'inline-block', width:'80%',fontSize:'1.75rem'}}>{blogs[this.props.selectedLanguage]["snack-ideal-diabetes"].title}</span>
              <hr style={{width:'60%'}}/>
            </div>
          </a>

        </div>  
        <Cotizar selectedLanguage={this.props.selectedLanguage} />
      </div>)
  }
  if (val==6){
    var naturales=[
      {key:1, nombre:[<br></br>,'Cacahuate',<br></br>,'con càscara'], descripcion:'Nombre en latin y toda la cosa', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:2, nombre:[<br></br>,'Cacahuate',<br></br>,'blanqueado',<br></br>,'entero'], descripcion:'Nombre en latin y toda la cosa' , link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:3, nombre:[<br></br>,'Cacahuate',<br></br>,'blanqueado',<br></br>,'mitad'], descripcion:'Descripcion del servicio 3', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:4, nombre:[<br></br>,'Cacahuate',<br></br>,'crudo'], descripcion:'Descripcion del servicio 4', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']}];
    return(    
      <div className="Contenedor">
        <BannerPrincipal descripcion='BANNER INGREDIENTES NATURALES'/>
        <SoloTexto texto={["Lorem Ipsum is simply dummy text of the printing and typesetting industry.",<br></br>,"LoremIpsumhas been the industry'sstandard dummy text ever since the 1500s"]} titulo=""/>     
         <BannerH conceptos={naturales} estilo="cuadrado"/>        
         <div style={{width:"100%"}}>
          <br></br>           
          <Link className="Link LinkButton"  style={{float:"center"}} to="/products">Regresar a todos los productos</Link>        
        </div>         
        <Cotizar selectedLanguage={this.props.selectedLanguage} />
        <Certificaciones/>
      </div>)
  }  
  if (val==7){

    return(    
      <div className="Contenedor">
        <BannerHeader selectedLanguage={this.props.selectedLanguage} descripcion='' imagen={encabezado} banners={[banners[this.props.selectedLanguage][0]]}  className="HeaderPrincipal" />

        <div id="proceso" style={{width:"100%", borderBottom:'10px solid #43B02A', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center',}}>
          <SoloTexto style={{width:'calc(100%)', padding:'2rem'}} titulo={dict_conoce[this.props.selectedLanguage].title} color='verde' alineacion="left"/>
          <div style={{width: '80%', paddingBottom:'2rem'}}>
            <div class='embed-container'><iframe title="video Conoce más" src={dict_conoce[this.props.selectedLanguage].video_url} frameborder='0' allowfullscreen></iframe></div>
          </div>
        </div>
        <Cotizar selectedLanguage={this.props.selectedLanguage} />
      </div>);    

  }  
  if (val==8){
    var granel=[
      {key:1, nombre:[<br></br>,'Pistache',<br></br>,'horneado /',<br></br>,'salado'], descripcion:'Nombre en latin y toda la cosa', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:2, nombre:[<br></br>,'Jamaica',<br></br>,'natural'], descripcion:'Nombre en latin y toda la cosa' , link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:3, nombre:[<br></br>,'Nuez de la',<br></br>,'India natural/',<br></br>,'horneada con',<br></br>,'o sin sal'], descripcion:'Nombre en latin y toda la cosa', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      
    ];
    var granel3=[
      {key:1, nombre:[<br></br>,'Semilla de',<br></br>,'calabaza'], descripcion:'Nombre en latin y toda la cosa', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:2, nombre:[<br></br>,'Garbanzo'], descripcion:'Nombre en latin y toda la cosa' , link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:3, nombre:[<br></br>,'Habas',<br></br>,'o sin sal'], descripcion:'Nombre en latin y toda la cosa', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:4, nombre:[<br></br>,'Arandano',<br></br>,'natural'], descripcion:'Nombre en latin y toda la cosa', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']}
    ];
    return(    
      <div className="Contenedor">
        <BannerPrincipal descripcion='BANNER PRODUCTOS A GRANEL'/>
        <SoloTexto texto={["Lorem Ipsum is simply dummy text of the printing and typesetting industry.",<br></br>,"LoremIpsumhas been the industry'sstandard dummy text ever since the 1500s"]} titulo=""/>     
         <BannerH conceptos={granel} estilo="cuadrado"/>  
         <BannerH conceptos={granel3} estilo="cuadrado"/>  
         <div style={{width:"100%"}}>
          <br></br>           
          <Link className="Link LinkButton"  style={{float:"center"}} to="/products">Regresar a todos los productos</Link>        
        </div>               
        <Cotizar selectedLanguage={this.props.selectedLanguage} />
        <Certificaciones/>
      </div>)
  }  
  if (val==9){
    var articulos=[
      {key:1}, 
      {key:2},
      {key:3}
      
    ];
    
    return(    
      <div className="Contenedor">
        <BannerPrincipal descripcion='BANNER CON IMAGEN DEL ARTICULO'/>
        <SoloTexto texto="“Sed ut perspiciatis, unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam eaque ipsa,
              quae ab illo inventore veritatis et quasi architecto beatae vitae dicta
              sunt, explicabo. Nemo"
              titulo=""/>     
          <SoloTexto titulo="Articulos relacionados"/>
         <BannerH conceptos={articulos} estilo="cuadrado"/>
         <div style={{width:"100%"}}>
          <br></br>           
          <Link className="LinkButton"  style={{float:"center"}} to="/blog">Regresar a todos los blogs</Link>        
          <br></br>           
          <br></br>           
        </div>         
      </div>)
  }  
  if (val==10){
    return(    
      <div className="Cotizacion" onClick={(e)=>{e.stopPropagation();}}>
        <div className="cotizacion-title" style={{width:'100%', height:'5rem', backgroundColor:'#3DAE2B', display:'flex', alignItems:'center', justifyContent:'center'}}>
          <h2 style={{color:'white'}}>Contacto</h2>
        </div>
          <div className="Contenedor" style={{textAlign:'left', width:'75%'}}>
            <h2 className="Leyenda" style={{textAlign:'left',width:'100%', marginTop:'2rem', fontFamily:'Calibri'}}>México:</h2>
            <div className="footer-data texto-m" style={{width:'100%', fontFamily:'Calibri', color:'#6c6c6c'}}>
              <span>{dict_contacto[this.props.selectedLanguage].corporativas}</span>
              <span>Camino Sta. Teresa 1040 Piso 9 int 902</span>
              <span>Jardines en la Montaña</span>
              <span>Tlalpan, CDMX 14210, México</span>
              <span>E-mail:</span>
              <span>ventas@galdisa.com</span>
              <span>info@galdisa.com</span>
              <span style={{fontFamily:'Calibri', fontWeight:'800'}}>Tel: +52 55 56 30 46 16</span>
              <br/>
            </div>
          </div>
          <div style={{width:'100%', borderBottom:'2px solid #c6c6c6'}}></div>
          <div className="Contenedor" style={{textAlign:'left', width:'75%'}}>
            <h2 className="Leyenda" style={{marginTop:'2rem', textAlign:'left',width:'100%' }}>USA</h2>
            <div className="footer-data texto-m" style={{width:'100%', fontFamily:'Calibri', color:'#6c6c6c'}}>
            <span>{dict_contacto[this.props.selectedLanguage].corporativas}</span>
            <span>3455 Pollok Dr.</span>
            <span>Conroe, TX 77303</span>
            <span>E-mail:</span>
            <span>contactusa@galdisa.com</span>
            <span style={{fontFamily:'Calibri', fontWeight:'800'}}>Tel:+1 (936) 242-5527</span>
            <br/>
          </div>
        </div>
      </div>
      )
  }  
  if (val==11){
     return(    
        <div className="Cotizacion" style={{overflowY:'scroll', maxHeight:'100vh'}} onClick={(e)=>{e.stopPropagation();}}>
          <div className="cotizacion-title" style={{width:'100%', height:'5rem', backgroundColor:'#3DAE2B', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <h2 style={{color:'white'}}>{dict_contacto[this.props.selectedLanguage].quote}</h2>
          </div>
          {(!this.state.thanks && !this.state.errorProcess) &&
            <>
              <div className="cotizacion-input">
                <div className="Leyenda" style={{color:(this.state.errors.nombre ? '#FF0000' : '#8d8d8d')}}>{dict_contacto[this.props.selectedLanguage].nombre}</div>
                <div className="Texto"><input type="Text" className="Caja" name="nombre" value={this.state.nombre} onChange={(evt)=>{this.setState({nombre:evt.target.value})}}></input></div>
              </div>
              <div className="cotizacion-input">
                <div className="Leyenda" style={{color:(this.state.errors.industria? '#FF0000' : '#8d8d8d')}} >{dict_contacto[this.props.selectedLanguage].industria}</div>
                <div className="Texto"><input type="Text" className="Caja" name="industria" value={this.state.industria} onChange={(evt)=>{this.setState({industria:evt.target.value})}}></input></div>
              </div>
              <div className="cotizacion-input">
                <div className="Leyenda">{dict_contacto[this.props.selectedLanguage].mail}*</div>
                <div className="Texto"><input ref={(input)=>{this.emailInput = input}} type="Text" className="Caja" name="email" style={{color:(this.state.emailError? '#FF0000' : '#8d8d8d')}} value={this.state.email} onChange={(evt)=>{this.setState({email:evt.target.value, emailError: !evt.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)})}}></input></div>
              </div>
              <div className="cotizacion-input">
                <div className="Leyenda" style={{color:(this.state.errors.telefono? '#FF0000' : '#8d8d8d')}} >{dict_contacto[this.props.selectedLanguage].telefono}</div>
                <div className="Texto"><input type="Text" className="Caja" name="telefono" value={this.state.telefono} onChange={(evt)=>{this.setState({telefono:evt.target.value})}}></input></div>
              </div>
              <div className="cotizacion-input">
                <div className="Leyenda" style={{color:(this.state.productError? '#FF0000' : '#8d8d8d')}}>{dict_contacto[this.props.selectedLanguage].producto} (*)</div>
                <div className="Texto"><input ref={(input)=>{this.productoInput = input}} type="Text" className="Caja" name="producto" value={this.state.producto} onChange={(evt)=>{this.setState({producto:evt.target.value})}}></input></div>
              </div>
              <div className="cotizacion-input">
                <div className="Leyenda">{dict_contacto[this.props.selectedLanguage].requiere_certificacion}</div>
                <div className="Texto">
                  <select type="Text" className="Caja" name="requiere_certificacion" value={this.state.requiere_certificacion} onChange={(evt)=>{this.setState({requiere_certificacion:evt.target.value})}}>
                    <option value="si">{dict_contacto[this.props.selectedLanguage].yes}</option>
                    <option value="no">{dict_contacto[this.props.selectedLanguage].no}</option>
                  </select></div>
              </div>              
              {
                this.state.requiere_certificacion === 'si' && 
                <div className="cotizacion-input">
                  <div className="Leyenda">{dict_contacto[this.props.selectedLanguage].certificacion}</div>
                  <div className="Texto"><input type="Text" className="Caja" name="certificacion" value={this.state.certificacion} onChange={(evt)=>{this.setState({certificacion: evt.target.value})}}></input></div>
                </div>
              }
              <div className="cotizacion-input">
                <div className="Leyenda" style={{color:(this.state.volumenError? '#FF0000' : '#8d8d8d')}}>{dict_contacto[this.props.selectedLanguage].volumen} (*)</div>
                <div className="Texto"><input ref={(input)=>{this.volumenInput = input}} type="Text" className="Caja" name="volumen_presentacion" value={this.state.volumen_presentacion} onChange={(evt)=>{this.setState({volumen_presentacion: evt.target.value})}}></input></div>
              </div>
              <div className="cotizacion-input">
                <div className="Leyenda" style={{color:(this.state.errors.mensaje? '#FF0000' : '#8d8d8d')}} > {dict_contacto[this.props.selectedLanguage].mensaje}</div>
                <div className="Texto"><textarea type="Text" className="Caja" name="mensaje" value={this.state.mensaje} onChange={(evt)=>{this.setState({mensaje:evt.target.value})}}></textarea></div>
              </div>
              <div style={{display:'flex',alignItems:'center',justifyContent:'center'}} className="cotizacion-input">
                <button style={{backgroundColor: this.state.disableSendButton? '#8c8c8c' : '#3DAE2B', color:'white', height:'3rem', width:'100%'}} disabled={this.state.disableSendButton} onClick={this.submitForm} className="texto-bold texto-m">{dict_contacto[this.props.selectedLanguage].enviar}</button>       
              </div> 
            </>
          }
          {
            this.state.thanks && 
            <>
              <div className="Contenedor" style={{textAlign:'left', width:'75%'}}>
                <h2 className="Leyenda" style={{textAlign:'left',width:'100%', margin:'2rem auto', fontFamily:'Calibri', textTransform:'none'}}>{dict_contacto[this.props.selectedLanguage].thanks_message}</h2>
                <div className="footer-data texto-m" style={{width:'100%', fontFamily:'Calibri', color:'#6c6c6c'}}>
                  <span></span>
                </div>
              </div>

            </>
          }
          {
            this.state.errorProcess && 
            <>
              <div className="Contenedor" style={{textAlign:'left', width:'75%'}}>
                <h2 className="Leyenda" style={{textAlign:'left',width:'100%', margin:'2rem auto', fontFamily:'Calibri', textTransform:'none', color: '#6c6c6c'}}>{dict_contacto[this.props.selectedLanguage].error_message}</h2>
                <div className="footer-data texto-m" style={{width:'100%', fontFamily:'Calibri', color:'#6c6c6c'}}>
                  <span></span>
                </div>
              </div>

            </>
          }

        </div>  
    )
  }  
}} 
export default Opc;