import React from 'react';
export default {
    en:{
        title:`Know more about Galdisa`,
        video_url:"https://www.youtube.com/embed/fb1QZFxxC9I"
    },
    es:{
        title:`Conoce más sobre Galdisa`,
        video_url:"https://www.youtube.com/embed/3-dXeURsTg8"
    }
};