import React from 'react';
import './App.css';
import Concepto from './Concepto.js';

class BannerH extends React.Component{
  constructor(props){
    super(props);
    this.state={conceptos:this.props.conceptos};  
    this.moverIzq=this.moverIzq.bind(this);
    this.moverDer=this.moverDer.bind(this);
  }
  moverDer(){
    var arreglo=this.props.conceptos
    var elemento=arreglo[arreglo.length-1];
    arreglo.unshift(elemento);
    arreglo.pop();
    //console.log(arreglo);
    this.setState({conceptos:arreglo})
  }
  
  moverIzq(){
    var arreglo=this.props.conceptos
    var elemento=arreglo[0];
    arreglo.shift();
    arreglo.push(elemento);
    //console.log(arreglo);
    this.setState({conceptos:arreglo})
  }
  
  componentDidMount() {
    if (this.props.timer!==undefined){ 
     this.conceptos = setInterval(
       () => this.moverIzq(),
       this.props.timer*1000
     )};
   }
 
  
    render(){      
      //this.reordenar();
      if (this.props.conceptos!==undefined){
        return(
          <div id={this.props.id} className="Banner2rows" style={{width:"100%", backgroundImage:'url('+this.props.imagen+')', backgroundSize:'cover'}}>
          <div>{this.props.children}</div>
             <div className="BannerFlechas">
            {/*  <div style={{width:"3em",position:"relative",left:"2em"}}>
                <a onClick={()=>this.moverIzq()} className="flecha">{"<"}</a></div> */}
              <div id="BannerHorizontal" className="BannerH">
                {this.props.conceptos.map((concepto)=>
                <Concepto imagen={concepto.imagen}  estilo={this.props.estilo} key={concepto.key} nombre={concepto.nombre} titulo={concepto.titulo} descripcion={concepto.descripcion} link={concepto.link} ruta={concepto.ruta} imagen={concepto.imagen}/>
                )}                                        
              </div>
              {/* <div style={{width:"3em",position:"relative"}}
                ><a onClick={()=>this.moverDer()} className="flecha">{">"}</a></div> */}
            </div> 
          </div>
        )
              }else
              {return(<div className="BannerH">NO SE DEFINIO LA LISTA DE ELEMENTOS</div>)}
      }
      
    
    }

      
export default BannerH;