import React from 'react';
import './App.css';


class BannerPrincipal extends React.Component {
render(){
    
    return(
        

<div className="BannerA" style={{width:this.props.ancho, backgroundImage:'url('+this.props.imagen+')', ...this.props.style}}>
    {this.props.children}
    <p>{this.props.descripcion}</p>
          </div>
    )}}
    export default BannerPrincipal;