import React, {useState, useEffect} from 'react';
import './App.css';
import MenuSup from './MenuSup.js';
import PieDePagina from './PieDePagina.js';
import Opc from './funciones.js';
import BlogPage from './BlogPage'
import PrivacyPage from './PrivacyPage'

import ScrollToTop from './ScrollTop';

import Home from './views/Home';

import {
  Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import {LanguageContext} from './langContext';

import createBrowserHistory from 'history/createBrowserHistory';


var history = createBrowserHistory();

history.listen((location) =>{
  if(window.gtag){
    window.gtag('event', 'page_view', {
      page_path: location.pathname + location.search
    });
  }
});



let browserLanguage = navigator.language || navigator.userLanguage;
browserLanguage = browserLanguage.substring(0,2);
if(!([`es`, `en`].includes(browserLanguage))){
  browserLanguage=`es`; 
}

function App() {

  useEffect(()=>{
    if(window.gtag){
      window.gtag('event', 'page_view', {
        page_path: window.location.pathname
      });
    }
  }, []);
  const [language, changeLanguage] = useState(browserLanguage.substring(0,2));
  return (
    <LanguageContext.Provider value={{language, changeLanguage}   }>
      <LanguageContext.Consumer>
        {
          (context) =>       <Router history={history}>
          <ScrollToTop>
          <body>
            <header  id="encabezado">
                <MenuSup selectedLanguage={context.language} languageSelector={context.changeLanguage} />
            </header>       
            <div id="contenido">           
              <Switch>
                <Route key="index" exact path="/" >
                  <Home selectedLanguage={context.language} />
                </Route>
                <Route key="about" exact path="/aboutus">
                  <Opc val="1" selectedLanguage={context.language} />
                </Route>
                <Route key="products" exact path="/products">
                  <Opc val="2" selectedLanguage={context.language} />
                </Route>
                <Route key="detailproducts" exact path="/detailproducts">
                  <Opc val="3" selectedLanguage={context.language} />
                </Route>
                <Route key="services" exact path="/services">
                  <Opc val="4" selectedLanguage={context.language} />
                </Route>
                <Route key="blog" exact path="/blog">
                  <Opc val="5" selectedLanguage={context.language} />
                </Route>
                <Route key="blogPage" path="/blog/:post" 
                  render={(props)=><BlogPage {...props} selectedLanguage={context.language}/>} /> 
                <Route key="natural" exact path="/aviso">
                  <PrivacyPage selectedLanguage={context.language}/>
                </Route>
                <Route key="natural" exact path="/conoce">
                  <Opc val="7" selectedLanguage={context.language}/>
                </Route>
                <Route key="granel" exact path="/granel">
                  <Opc val="8" selectedLanguage={context.language}/>
                </Route>
                <Route key="articles" exact path="/articles">
                  <Opc val="9" selectedLanguage={context.language}/>
                </Route>
                <Route key="socialresp" exact path="/socialresp">
                  <Opc val="10" selectedLanguage={context.language}/>
                </Route>
                <Route key="cotizacion" exact path="/cotizacion">
                  <Opc val="11" selectedLanguage={context.language}/>
                </Route>
                <Redirect to="/" />
                  
              </Switch>
              <PieDePagina selectedLanguage={context.language}/>         
            </div>
            <div id="fb-root"></div>
      
            <div class="fb-customerchat"
              attribution="setup_tool"
              page_id="338684242951741"
              theme_color="#67b868"
              logged_in_greeting="Hola! ¿Cómo te podemos ayudar?"
              logged_out_greeting="Hola! ¿Cómo te podemos ayudar?">
            </div>
          </body>
          </ScrollToTop>
        </Router>
        }
      </LanguageContext.Consumer>
    </LanguageContext.Provider>
  );
}

export default App;