import React from 'react';
export default {
    en:{
        natural:'Natural peanut',
        procesados: 'Peanut ingredients',
        snacks:'Snacks',
        granel: 'Other bulk products',
        download: 'Download data sheet',
        variedades: 'We have a vast catalogue of gage and variety of peanuts that adapt to the requirements of any orders of ingredients or finished products',
        virginia_title: 'Virginia peanuts',
        virginia_text: 'The largest seeds, it is mainly sold toasted or with shell',
        runner_title: 'Runner peanuts',
        runner_text: 'Seeds with a vast variety of sizes. Great taste and excellent for toasting',
        espanol_title: 'Spanish peanuts',
        espanol_text: 'Smaller seeds with brown – redish skin. Usually sold for salted peanuts or as an ingredient to produce peanut butter',
        valencia_title: 'Valencia peanuts',
        valencia_text: 'Sweet seed covered with a thin red skin. It has three kernels in its shell. Usually sold roasted with skin or boiled ',
        productivo: 'Productive process',
        variedad: 'Varieties',
        calibres: 'Sizes',
        video_url: `https://www.youtube.com/embed/G1kzlxikHLY`,
        descubre: `Discover more`
    },
    es:{
        natural:'Cacahuate natural',
        procesados: 'Ingredientes procesados',
        snacks: 'Botanas y snacks',
        granel: 'Otros productos a granel',
        download: 'Descarga ficha técnica',
        variedades: ['Tenemos un amplio catálogo de calibres y variedades de cacahuate que se adaptan a las necesidades de cualquier pedido de ingrediente o producto terminado'],
        virginia_title: 'Virginia',
        virginia_text: 'Tiene las semillas más grandes y es el que más se vende tostado o con cáscara',
        runner_title:'Runner',
        runner_text: 'Semillas con amplia gama de tamaños. De muy buen sabor y son excelentes para tostado',
        espanol_title: 'Español',
        espanol_text: 'Semillas más pequeñas con cubierta café – rojiza. Suele venderse para cacahuate salado y como ingrediente hacer crema de cacahuate',
        valencia_title: 'Valencia',
        valencia_text: 'Semilla dulce cubierta por una delgada piel roja. Contiene tres semillas en la cáscara. Suele venderse tostado con cáscara o hervido',
        productivo: 'Proceso productivo',
        variedad: 'Variedades',
        calibres: 'Calibres',
        video_url: `https://www.youtube.com/embed/SjJtoU6PdwU`,
        descubre: `Descubre todo sobre las variedades / tipos de cacahuate`
    }
};