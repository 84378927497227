import React from 'react';
import './App.css';
import { isUndefined } from 'util';

class SoloTexto extends React.Component{
constructor(props){
  super(props);
  this.state={texto:" "};  
  this.handleChangeText=this.handleChangeText.bind(this);
}
static defaultProps ={
  titulo:"Titulo default",
  clickHandler2:""
  }
  
  handleChangeText=
  function(texto2) {
  this.setState({texto:texto2})  
  console.log('solotexto '+texto2);
 }
    render(){
      
      this.state.texto =this.props.texto;
      var link=this.props.link;

      
      if (isUndefined(this.state.texto)===false) {
        this.state.texto=[<p key="1">{this.state.texto}</p>];
      }
      if (isUndefined(link)===false) {
        link=[<p key="2">{link}</p>];
        }

        return(
            <div className="BannerInformativo texto-m" style={{textAlign:this.props.alineacion}} {...this.props}>            
              <h2 className={this.props.color} style={{textAlign:this.props.alineacion, fontSize:'2.5em'}}>{this.props.titulo}</h2>
              {this.state.texto && 
                <p style={{minHeight:"3em", color: this.props.textColor ? this.props.textColor : '#8d8d8d', width:'90%', margin:'0 auto', fontFamily:'Calibri', fontSize:'1em'}} className={this.props.color2} >{this.state.texto}</p>
              }
              {link}
              {this.props.children}
            </div>
        )
      
      
      }}

    
        export default SoloTexto;