import React from 'react';

import blog1 from '../images/1x/galdisa_blogImg1.jpg';
import blog2 from '../images/1x/galdisa_blogImg2.jpg';
import blog3 from '../images/1x/galdisa_blogImg3.jpg';
import blog4 from '../images/1x/galdisa_blogImg4.jpg';
import blog5 from '../images/1x/galdisa_blog_blog1.jpg';
import blog6 from '../images/1x/galdisa_blogImg5.jpg';
import blog7 from '../images/1x/galdisa_blogImg7.jpg';
import blog8 from '../images/1x/galdisa_blog_blog2.jpg';
import blog9 from '../images/1x/galdisa_blogImg8.jpg';
import blog10 from '../images/1x/galdisa_blogImg9.jpg';
import blog11 from '../images/1x/galdisa_blogImg11_bar.jpg';
import blog12 from '../images/1x/galdisa_blogImg12.jpg';
import blog13 from '../images/1x/galdisa_blogImg13.jpg';
import blog14 from '../images/1x/galdisa_blogImg14_bar.jpg';
import blog15 from '../images/1x/galdisa_blogImg15.jpg';
import blog16 from '../images/1x/galdisa_blogImg16.jpg';
import blog17 from '../images/1x/galdisa_blogImg17.jpg';
import blog18 from '../images/1x/galdisa_blogImg18.jpg';
import blog19 from '../images/1x/galdisa_blogImg19.jpg';
import blog20 from '../images/1x/galdisa_blogImg20.jpg';
import blog21 from '../images/1x/galdisa_blogImg21.jpg';
import blog22 from '../images/1x/galdisa_blogImg22.jpg';
import blog23 from '../images/1x/galdisa_blogImg23.jpg';
import blog24 from '../images/1x/galdisa_blogImg24.jpg';
import blog25 from '../images/1x/galdisa_blogImg25.jpg';
import blog26 from '../images/1x/galdisa_blogImg26.jpg';
import blog27 from '../images/1x/galdisa_blogImg27.jpg';
import blog28 from '../images/1x/galdisa_blogImg28.jpg';
import blog29 from '../images/1x/galdisa_blogImg29.jpg';
import blog30 from '../images/1x/galdisa_blogImg30.jpg';
import blog31 from '../images/1x/galdisa_blogImg31.jpg';
import blog32 from '../images/1x/galdisa_blogImg32.jpg';
import blog33 from '../images/1x/galdisa_blogImg33.jpg';
import blog34 from '../images/1x/galdisa_blogImg34.jpg';
import blog35 from '../images/1x/galdisa_blogImg35.jpg';
import blog36 from '../images/1x/galdisa_blogImg36.jpg';
import blog37 from '../images/1x/galdisa_blogImg37.jpg';
import blog38 from '../images/1x/galdisa_blogImg38.jpg';

import pepinos from '../pepinos.png';
import platanos from '../platano_crema.png';
import ensalada from '../ensalada_crujiente.png';
import granizado from '../granizado_cacahuate.png';
import ciclo_cultivo from '../images/1x/programa_campo_esquema.jpg';
import ciclo_cultivo_en from '../images/1x/programa_campo_esquema_en.jpg';
import infografia_embarazo from '../images/1x/infografia_galdisa_embarazo_es.jpg';
import infografia_embarazo_en from '../images/1x/infografia_galdisa_embarazo_en.jpg';

import infografia_diabetes from '../images/1x/infografia_galdisa_diabetes_es.jpg';
import infografia_diabetes_en from '../images/1x/infografia_galdisa_diabetes_en.jpg';
import { NavLink } from 'react-router-dom';


export default {
    
    es:{
        "beneficios-cacahuate":{
            "image": blog1,
            "title":"Principales beneficios del cacahuate",
            "desc": "El cacahuate es una planta de la familia de las leguminosas",
            "pars":[
                {"text":"El cacahuate es una planta de la familia de las leguminosas, al igual que el garbanzo, la lenteja, el frijol y los chícharos. Es originaria de Sudamérica pero se introdujo a México durante la época prehispánica.", className:"blog-page-par-first"},
                {"text":"El nombre cacahuate proviene del náhuatl tlacáhuatl, compuesto de las palabras tlalli, tierra, y cacahuatl, cacao, es decir, cacao de la tierra."},
                {"text":"La planta del cacahuate es un pequeño arbusto de entre 30 y 80 cm de altura. Lo interesante es que sus frutos crecen debajo de la tierra, entre las raíces, a diferencia de las nueces y almendras que crecen en los árboles. Las semillas de esta leguminosa cuentan con una cáscara dura y rugosa color café, dentro de la cual se pueden encontrar de 1-4 semillas. Las semillas miden de 5 - 10 mm de diámetro y suelen ser de color marrón. Esta es una planta anual que se siembra en primavera y se cosecha a finales de otoño."},
                {"text":"Su producción es muy importante para nuestro país; en 2018 en México se cultivaron 91 mil toneladas que representaron 1,100 millones de pesos."},
                {"text":["Este alimento cuenta con dos clasificaciones:  botánicamente se agrupan dentro de las legumbres, pero, para fines culinarios, de investigación y nutricionales, los cacahuates se consideran una nuez.  Como grupo, estas semillas proporcionan la mejor fuente de proteína concentrada en el reino vegetal. Si bien, su estructura física y sus beneficios nutricionales se parecen más a los de otras legumbres, el uso del maní en las dietas y cocinas se parece más al de los ", <a className="blog-link" href="http://www.goldfritz.com" target="_blank" rel="noreferrer noopener">frutos secos</a>,"."]},
                {"text":"Conoce sus principales beneficios: ", "list":[
                    "Mantienen bajos los niveles de colesterol y triglicéridos",
                    "Contienen compuestos bioactivos que ayudan a detener el crecimiento de las células cancerosas.",
                    "Son ricos en resveratrol, poderoso antioxidante que protege contra enfermedades cardiacas.",
                    "Contienen ácido P-cumarico, conocido por sus propiedades de protección contra el daño celular.",
                    "Son una excelente fuente de niacina, ingrediente que en buenas cantidades puede reducir hasta en un 70% el riesgo de padecer Alzheimer."
                ]},
                {"text":"Y estos son tan solo algunos de los beneficios que el cacahuate ofrece, sin duda, es un alimento que bien podría ser considerado como milagroso, por sus múltiples nutrientes y beneficios que tiene al consumirlo. Inclúyelo en tu dieta diaria y disfruta de su delicioso sabor. "},
                {"text":["En Galdisa conocemos a esta saludable semilla desde la siembra, lo que nos hace expertos para recomendar la mejor opción como ", <a className="blog-link" href="/products" target="_blank" rel="noopener noreferrer" >materia prima</a>, " en diferentes y variadas aplicaciones."]}
            ],
            "refs":[]
        },
        "regresando-a-compras-granel":{
            "image": blog2,
            "title":"Regresando a las compras a granel",
            "desc":"Esta forma de comprar es quizá una de las más antiguas",
            "pars":[
                {"text":"Las compras a granel no son para nada un tema nuevo, esta forma de comprar es quizá una de las más antiguas, en la que no solo los comerciantes estaban involucrados, muy seguramente, nuestras abuelas y bisabuelas optaban por realizar compras a granel en mercados. Sin embargo, aún en la actualidad, aunque ya se cuenta con clubes de precio que ofrecen productos empaquetados para la venta al por mayor, se sabe que los alimentos comprados a granel son en cierto modo más “saludables” y “frescos” debido a que no contienen conservadores utilizados para prolongar su tiempo de vida dentro de los empaques y de los anaqueles del supermercado.", className:"blog-page-par-first"},
                {"text":["Es justamente por la calidad de los alimentos y los precios competitivos que en la segunda mitad del siglo XX nace la ",<a className="blog-link" href="http://www.goldfritz.com" target="_blank" rel="noreferrer noopener">Central de Abastos</a>," de al menos 327 hectáreas, maneja un flujo de recursos de 9 mil millones de pesos al año, convirtiéndola en el segundo punto de operaciones del país, después de la Bolsa Mexicana de Valores."]},
                {"text":["Así que, pierde el miedo de las ",<a className="blog-link" href="http://www.goldfritz.com" target="_blank" rel="noreferrer noopener">compras a granel</a>," que no solo resulta ser más saludable, también es más económico comprar sin pagar el empaquetado, es más natural la conservación de los alimentos y es ¡eco-friendly!  Te dejamos algunos consejos para que tus siguientes compras sean aún más eficientes:"],"list":[
                    "Para tus compras a granel no olvides portar tu bolsa de tela o reutilizable, comenzarás a comprar lo que necesitas sin dejar residuos, basura o comida que se termina tirando al final del mes.",
                    "Acude a lugares específicos para compras a granel, desde el mercado más cercano a tu hogar, hasta centros de distribución más grandes como “central de abastos” o “merced”",
                    "Programa tu visita a tempranas horas de la mañana para encontrar los productos más frescos, terminar temprano y aprovechar al máximo el resto del día.",
                    "Pregunta a los comerciantes de la zona y compara los precios entre cada negocio, así, encontrarás los mejores precios y mayor variedad de productos." 
                ]},
                {"text":"Las compras más inteligentes no son solo las más económicas, sino, también la más saludables y beneficiosas para tu salud y la de tu familia. Apostar por las compras a granel es sin duda una excelente decisión."},
                {"text":["Te invitamos a revisar la sección de ",<a className="blog-link" href="/products" target="_blank" rel="noreferrer noopener">Productos</a>," para conocer nuestro catálogo. No dudes en contactarnos."]}
            ],
            "refs":[]
        },
        "alimentacion-grasas-sanas-beneficios":{
            "image": blog3,
            "title":"Alimentación con grasas sanas y sus beneficios",
            "desc":"Comer bien incluye elegir alimentos saludables que, además",
            "pars":[
                {"text":"Comer bien incluye elegir alimentos saludables que, además, sepan bien. El cacahuate hace que comer sanamente resulte fácil. Los cacahuetes son disfrutados por todos los grupos de edad y las investigaciones muestran que todos, sin importar la edad se benefician al comerlos.", className:"blog-page-par-first"},
                {"text":"Los cacahuetes son un alimento integral que contiene múltiples nutrientes en una sola porción. Vivir un estilo de vida saludable o tratar de perder peso puede parecer difícil a veces. A menudo, se piensa que restringir ciertos alimentos o grasas es el mejor enfoque para llegar al peso objetivo o ideal. Sin embargo, numerosas dietas que incorporan cacahuate o maní muestran que las personas obtienen mejores resultados con dietas que no eliminan a las famosas grasas saludables."},
                {"text":"Las grasas son un tipo de nutriente que se obtiene de la alimentación y son fundamentales para el funcionamiento del organismo. Existen 2 tipos de grasas en nuestra dieta: saturadas (grasas malas y grasas trans) e insaturadas (consideradas como grasas saludables)"},
                {"text":"Grasas saturadas: son sólidas a temperatura ambiente, elevan el colesterol malo y su principal fuente son las grasas de origen animal (tocino, mantecas, lácteos, embutidos etc.). Debemos evitarlas en medida de lo posible."},
                {"text":"Grasas insaturadas: son líquidas a temperatura ambiente y comúnmente se les conoce como aceites. Elevan los niveles de colesterol bueno y los triglicéridos en sangre, recogen la grasa depositada en las arterias. Están en los pescados, aceite de oliva, semillas oleaginosas como el girasol, cacahuates y en frutos secos como nueces y almendras."},
                {"text":"Más del 80% de las grasas que provienen del cacahuate son grasas insaturadas y por ello saludables para el corazón. Este tipo de grasas también nos aporta:","list":[
                    "Energía",
                    "Favorecen el desarrollo y actividad cerebral",
                    "Regulan la temperatura e inflamación",
                    "Transportan vitaminas",
                    "Forman parte de la estructura de todas las células de nuestro cuerpo, de las hormonas y de los ácidos biliares",
                    "Mantienen la piel y el cabello en buenas condiciones"
                ]},
                {"text":"No olvides que en la mayoría de las pirámides alimentarias que se han desarrollado para ayudarnos a tener una dieta saludable se recomienda seguir los siguientes porcentajes diariamente:  30% grasas, 30% proteínas y 40% carbohidratos."},
            ],
            "refs":[]
        },
        "ideas-lunch":{
            "image": blog4,
            "title":"No te quedes sin ideas de ¡recetas para lunch!",
            "desc":"La hora del almuerzo se vuelve más divertida y nutritiva",
            "pars":[
                {"text":"La hora del almuerzo se vuelve más divertida y nutritiva si agregas como ingrediente base: ¡el cacahuate! Ten a la mano estas deliciosas y muy sencillas recetas: ", className:"blog-page-par-first"},
                {"title":"Pepinos Locos", "text":"Necesitas:", "list":[
                    "Pepinos",
                    "Zanahorias",
                    "Cacahuates de tu preferencia",
                    "Limón y sal",
                    "Picante al gusto"
                ], "gallery":[{src:pepinos, alt:'Pepinos locos'}]},
                {"text":"Pela los pepinos y córtalos primero por mitad (a lo ancho) y luego otra vez por mitad (a lo largo). Con ayuda de una cuchara quítales todo el centro (las semillitas) y déjalos limpios como si fueran unas lindas trajineras. Ralla la zanahoria en un bowl y revuélvela con cacahuates, limón, sal y salsa al gusto. Por último con una cuchara agrega la mezcla en los pepinos ¡y listo! "},
                {"title":"Sándwich de plátano y crema de cacahuate", "text":"Necesitas:", "list":[
                    "Pan de caja",
                    "Plátanos",
                    "Crema de cacahuate",
                    "Amaranto",
                ], "gallery":[{src:platanos, alt:'Sándwich de plátano y crema de cacahuate'}]},
                {"text":"Inicia tostando el pan, una vez listo, agrega la crema de cacahuate, el plátano en tiras y amaranto al gusto.  El desayuno más nutritivo y delicioso sí existe."},
                {"title":"Ensalada crujiente", "text":"Necesitas:", "list":[
                    "Lechuga",
                    "Péchuga asada",
                    "Queso panela",
                    "Mango",
                    "Cacahuates"
                ], "gallery":[{src:ensalada, alt:'Ensalada crujiente'}]},
                {"text":"Limpia y desinfecta la verdura y córtala. Agrégala a un bowl con la pechuga ya asada y el cuadritos, el queso panela, mango y cacahuates al gusto."},
                {"text":"Es una ensalada práctica, sencilla y nutritiva. Puedes acompañarla con un aderezo dulce y de cacahuate."},
                {"title":"Granizado de cacahuate", "text":"Necesitas:", "list":[
                    "1 taza de cacahuate pelado y tostado",
                    "2 tazas de azúcar",
                    "1 litro de leche y 1 litro de agua",
                    "Vainilla",
                ], "gallery":[{src:granizado, alt:'Granizado de cacahuate'}]},
                {"text":"PASOS:", list:[
                    "Deja remojando el cacahuate en el agua durante 20 min",
                    "Licúa los cacahuates con el agua en la que se remojaron y añade a un bowl grande la mezcla con el litro de leche, la cucharada de vainilla y el azúcar",
                    "Llena tus bolsitas, hazles un nudo y llévalas a congelar."
                ]},
                {"text": ["Nuestra marca hermana ",<a className="blog-link" href="http://www.goldfritz.com" target="_blank" rel="noreferrer noopener">Gold Fritz</a> ," te ofrece variedad de cacahuates y semillas listos para añadir a tus recetas."]}
            ],
            "refs":[]
        },
        "programa-campo":{
            "image": blog5,
            "title":"Programas de campo",
            "desc":"Galdisa mantiene, desde hace más de 15 años, programas agrícolas",
            "pars":[
                {"title":"Programa Agrícola","text":"Galdisa mantiene, desde hace más de 15 años, programas agrícolas dedicados a cacahuate con agricultores del norte del país. Nuestros programas, en general, consisten en proveer de semillas certificadas, asistencia técnica y agroquímicos aprobados, para asegurar desde el inicio de la cadena de abasto, la calidad de sus productos terminados asociados.", className:"blog-page-par-first"},
                {"text":"Todo el cacahuate asociado a nuestros programas se recibe en nuestros puntos de compra, para después ser descascarado, clasificado y seleccionado para su comercialización o procesamiento."},
                {"title":"Etapas del cultivo de cacahuate:"},
                {"text":<div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}><img src={ciclo_cultivo} style={{width:'70%', margin:'2rem auto'}} alt="Ciclo de cultivo"/></div>}
            ],
            "refs":[]
        },
        "beneficios-cacahuate-embarazo":{
            "image": blog6,
            "title":"Beneficios de los cacahuates durante el embarazo",
            "desc":"Muchos desconocen los beneficios y nutrientes que aportan los cacahuates en la etapa del embarazo",
            "pars":[
                {"text":"La mayoría de las personas consideran a los cacahuates como el snack perfecto para saciar el hambre, pero muchos desconocen los grandes beneficios y nutrientes que estos aportan en la etapa del embarazo.", className:"blog-page-par-first"},
                {"text":"La enorme cantidad de ácido fólico, aminoácidos, vitaminas y minerales, lo convierten en el aperitivo ideal para contribuir al buen desarrollo de las células del bebé durante su gestación, y no sólo a éste, sino también a la madre ¡Una ganancia doble!"},
                {"text":<div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}><img src={infografia_embarazo} style={{width:'100%', margin:'2rem auto'}} alt="Infografía embarazo"/></div>}
            ],
            "refs":[]
        },
        "snack-ideal-diabetes":{
            "image": blog7,
            "title":"El snack ideal para diabéticos",
            "desc":"if you have been diagnosed with diabetes, forbidden foods become a central issue every time .",
            "pars":[
                {"text":"Sabemos que si has sido diagnosticado con diabetes, los alimentos prohibidos se vuelven un tema central cada vez que vas de compras o comes en otro sitio ajeno a tu casa, es muy importante aprender a elegir alimentos que nos ayuden a controlar el avance de nuestra enfermedad, el cacahuate es uno de estos alimentos: Esta famosa legumbre nos ayuda a mantener estables los niveles de glucosa en la sangre, además de liberar insulina en nuestro cuerpo, evita riesgos de gangrena vinculados al pie diabético y demás infecciones. Conoce más de los beneficios que aportan los cacahuates para apegarnos a nuestro tratamiento dietético y recuerda comer un puñito en el desayuno.", className:"blog-page-par-first"},
                {"text":<div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}><img src={infografia_diabetes} style={{width:'100%', margin:'2rem auto'}} alt="Infografía Diabetes"/></div>}
            ],
            "refs":[]
        },
        "leche-cacahuate":{
            "image": blog8,
            "title":"¿Cómo preparar leche de cacahuate?",
            "desc": "Una alternativa cada vez más popular  (leche vegetal, vegana)",
            "pars":[
                {"text":"Como hemos platicado en artículos anteriores el cacahuate es una legumbre que contiene una alta cantidad y variedad de proteínas, grasas, hidratos de carbono y vitaminas.", className:"blog-page-par-first"},
                {"text":"El cacahuate es también un alimento muy versátil que se puede ocupar en un sinfín de recetas ya sea como protagonista, botana e incluso cómo ¡bebida!"},
                {"text":"Sí, la leche de cacahuate cada día se vuelve más conocida ya que es muy saludable pero también bastante económica debido a que su preparación se puede realizar desde la comodidad de nuestros hogares. ¿Ya la probaste?  Si aún no lo has intentado te dejamos una receta sencilla para que le des una oportunidad."},
                {"title":"Ingredientes: ", "list":[
                    "1 taza de cacahuates (pelados, crudos y sin sal)",
                    "3 tazas de agua ",
                    "Opcional si la quieres endulzar (miel, azúcar, splenda, agave) ",
                    "1 cucharadita de canela y vainilla ",
                ]},
                {"title":"Pasos a seguir: ", "list":[
                    "1. En un tazón de vidrio coloca la taza de cacahuates y vierte 3 tazas de agua. ",
                    "2. Deja reposar por 8 horas.",
                    "3. Pasadas las 8 horas, escurre los cacahuates y enjuaga. ",
                    "4. Licúa la tasa de cacahuates hasta obtener una pasta. ",
                    "5. Agrega nuevamente 3 tazas de agua y bate durante 5 minutos.",
                    "6. Si deseas que la leche quede dulce, viértela en la licuadora y agrega 1-2  cucharadas del endulzante de tu preferencia, así como vainilla y canela. ",
                ]},
                {"text":"Valdrá la pena la espera pues su delicioso sabor definitivamente te recordará a una buena tarde fría en compañía de cacahuates, pero en su forma líquida con una consistencia rica, intensa y espesa. Además de que esta leche es 100% vegana, vegetal y libre de explotación animal."},
                {"text":"Las leches vegetales son una muy buena opción para aquellas personas intolerantes a la lactosa, para las personas que llevan una dieta vegana y para los que buscan mantener una nutrición adecuada. Entre sus beneficios: son bebidas de fácil digestión ayudan a que la piel se vea más joven y luminosa, favorecen la liberación del estrés y por lo tanto, ayudan a dormir mejor. "}
            ],
            "refs":[]
        },
        "nutricion-deportiva":{
            "image": blog9,
            "title":["Nutrición Deportiva"],
            "desc": "El cuerpo humano está diseñado para mantenerse en constante movimiento...",
            "pars":[
                {"text":"El cuerpo humano está diseñado para mantenerse en constante movimiento, desde hace miles de años, nuestros genes se han mantenido codificados para ello.", className:"blog-page-par-first"},
                {"text":"Hoy en día las tendencias orientadas a un estilo de vida saludable y activo se encuentran más fuertes que nunca. Cada vez son más las personas que buscan llevar una alimentación balanceada, utilizar productos naturales, informarse o leer artículos sobre liberación de estrés y vida balanceada, etc. "},
                {"text":"En conjunto con esta tendencia, ha crecido el número de personas que buscan realizar actividad física, quizás no de manera profesional o de alto rendimiento, pero sí de forma constante e intensa."},
                {"text":"Todos estos cambios dentro de nuestros estilos de vida deben de venir acompañados por modificaciones o ajustes en la alimentación. Realizar actividad física provoca desgaste en nuestro cuerpo por lo que acoplarse a una dieta deportiva brindará los siguientes beneficios: ", "list":[
                    "1. Contar con más energía durante los entrenamientos.",
                    "2. Mejorar la recuperación para sentirse menos adolorido y prevenir lesiones",
                    "3. Lograr resultados como la construcción de los músculos y/o condición física ",
                ]},
                {"text":"Después de finalizar nuestra rutina de ejercicio es importante recuperar los nutrientes perdidos. Según Beatriz Boullosa, presidenta de la Federación Mexicana de Nutrición Deportiva, se recomienda consumir hidratos de carbono o combinarlos con una proteína para potenciar la síntesis de glucógeno en el músculo. "},
                {"text":"Una comida balanceada para mantenerse en forma debe contener: carbohidratos para alimentar las células, proteínas para construir y reparar las células, y grasa para proteger las células."},
                {"text":"¿Dónde encontrar hidratos de carbono y grasas saludables?", "list":[
                    "Frutos secos",
                    "Cacahuates",
                    "Crema de cacahuate",
                    "Nueces",
                    "Pepitas",
                    "Garbanzo/Humus"
                ] },
                {"text":"Los cacahuetes contienen 7 gramos de proteína por onza, más que cualquier otro fruto seco, por lo que brindan energía duradera y favorecen el crecimiento muscular, así como su recuperación. Los cacahuates son una fuente de fibra y contienen grasas buenas, lo cual es importante para la salud del corazón."},
            ],
            "refs":[]            
        },
        "lomo-salsa-cacahuate":{
            "image": blog10,
            "title":["Lomo en Salsa de Cacahuate"],
            "desc": "Diciembre es una época muy especial, ya que es tiempo de compartir...",
            "pars":[
                {"text":"Diciembre es una época muy especial, ya que es tiempo de compartir, reflexionar sobre lo que hemos vivido en el año y con ello vienen muchas emociones, sentimientos y sabores que se traducen en felicidad, paz y armonía.", className:"blog-page-par-first"},
                {"text":"En cada familia, las fiestas se viven de forma distinta de acuerdo con sus tradiciones y creencias, es decir, hay un toque único y personal, pero sin duda en todas las celebraciones, lo que no puede faltar es… ¡la comida!"},
                {"text":"Te dejamos una receta deliciosa para sorprender a tus seres queridos: Lomo de cerdo en salsa de cacahuate. "},
                {"title":"Ingredientes", "list":[
                    "200 gramos de cacahuates pelados y sin sal.",
                    "4 chiles chipotle",
                    "1/2 pieza de cebolla fileteada",
                    "2 dientes de ajo",
                    "1 kilo de lomo de cerdo ",
                    "Manteca de cerdo",
                    "2 tazas de caldo de pollo",
                    "5 jitomates"
                ]},
                {"title":"Instrucciones a seguir:", "list":[
                    "1. Freír: Primero fríe los cacahuates en manteca y retíralos cuando estén dorados.",
                    "2. Licuar y cocinar: Licúa los 5 jitomates, la cebolla, ajos, caldo de pollo, y el cacahuate.",
                    "3. Cortar: Corta en pedazos iguales el lomo de cerdo, no tienen que ser cortes muy profundos.",
                    "4. Hornear: Envuelve en papel aluminio y hornea a 180 grados por 1 ½ horas o hasta que esté cocido.",
                    "5. Sacar: Saca el lomo de cerdo y sube la temperatura del horno a 200 grados.",
                    "6. Quitar: Quita el papel de aluminio y agrega el jugo que desprende, mientras se horneaba, regresa al horno y vuelve a hornear por 25 minutos más.",
                    "7. Reposar: Deja que repose fuera del horno por 10 minutos para poder servir.",
                    "8. Disfrutar: Disfruta y celebra el cierre de este año con quienes más quieres."
                ]},                

                {"text":"Tip: Si deseas añadir cacahuate picado para incrementar el sabor, agrégalo al momento de servir."},
            ],
            "refs":[]            
        },
        "tendencias-alimentarias-pandemia":{
            "image": blog11,
            "title":["Tendencias alimentarias que surgen a raíz de una pandemia"],
            "desc": "Desde la llegada del SARS- CoV-2, todos hemos sufrido una serie de cambios radicales en nuestras vidas...",
            "pars":[
                {"text":"En tiempos de pandemia las únicas constantes han sido el cambio, la adaptación y la incertidumbre.  Desde la llegada del SARS- CoV-2, todos hemos sufrido una serie de cambios radicales en nuestras vidas, desde la forma en la que trabajamos, nos ejercitamos, convivimos, hasta la forma en la que nos alimentamos.", className:"blog-page-par-first"},
                {"text":"Debido a ello, la industria alimentaria también ha sufrido algunos cambios y ha tenido que adaptarse a lo que hoy llamamos “la nueva normalidad”, la pandemia representa un reto para la industria, pero también un área de oportunidad para adaptarse a nuevas tendencias."},
                {"text":"Los proveedores y marcas de alimentos han tenido que adaptar sus ofertas hacia un consumidor que se encuentra la mayor parte del tiempo en casa. Por lo que la comida para llevar ha cobrado relevancia y ha tomado un nuevo significado, ahora, los comensales crean sus propias experiencias y momentos de disfrute a partir de la comida preparada o pedida al hogar. "},
                {"text":"Tomando en cuenta todo lo anterior, durante la emergencia sanitaria generada por la COVID- 19, han surgido 5 tendencias que aumentarán y prosperarán en los hábitos alimentarios de nuestros consumidores durante 2021 y que debemos de tomar en cuenta para tener una oferta que responda a estas nuevas necesidades:"},
                {"title":"1) INMUNIDAD", "text":"Sin duda, ha crecido la demanda por productos e ingredientes que ayudan a fortalecer el sistema inmune. La tendencia de la “inmunidad” llegó para quedarse. Ahora los consumidores basan sus compras en alimentos o bebidas que ayuden a mejorar sus defensas. La oferta de vitaminas en bebidas como juegos preparados o en botanas ricas en vitaminas D y E han sido los grandes ganadores de esta tendencia."},
                {"title":"2) PAN ", "text":"El pan, por su versatilidad, comodidad y facilidad es la clave de todo.  Ahora surge un nuevo concepto de entretenimiento que consiste en preparar pan en casa. Estos panes se crean a partir de un sin fin de recetas extravagantes que incluyen desde pistaches, semillas de girasol, arándanos, hasta amaranto y cacahuates. Un delicioso pan recién hecho en casa es la base para la tendencia #2."},
                {"title":"3) SÁNDWICH GOURMET", "text":"Regresan los sándwiches de la vieja escuela, pero ahora más “PRO”.  Podemos encontrar sándwiches dulces con crema de cacahuate o salados que además de jamón y queso pueden contener semillas para incrementar su valor nutricional."},
                {"title":"4) ¡CARNE ASADA!", "text":"Los amantes de las parrilladas han podido hacer uso de ese asador y roofgarden que antes únicamente se usaba durante el verano. Estar en casa ha despertado nuestro instinto de chefs y más de uno hemos puesto a prueba a nuestro asador con nuevas recetas deliciosas y cortes de carne de envidia."},
                {"title":"5) PRODUCTOS LÁCTEOS VEGETALES", "text":"Los productos alternativos hechos a bases de cacahuates, nueces o frutos secos han pasado a formar parte de la dieta diaria de las personas en busca de una alternativa a la leche, incluso las cafeterías la incluyen como una opción de leche dentro de sus bebidas, atrayendo a consumidores mas jóvenes con beneficios ecológicos y una alternativa sana."},
                {"text":["Galdisa ofrece a la industria alimentaria, diversas opciones para incluir, entre otras aplicaciones, en la panificación y la confitería. Te invitamos a visitar nuestra ", <a className="blog-link" href="/products" target="_blank" rel="noreferrer noopener">sección de productos.</a>]}
            ],
            "refs":[]            
        },
        "sindrome-metabolico":{
            "image": blog12,
            "title":["El consumo de cacahuetes puede ayudar a revertir el síndrome metabólico"],
            "desc": "El síndrome metabólico es el nombre de un grupo de factores de riesgo que incluyen enfermedades cardiacas...",
            "pars":[
                {"text":"El síndrome metabólico es el nombre de un grupo de factores de riesgo que incluyen enfermedades cardiacas, diabetes y otros problemas de salud que a menudo se presentan de forma simultánea. Las personas que sufren de síndrome metabólico tienen al menos 3 de los siguientes factores de riesgo:", className:"blog-page-par-first",
                    "list":[
                        "Cuerpo en \"forma de manzana\", también conocido como obesidad abdominal.",
                        "Alto nivel de trigliceridos, un tipo de grasa mala que se encuentra en la sangre.",
                        "Bajo nivel de colesterol HDL. El HDL se conoce como colesterol \"bueno\" porque ayuda a eliminar el colesterol \"malo\" de las arterias.",
                        "Presión arterial alta. Una presión arterial elevada de forma constante puede dañar el corazón y desencadenar otros problemas de salud.",
                        "Alto nivel de azúcar en la sangre en ayunas: El nivel de azúcar elevado puede ser un signo temprano de diabetes."
                    ]
                },
                {"text":"Cuantos más factores se tengan, mayor será el riesgo de enfermedades cardíacas, diabetes y accidente cerebrovasculares."},
                {"text":"El síndrome metabólico tiene varias causas, algunas de ellas son la obesidad o sobrepeso, acompañado de un estilo de vida sedentario y resistencia a la insulina."},
                {"text":"El tratamiento para el síndrome metabólico consiste en tratar adecuadamente cada una de las  enfermedades subyacentes (diabetes, hiperinsulinemia, niveles elevados de colesterol o presión arterial alta)."},
                {"text":"Como parte complementaria del tratamiento se recomienda realizar ejercicio y llevar una dieta adecuada para adelgazar, esto ayudará a mejorar la sensibilidad a la insulina y reducir la presión arterial y los niveles de colesterol."},
                {"title":"Un gran aliado: El cacahuate", "text":"Un estudio realizado por el Instituto del Cacahuate, dirigido por investigadores de Harvard, demostró que comer dos onzas de cacahuetes ligeramente salados al día durante 12 semanas puede ayudar a revertir la condición médica conocida como síndrome metabólico. En el estudio se demuestra que aquellos que comieron cacahuetes tuvieron una probabilidad dos veces mayor de revertir su síndrome metabólico que el grupo de control."},
                {"text":[<i>"Este estudio es único porque sólo añadió un nuevo elemento a la dieta de los participantes -dos porciones de cacahuetes al día-" Dice la doctora Samara Sterling, directora de investigación de The Peanut Institute. "La investigación mostró el impacto de los cacahuetes ricos en nutrientes y cómo afectaron positivamente a los resultados de salud de los participantes".</i>]},
                {"text":["El estudio también demostró que comer dos porciones de cacahuetes al día durante 12 semanas no hizo que los participantes aumentaran de peso. ", <i>Se trata de una investigación apasionante porque respalda el planteamiento de que los alimentos pueden considerarse una medicina en la lucha contra las enfermedades crónicas", también comentó Sterling. "Los cacahuetes están repletos de nutrientes y son capaces de aportar beneficios para la salud en una ración pequeña y asequible".</i>]},
                {"text":"Dos raciones, o sea, unos 70 cacahuetes, tienen un precio por debajo de los $20.00, tienen unas 170 calorías y contienen 14 gramos de proteínas vegetales, además de 19 vitaminas y minerales. Los cacahuates son alimentos que poseen antioxidantes, sustancias vegetales y de grasas de buena calidad  que ayudan a reducir el tan llamado “colesterol malo” y aumenta el colesterol bueno"},
                {"text":"¿Quién no conoce el cacahuate? Este pequeño alimento es my conocido, dentro de la gastronomía mexicana, haciéndolo el protagonista de deliciosos platillos o bien, conocido por ser una deliciosa botana o snack saludable y que además ayuda a calmar el hambre. Ahora ya sabes que el cacahuate, también puede ser increíblemente beneficioso para la salud  de aquellas personas que sufren síndrome metabólico."},
            ],
            "refs":[]            
        },
        "cafe-cacahuate":{
            "image": blog13,
            "title":["Café de cacahuate, la nueva alternativa para los amantes del café."],
            "desc": "Para no renunciar al delicioso café de la mañana, los seres humanos hemos buscado alternativas...",
            "pars":[
                {"text":"El olor del café es uno de los preferidos de nuestro cerebro, pues sin duda, no existe un olor más rico que el de un café recién hecho. El sentir cómo su aroma te despierta por las mañanas y te ayuda a entrar poco a poco en el mundo, no tiene precio.", className:"blog-page-par-first"},
                {"text":"Sin embargo, es muy común que éste tenga efectos negativos en las personas y por ello algunos de nosotros nos vemos obligados a limitar su ingesta. Insomnio, taquicardia, dolor de cabeza e incluso ansiedad son solo algunos de los padecimientos que nos puede traer la cafeína que se encuentra tanto en el café como en algunos tés."},
                {"text":"Para no renunciar al delicioso café de la mañana, los seres humanos hemos buscado alternativas que nos ayuden a evitar estos síntomas, pero que a la par  resulten igual de deliciosas y placenteras."},
                {"text":["Si eres fan de los ", <a className="blog-link" href="https://www.galdisa.com/static/media/Galdisa_Ficha_Cacahuate_Secado.4661fd8b.pdf" target="_blank" rel="noopener noreferrer">cacahuates</a>," en todas sus presentaciones, desde un sándwich con ", <a className="blog-link" href="https://www.galdisa.com/static/media/Galdisa_Ficha_Crema_Cacahuate.880b4ef0.pdf" target="_blank" rel="noopener noreferrer">peanut butter</a>," o simplemente comer un puñito como snack, te tenemos la mejor noticia: Gracias al descubrimiento que hicieron los agricultores de Suffolk, Virginia, el cacahuate podría cambiar la vida para los amantes del café."]},
                {"text":"Sus características le permiten fungir como un excelente grano de café. El café de cacahuate es una bebida natural sin cafeína y tiene una serie de beneficios: poca acidez, no genera dependencia y no es diurético."},
                {"title":"¿Qué es el café de cacahuate?", "text":"Nos cuenta John Harrel, propietario y creador del café de cacahuate, que el proceso consiste en la extracción del aceite de cacahuete, “la extracción deja una sustancia fina y pulverulenta que se parece mucho al espresso en su aspecto, posteriormente, se mezcla el polvo fino con un tamaño de partícula más grueso para conseguir una consistencia similar a la del café molido tradicional."},
                {"text":"El café de cacahuate sorprendentemente tiene un sabor muy similar al del café tradicional. Es suave y tiene un toque tostado. Se puede preparar muy fácil en casa de la misma manera que preparas tu café de las mañanas o usando una prensa francesa."},
                {"text":"Sin duda, el cacahuate es un alimento muy conocido dentro de la gastronomía mexicana, reconocido por los múltiples nutrientes y beneficios que aporta al organismo. Ahora, también pasa a ser nuestro fiel acompañante para despertar en las mañanas."},
            ],
            "refs":[]            
        },
        "consumo-cacahuate-pandemia": {
            "image": blog14,
            "title":["El consumo de cacahuate ha aumentado durante la pandemia"],
            "desc": "La pandemia ha alterado muchos aspectos de nuestra vida diaria, el consumo del cacahuate ha aumentado de una manera exponencial...",
            "pars":[
                {"text":["La pandemia ha alterado muchos aspectos de nuestra vida diaria, principalmente la manera de consumir. Las nuevas tendencias en compras de productos y servicios toman un papel importante en nuestra cotidianidad. El consumo del ",<a href="/products" target="_blank" className="blog-link" rel="noopener noreferrer">cacahuate</a>," ha aumentado de una manera exponencial y gracias a sus diferentes presentaciones se ha convertido en el snack favorito de muchos."], className:"blog-page-par-first"},
                {"text":["Desde el inicio de la ",<a href="/blog/tendencias-alimentarias-pandemia" className="blog-link" target="_blank" rel="noreferrer noopener">pandemia</a>," los comportamientos de compra por parte de los consumidores han cambiado para adaptarse rápidamente a la nueva normalidad. Cada día más personas compran su comida a través de  aplicaciones móviles o la preparan en casa. Debido a esto, ahora los padres de familia y trabajadores home office buscan alternativas que proporcionen nutrientes, un rico sabor y facilidad de preparación. ¡Qué mejor que sea un puño de cacahuates o una rebanada de pan con ", <a className="blog-link" href="/products" rel="noreferrer noopener" target="_blank">crema de cacahuate</a>," con mermelada o frutas!"]},
                {"title":"Amor por la crema de cacahuate","text":"Con la nueva normalidad niños y adultos descubrieron su amor por la crema de cacahuate al ser una explosión de sabor nutritiva, versátil y también accesible a todos los bolsillos; Proporciona bienestar y saciedad en cualquier momento del día."},
                {"text":"Un reciente informe sobre el comportamiento de los consumidores, realizado por J.M. Smucker Company, afirma que la pandemia aceleró el crecimiento de la venta de crema de cacahuate en un 7.1 % durante las 52 semanas que terminaron el 1 de noviembre de 2020."},
                {"title":"¿Cómo ha aumentado?","text":["Los consumidores están cenando ", <a className="blog-link" href="/blog/beneficios-cacahuate" rel="noopener noreferrer" target="_blank">crema de cacahuate</a>, " y mermelada un 64% más, frente a un 49% tanto por las mañanas como por las tardes. Las generaciones más jóvenes lo están adaptando como un refrigerio."]},
                {"text":"No es de extrañarse que las ventas hayan aumentado en el último año un 95%, según Golin, pues por las múltiples combinaciones relacionadas con recetas y consejos de nutrición en redes sociales, un 55% mencionan como protagonista de sus platillos a la crema de cacahuate  y otros canales de comunicación con un 414%, como producto no perecedero."},
                {"title":["Cacahuates como ", <a className="blog-link" href="/products#botanas" target="_blank" rel="noopener noreferrer">botana</a>], "text":"El 2020 fue un año excepcional para los cacahuates como snack, ya que sus ventas aumentaron en 7.25% llegando a los mejores volúmenes de desplazamiento desde el 2016. El aumento fue superior al 7.2% en la categoría de frutos secos comparados con otros."},
                {"text":"Los cacahuates han sido nuestros fieles acompañantes en tiempos de incertidumbre, en otras palabras, es reconfortante para las personas compartir cacahuates en todas sus presentaciones y les causa alegría en tiempos difíciles. Sobretodo, hemos descubierto el amor hacia el cacahuate en  todas sus presentaciones."}
            ],
            "refs":[]            
        },
        "mindfulness-salud-mental-2021": {
            "image": blog15,
            "title":["4 formas de utilizar mindfulness para cuidar tu salud mental en 2021"],
            "desc": "El año pasado cambiamos nuestras rutinas diarias de forma drástica y pensamos que el 2021 sería mejor. ...",
            "pars":[
                {"text":["El año pasado cambiamos nuestras rutinas diarias de forma drástica y pensamos que el 2021 sería mejor. A pesar de estar en la etapa de vacunación se recomienda seguir en casa y las medidas de higiene recomendadas y, por esto, para los que trabajamos home office o somos padres de familia este año ha cambiado muy poco."], className:"blog-page-par-first"},
                {"text":["Seguir en casa en pandemia puede provocar estrés, ansiedad y falta de concentración. No te desesperes, así como te cuidas del coronavirus debes de cuidar tu salud mental. Te mereces un tiempo para estar libre de pensamientos y juicios hacia ti mismo y tu alrededor, un momento de tranquilidad. Por eso te recomendamos usar técnicas de mindfulness:"], "list":[
                    "Disfruta las pequeñas cosas: Cada día hacemos y vemos cosas cotidianas sin interés, como desayunar, ver el amanecer, pasear a tu mascota, etc. Te proponemos prestarles atención y encontrar la manera de disfrutarlas. Puedes anotarlas en tu smartphone o una libreta para regresar a ellas cuando lo necesites. ",
                    "Respira: Tómate unos minutos al día para ser consciente de tu respiración, estar presente y no abrumarnos por un futuro incierto. Concéntrate en tu inhalación y exhalación, siente como el aire entra y sale de tu nariz. Poco a poco te saldrá mejor, inténtalo.",
                    "Mueve tu cuerpo: Enfócate en realizar una actividad con regularidad para ayudarte a relajarte y desestresarte después de un día de trabajo. Te recomendamos practicar yoga, saltar la cuerda u otro ejercicio en casa. Así también liberarás endorfinas, conocidas como hormonas de la felicidad.",
                    "Conéctate con la naturaleza: Nos referimos a salir a caminar, sentarte en tu patio bajo el sol o disfrutar desde tu ventana el día y tus plantas. Si tu mente sigue divagando enfócate en un sonido, como el canto de los pájaros o el viento. Concéntrate en quién o qué lo produce, cómo es el ritmo, si te es agradable, etc. Disfruta tu entorno."
                ]},
                {"text":["El mindfulness lo podemos entender como estar en equilibrio contigo y tu alrededor, sin presiones, y una manera de complementar estar bien con tu cuerpo es comiendo ", <a className="blog-link" href="/blog/ideas-lunch" target="_blank" rel="noopener noreferrer">recetas saludables</a>, ". Te recomendamos un sándwich de plátano y crema de cacahuate, una ensalada crujiente con semillas o un refrescante granizado de cacahuate." ]},
            ],
            "refs":[]            
        },
        "palanquetas-distintas-semillas": {
            "image": blog16,
            "title":["Prepara palanquetas con distintos tipos de semillas"],
            "desc": "En estos tiempos de home office constantemente estamos buscando snacks nutritivos y fáciles de hacer en casa. La palanqueta es una de las mejores opciones ...",
            "pars":[
                {"text":["En estos tiempos de home office constantemente estamos buscando snacks nutritivos y fáciles de hacer en casa. La palanqueta es una de las mejores opciones por ser alta en proteínas, fibra y grasa vegetal; además, beneficia en la disminución del colesterol malo y ¡es deliciosa!"], className:"blog-page-par-first"},
                {"text":["¿Sabías que palanqueta deriva de la voz ‘papaquili’ que significa feliz o alegre? Este dulce típico mexicano es perfecto para aumentar tu energía entre comidas. Te enseñamos que a la hora de prepararla no solo debe llevar cacahuate, sino que la puedes complementar con diferentes semillas, arándanos y otros ", <a className="blog-link" href="/products" target="_blank" rel="noopener noreferrer" >ingredientes a granel</a> ," de alta calidad que ofrece ", <a className="blog-link" href="/aboutus" target="_blank" rel="noopener noreferrer" >Galdisa</a> ,"."]},
                {"text":["¡No hay excusa para tener a la mano esta rica botana!"]},
                {"title":"Necesitas:", "list":[
                    "2 tazas de cacahuate tostado natural",
                    "¼ taza de pepita pama sin cáscara",
                    "¼ taza de nuez de la india ",
                    "¼ taza de arándanos",
                    "2 tazas de azúcar",
                    "½ taza de miel de maíz",
                    "60 gr. de mantequilla",
                    "¼ taza de agua"
                ]},
                {"title":"Proceso:", "list":[
                    "1. En un sartén tuesta el cacahuate, la pepita pama y la nuez de la India hasta que suelten su aroma y reservarlos.",
                    "2. Pon el agua a fuego alto y poco a poco agrega la miel de maíz, la mantequilla y el azúcar hasta que empiece a hervir. Mueve con una palita constantemente para que se forme una textura de caramelo.",
                    "3. Agrega las semillas tostadas, los arándanos, la mantequilla y revuelve hasta que todo quede cubierto. Retira del fuego.",
                    "4. Extiende la mezcla en un recipiente engrasado previamente con mantequilla y corta rectángulos con un cuchillo húmedo.",
                    "5 Espera a que se enfríe y ¡disfruta de unas crujientes palanquetas!",
                ]},
                {"text":["UN CONSEJO: Para que tus palanquetas sepan mejor es mejor comprar semillas a granel, ya que no contienen conservadores que prolongan su tiempo de vida como los empaquetados. Siempre escoge las opciones más saludables, nutritivas y ricas."]},
                {"text":["Te invitamos a revisar la sección de ", <a className="blog-link" href="/products" target="_blank" rel="noopener noreferrer">productos</a>, " para conocer nuestro catálogo. No dudes en contactarnos." ]},
            ],
            "refs":[]            
        },
        "preparar-salsa-cacahuate": {
            "image": blog17,
            "title":["¿Cómo preparar salsa de cacahuate?"],
            "desc": "Los cacahuates, además de ser deliciosos y sumamente saludables, son muy conocidos por aportar proteínas, grasas y nutrientes que son benéficos para el cuerpo humano ...",
            "pars":[
                {"text":"Los cacahuates, además de ser deliciosos y sumamente saludables, son muy conocidos por aportar proteínas, grasas y nutrientes que son benéficos para el cuerpo humano. Es por ello que en Galdisa recomendamos incluirlos en tu dieta diaria, pero ¿solo se pueden consumir como botana? ¡Claro que no!  Hoy en día existen diversas formas de incluirlos dentro de tu plan alimenticio  que van desde comerlos crudos, tostados, como bebida, crema de cacahuate y, ahora hasta ¡salsa!", className:"blog-page-par-first"},
                {"text":["Aquí te dejamos una receta sencilla para preparar salsa de cacahuate en casa. ¡Una forma deliciosa de condimentar tu comida!"]},
                {"title":"Necesitas:", "list":[
                    "1 taza de cacahuates naturales sin cáscara",
                    "5 chiles de árbol al gusto",
                    "¼ de cebolla",
                    "Aceite de oliva",
                    "Sal",
                    "Agua",
                ]},
                {"title":"Preparación:", "list":[
                    "1. Coloca una sartén a fuego medio y vierte un poco de aceite de oliva. Espera a que se caliente.",
                    "2. Con cuidado agrega los chiles de árbol y los ajos. Dóralos y resérvalos.",
                    "3. Agrega las semillas tostadas, los arándanos, la mantequilla y revuelve hasta que todo quede cubierto. Retira del fuego.",
                    "4. En la licuadora añade los cacahuates, chiles, ajos, cebolla y sal.  Poco a poco añade agua para que no quede una consistencia tan espesa.",
                    "5. ¡Listo! Si quieres darle más textura a tu salsa puedes agregarle cacahuates sueltos.",
                ]},
                {"text":["No olvides comprar tus productos a granel para que estén más frescos y ricos. ¡Disfruta!"]},
                {"text":["Te invitamos a comprar tus cacahuates y ver las demás semillas que tenemos en nuestro ", <a className="blog-link" href="/products" target="_blank" rel="noopener noreferrer">catálogo</a>, " en ", <a className="blog-link" href="/" target="_blank" rel="noopener noreferrer">Galdisa</a> ]},
            ],
            "refs":[]            
        },
        "incluir-cacahuate-dieta": {
            "image": blog18,
            "title":["¿Por qué incluir el cacahuate, arándano y otras semillas en mi dieta diaria?"],
            "desc": "Cuando decimos que comer un puñado de semillas en tu día a día marcará la diferencia en tu salud, actitud y energía, ¡no es mentira! ...",
            "pars":[
                {"text":"Cuando decimos que comer un puñado de semillas en tu día a día marcará la diferencia en tu salud, actitud y energía, ¡no es mentira! Estos pequeños alimentos son una fuente muy importante de nutrientes para prevenir enfermedades, favorecer la piel y mejorar los niveles de colesterol. ¡No los dejes fuera de tu dieta!", className:"blog-page-par-first"},
                {"text":["Aquí te mostraremos los beneficios del cacahuate, arándano, nuez de la India y la pepita. Son excelentes acompañantes en las comidas o como snacks. Recuerda que comprarlos crudos y a granel es una opción más saludable, nutritiva y que ayuda al cuidado del medio ambiente."]},
                {"title":"Cacahuate","text":"Es considerado un superalimento por todos los beneficios que da a nuestra salud en su justa medida. Por ejemplo: prevenir enfermedades cardiacas, combatir la anemia, ayuda al sistema nervioso, previene el desarrollo de diabetes tipo 2, ¡y más!"},
                {"text":"Solo que para obtener todos estos beneficios es importante consumir al día de 25 a 30 gramos dependiendo de tu masa muscular. Puedes comerlos en ensaladas, snacks, comidas, etc."},
                {"title":"Arándano", "text":"El arándano rojo es muy común consumirlo deshidratado y como botana, sin embargo, hoy existen muchas formas de incluirlos en nuestras comidas diarias. Es muy conocido por ser un antioxidante y tener un alto contenido en vitaminas. Ayuda a prevenir infecciones urinarias, mejorar la salud intestinal, reduce el riesgo de enfermedades cardiovasculares y es ideal en dietas bajas en grasa. La cantidad recomendada a consumir es de 90 a 100 gramos al día. Puedes encontrarlos en snacks, bebidas, panes, ensaladas, etc. ¡No te arrepentirás!"},
                {"title":"Nuez de la india", "text":"Las nueces en general son famosas por acompañar todo tipo de postres o comidas, pero a veces se olvidan todos los beneficios que aportan a nuestra salud. La nuez de la India es un auxiliar en la disminución del colesterol, ayuda a tener cabello, piel y uñas más sanas; favorece la desinflamación del tracto digestivo y, si haces ejercicio, te ayuda a tonificar los músculos. Con 28 gramos al día podrás notar una gran diferencia. La puedes agregar en pastas, panes, ensaladas, etc."},
                {"title":"Pepitas", "text":"Las semillas de calabaza no se pueden quedar atrás si quieres mejorar tu salud, ya que son ricas en magnesio, ácidos grasos, omega 3 y fibra. Por todo esto beneficia la salud cardiovascular, disminuye la presión alta, ayuda al buen funcionamiento del corazón y el cerebro, entre otras funciones. Consumir de 10 a 15 gramos diarios es una muy buena opción para notar los cambios. Recuerda que, como las demás semillas, puedes prepararlas de diversas maneras. ¡Tu creatividad es el límite!"},
                {"text":["Es muy importante que no te excedas de las cantidades recomendadas en estos superalimentos, porque los resultados no serán los esperados."]},
                {"text":["Te invitamos a visitar nuestro ", <a className="blog-link" href="/products" target="_blank" rel="noopener noreferrer">catálogo</a>, " en ", <a className="blog-link" href="/" target="_blank" rel="noopener noreferrer">Galdisa</a>, " donde cada producto tiene su información nutrimental, sus diferentes aplicaciones para comerlos  comerlos y algunos beneficios. Cuidar tu alimentación es una parte fundamental del día a día." ]},
            ],
            "refs":[]            
        },
        "leche-mantequilla-cacahuate": {
            "image": blog19,
            "title":["¿Leche, mantequilla, harina y aceite de cacahuate?"],
            "desc": "¿Sabías que el cacahuate es más que una botana o un snack ? Día a día más personas reconocen las propiedades de los cacahuates...",
            "pars":[
                {"text":"¿Sabías que el cacahuate es más que una botana o un snack ? Día a día más personas reconocen las propiedades de los cacahuates y buscan consumirlos en sus diferentes presentaciones.", className:"blog-page-par-first"},
                {"text":["Si es que ya te aburriste de únicamente comer la semilla del cacahuate, la mantequilla, leche, harina y aceite son alternativas bastantes saludables, ya que aún modificando su presentación no se pierden las propiedades originales de este increíble alimento. ¿Te animas a probarlos?"]},
                {"title":"LECHE DE CACAHUATE","text":"La leche de cacahuate, o bebida vegetal, es una opción ideal para quienes son intolerantes a la lactosa, quieren cambiar su dieta o probar el rico sabor del cacahuate en sus bebidas favoritas. Contiene proteínas, grasas monoinsaturadas, vitamina E y B3, magnesio y fósforo. ¿Qué esperas para incluirla en tu dieta diaria y mejorar tu salud?"},
                {"title":"HARINA", "text":"Otro producto derivado del cacahuate es la harina, la cual es una excelente opción libre de gluten para dar un gran sabor a la panadería, repostería y hasta barritas energéticas. Además, al transformar el cacahuate en harina se reduce su cantidad de grasa entre 12% y 18%, un cambio drástico si consideramos que su porcentaje normal es de un 48%. Aunque, si la harina está desgrasada, aumenta su contenido en proteína al doble, pues pasa de un 25% a 50%. ¡Una excelente opción si buscas aumentar tu consumo de proteína y generar mayor masa muscular!"},
                {"title":"ACEITE", "text":"El aceite de cacahuate podría ser el menos conocido entre sus presentaciones, ya que es más utilizado en la gastronomía asiática por el rico sabor que aporta a la hora de guisar o aderezar. Sin embargo, poco a poco lo podemos incorporar a nuestras comidas para sofreír verduras o acompañar una ensalada.  Se debe de tener en cuenta que por su alto porcentaje de grasas no es recomendable usarlo en altas temperaturas o para freír."},
                {"text":"Por ser rico en vitamina 3 también es recomendable aplicarlo en masajes de rostro o cuerpo, e hidratante de labios."},
                {"title":"MANTEQUILLA", "text":"La mantequilla o crema de cacahuate es de sus presentaciones más famosas y favoritas gracias a su sabor, beneficios y versatilidad a la hora de comerla; puedes hacerte unas tostadas, acompañar un postre, agregarlo a un batido, etc. Y, si eres una persona que realiza actividad física, de manera constante, consumirla como snack puede ser muy beneficioso por su alto contenido calórico (2 cdtas. son 200 calorías) y de proteína (25%). ¡Anímate!"},
                {"text":["¿Cuál es tu presentación favorita? ¡Cada una tiene lo suyo para darle un toque de cacahuate a tu día! Todas estas presentaciones, a excepción del aceite, son muy fáciles de realizar en casa. En nuestra sección de ",<a className="blog-link" href="/blog" target="_blank" rel="noopener noreferrer">blog</a>," puedes encontrar “",<a className="blog-link" href="/blog/leche-cacahuate" target="_blank" rel="noopener noreferrer">¿Cómo preparar leche de cacahuate?</a>,"”, recetas e información que tenemos para ti."]},
            ],
            "refs":[]            
        },     
        "galletas-arana-cacahuate": {
            "image": blog20,
            "title":["Galletas de araña con crema de cacahuate.", "Happy Halloween!"],
            "desc": "¡Dale un toque diferente a tu noche de brujas!",
            "pars":[
                {"text":"¡Dale un toque diferente a tu noche de brujas! Las galletas de araña con crema de cacahuate, aparte de ser sencillas, le darán a tus invitados un adorable susto. Además, el cacahuate aporta ácidos grasos y minerales importantes para fortalecer el sistema inmunológico. ¡Una receta fácil y saludable!", className:"blog-page-par-first"},
                {"text":"Ten todos los ingredientes a la mano antes de empezar, porque tardarás muy poco en hacerlo. ¡Feliz Halloween!"},
                {"title":"Ingredientes",
                    "list":[
                        "½ taza de mantequilla a temperatura ambiente",
                        "½ taza de crema de cacahuate",
                        "½ taza de azúcar morena",
                        "1 huevo",
                        "2 cucharadas de leche entera",
                        "1 cucharada cafetera de bicarbonato de sodio",
                        "1 cucharada cafetera de sal",
                        "1 ¾ tazas de harina de trigo",
                        "18 bombones cubiertos de chocolate",
                        "36 ojitos de dulce ",
                        "100 g. de chocolate oscuro fundido "
                    ]
                },
                {"title":"Instrucciones",
                    "list":[
                        "Precalienta el horno a 160° C.",
                        "En un bowl mezcla la mantequilla y la crema de cacahuate hasta incorporarlos. Después agrega el azúcar morena, el huevo y las cucharadas de leche.",
                        "En otro recipiente mezcla la sal, el bicarbonato y la harina. Integra los ingredientes secos en el primer bowl hasta conseguir una masa.",
                        "Forma bolitas con la masa y cubre la charola con un poco de mantequilla. Coloca las galletas. ",
                        "Hornea durante 12 minutos. Saca las galletas, déjalas enfriar un poco, aplástalas ligeramente y con tu pulgar deja un espacio para el chocolate.",
                        "Coloca el bombón cubierto con chocolate encima de las galletas.",
                        "Refrigera por 20 minutos. ",
                        "Por último, pega los ojos y dibuja las patas con el chocolate derretido."
                    ]},
                {"text":["Recuerda que puedes saber la información nutrimental de la crema de cacahuate de Galdisa en nuestro ",<a className="blog-link" href="/products" target="_blank" rel="noopener noreferrer">catálogo de productos</a>," y así, tener un mayor control de tu dieta. No olvides que en tus tenebrosas fiestas puedes incluir como botanas nuestros cacahuates enchilados, pepitas, arándanos y más."]},
                {"text":"¡Conoce nuestra gran variedad de productos y disfrútalos!"}
            ],
            "refs":[]            
        },                
        "ensalada-navidena-posadas": {
            "image": blog21,
            "title":["Ensalada navideña ideal para las posadas"],
            "desc": "¿Te gustaría empezar las festividades navideñas con un platillo súper rico y acorde a estas próximas fechas? Tenemos la receta perfecta para que disfrutes de todo el sabor y color que podemos tener en diciembre.",
            "pars":[
                {"text":"¿Te gustaría empezar las festividades navideñas con un platillo súper rico y acorde a estas próximas fechas? Tenemos la receta perfecta para que disfrutes de todo el sabor y color que podemos tener en diciembre. Si no has probado esta tradicional receta, esperamos que la disfrutes.", className:"blog-page-par-first"},
                {"text":"Recuerda que para tener altas tus defensas y soportar las temperaturas bajas del invierno debes consumir vitamina C, carbohidratos y grasas; con este platillo tendrás todo eso y más. Tu cuerpo te agradecerá esta deliciosa ensalada.  Así que te invitamos a prepararla."},
                {"title":"Ingredientes",
                    "list":[
                        "2 betabeles",
                        "1 jícama",
                        "2 granadas",
                        "2 naranjas",
                        "1 lechuga pequeña",
                        "½ taza de cacahuates sin cáscara ",
                    ]
                },
                {"title":"Preparación",
                    "list":[
                        "1. Quita la cáscara de los betabeles y de las jícamas. Corta en pedazos pequeños.",
                        "2. Cuece los betabeles en una olla con agua suficiente para cubrirlos y reserva media o una taza del agua de la cocción.",
                        "3. Abre la granada, quita los granos y colócalos en un refractario; después pica en pedazos pequeños algunas hojas de la lechuga, lávalas y desinféctalas. Quita la cáscara de las naranjas y corta en rodajas.",
                        "4. En un bowl mezcla toda la fruta que picaste. Agrega el agua de la cocción de los betabeles para mantener las frutas hidratadas.",
                        "5. Al momento de servir, coloca primero las hojas de la lechuga, luego las frutas. Decora con las semillas de granada y los cacahuates.",
                    ]},
                {"text":"Con esta rica ensalada tendrás todo listo para las posadas y tus cenas navideñas."},
                {"text":["Recuerda que en ",<a className="blog-link" href="/blog" target="_blank" rel="noopener noreferrer">nuestro blog</a>," encontrarás más recetas para recibir las ventajas de los cacahuates y otras semillas durante el invierno."]},
            ],
            "refs":[]            
        },
        "desayuno-muy-navideno": {
            "image": blog22,
            "title":["Un desayuno muy navideño: Hot cakes de avena, plátano y crema de cacahuate"],
            "desc": "En estas épocas sentimos una atmósfera especial en el aire. Aún en los pequeños detalles se debe notar que estamos en tiempo de Navidad y, por qué no, hacer de un desayuno un manjar festivo para toda la familia.",
            "pars":[
                {"text":"En estas épocas sentimos una atmósfera especial en el aire. Aún en los pequeños detalles se debe notar que estamos en tiempo de Navidad y, por qué no, hacer de un desayuno un manjar festivo para toda la familia. Tú decides: puede ser el desayuno del 24 o del 25 tras abrir los regalos; la idea es que sea un desayuno diferente acorde a las celebraciones, pero también sencillo para que no tome mucho tiempo, pues el enfoque principal serán los regalos y compartir en familia.", className:"blog-page-par-first"},
                {"text":"Te proponemos esta diferente receta para darle un giro interesante a la preparación de unos deliciosos hot cakes más naturales y saludables.  Encontrarás un sabor  casero, acompañado de una buena cantidad de proteínas, vitaminas y minerales. Es la combinación ideal para acompañar con una bebida caliente como ponche de frutas o chocolate preparado."},
                {"text":"Aquí la receta. ¡Esperamos que los disfrutes! "},
                {"title":"Ingredientes",
                    "list":[
                        "½ plátano",
                        "1 huevo",
                        "½ taza de avena",
                        "1 cucharada de mantequilla",
                        "5 cucharadas de crema de cacahuate",
                    ]
                },
                {"title":"Preparación",
                    "list":[
                        "1. Tritura la avena en una licuadora hasta conseguir un polvo sin grumos. Reserva la harina de avena.",
                        "2. En la misma licuadora muele el huevo, el plátano y dos cucharadas de crema de cacahuate.",
                        "3. Añade poco a poco la harina a la mezcla hasta integrar todos los ingredientes.",
                        "4. Calienta una sartén a fuego lento, dibuja un círculo con la mantequilla y añade un poco de la mezcla que preparaste.",
                        "5. Cuando la parte superior esté seca, gíralo con una espátula y retíralo 45 segundos después.",
                        "6. Al momento de servir, cubre el hot cake con una capa de crema de cacahuate. Puedes agregar frutos rojos, nueces, semillas."
                    ]},
                {"text":"Recuerda que consumir cacahuates en el invierno fortalece tu sistema inmunológico y es una buena fuente de grasas saludables. "},
                {"text":["¡Felices fiestas!"]},
            ],
            "refs":[]            
        },
        "razones-cacahuate-superalimento": {
            "image": blog23,
            "title":["Tres razones para considerar al cacahuate un superalimento"],
            "desc": "Es común leer en sitios especializados en nutrición, fitness, cuidado infantil, entre otros, acerca de las distintas cualidades de los cacahuates.",
            "pars":[
                {"text":"Es común leer en sitios especializados en nutrición, fitness, cuidado infantil, entre otros, acerca de las distintas cualidades de los cacahuates. Sin embargo, esas perspectivas que sólo destacan una cualidad suelen cerrar el panorama y dejar de lado otros beneficios. Es por eso que para conocer todos los beneficios de los cacahuates es necesario analizarlo desde una perspectiva más amplia, considerando sus características, composición y, sobre todo, sus usos en la cocina.", className:"blog-page-par-first"},
                {"text":"Pero, antes de analizar sus características ¿qué es un superalimento? Es un término que se utiliza para señalar a los alimentos que son ricos en nutrientes: fibra, vitaminas, ácidos grasos, entre otros. Esto ayuda a reconocer cuáles alimentos destacan en su composición y que el consumidor los integre con mayor facilidad en su dieta."},
                {"text":"La cantidad indicada para obtener los beneficios que vamos a compartir en este blog son 30g. al día, cerca de 14 cacahuates enteros o una cucharada sopera de crema de cacahuate. Recuerda que en nuestro blog puedes encontrar recetas para incluir un poco más de esta semilla en tu alimentación."},
                {"text":"Empecemos por hablar sobre su aporte energético, pues contiene un 27% de proteínas y un 8% de fibra, que al combinarse favorecen la movilidad intestinal y se combate el estreñimiento. Así se fortalece el sistema inmune,  aporta energía al cuerpo, aumenta el nivel de saciedad y ayuda al crecimiento y desarrollo de los huesos. "},
                {"text":"En segundo lugar, los ácidos grasos suman el 50% de su composición y, de éstos, podemos destacar que ayudan a disminuir el colesterol LDL (colesterol “malo”) y aumentan el HDL (colesterol “bueno”), que se relaciona con una buena salud cardiovascular. Además, ayudan a mantener constantes los niveles de azúcar en la sangre, por lo que está recomendado para prevenir la diabetes tipo 2 y puede ser consumido por quien padezca la enfermedad."},
                {"text":"Por último, el cacahuate aporta una gran cantidad de vitaminas y minerales. Por ejemplo, contiene tanto vitamina E como una gran cantidad de vitaminas del grupo B, éstas ayudan a mejorar  la calidad de la piel, a cuidar el sistema nervioso y la memoria. Además, encontramos magnesio y potasio, elementos esenciales para la salud de los músculos, ideal para quien práctica deporte."},
                {"text":"Estos son algunos de los beneficios más importantes que podemos encontrar en los cacahuates, pero no son todos, ya que aún se siguen realizando estudios para descubrir qué otros nos aportan tanto para la prevención como el cuidado de las personas. "},
                {"text":"Por lo que, ¿qué tal si comienzas a incluir más cacahuates en tu dieta?"},
            ],
            "refs":[]            
        },
        "cacahuate-alimento-diabeticos": {
            "image": blog24,
            "title":["El cacahuate como alimento amortiguador para diabéticos"],
            "desc": "Cuando una persona es diagnosticada con diabetes inicia un cambio en su rutina y alimentación, todo ello con el fin de mejorar su calidad de vida ya que hay más de una preocupación cuando se vive con ese padecimiento",
            "pars":[
                {"text":"Cuando una persona es diagnosticada con diabetes inicia un cambio en su rutina y alimentación, todo ello con el fin de mejorar su calidad de vida ya que hay más de una preocupación cuando se vive con ese padecimiento. Por ejemplo, una recomendación que reciben los pacientes es modificar su dieta y limitar el consumo de carbohidratos para evitar el aumento de glucosa en la sangre; otra recomendación es aumentar el consumo de alimentos ricos en fibra. Pero surge la duda, ¿hay alimentos que pueden combinar esas características? La buena noticia es que sí. Estudios han demostrado que el consumo de cacahuates puede ser un aliado en la dieta diabética. Analizaremos sus principales ventajas: ", className:"blog-page-par-first"},
                {"text":"El contenido de proteína vegetal y grasas saludables son los dos componentes principales que permiten recomendar el consumo de cacahuates en la dieta de las personas diabéticas, pues permiten controlar el nivel de glucosa en la sangre, es decir, \"amortigua\" o evita los aumentos súbitos."},
                {"text":"Otro de los motivos por los que se recomienda comer cacahuates o crema de cacahuate es porque la cantidad de carbohidratos es  principalmente fibra. Además, se ha descubierto que ayuda a disminuir el apetito y las ganas de comer. Esto consigue que el páncreas tenga periodos de descanso y se disminuya su deterioro, ya que secreta hormonas que favorecen la saciedad. "},
                {"text":"Otro beneficio importante se puede ver en la cantidad de grasas saludables en el cuerpo. Esto se interpreta al medir el nivel de colesterol en la sangre. El colesterol LDL, que comúnmente llamamos “malo”, disminuye mientras que el colesterol HDL, al que llamamos “bueno”, se mantiene de forma saludable. Actualmente se estudia el efecto antioxidante que puede generar el consumo adecuado de cacahuate. Esto es importante tanto para pacientes con diabetes tipo 1 como tipo 2."},
                {"text":"Finalmente, incorporar cacahuates a la dieta puede ayudar a prevenir los niveles bajos de vitaminas y minerales, entre los que destaca el magnesio, un problema común entre pacientes que no han logrado controlar su enfermedad. Igualmente, contienen una  fuente natural de arginina, un aminoácido esencial para la formación de proteínas y que facilita la absorción de colesterol en el intestino."},
                {"text":"Recuerda que la cantidad recomendada de consumo es ⅓ de tazas de cacahuates tostados o dos cucharadas de crema de cacahuate al día."},
                {"text":["Conoce más sobre este rico alimento en ", <a className="blog-link" rel="noopener noreferrer" href="https://www.galdisa.com/products">https://www.galdisa.com/products</a>]},
            ],
            "refs":[]            
        },  
        "relacion-cacahuate-mexico": {
            "image": blog25,
            "title":["La relación del cacahuate y México "],
            "desc": "El cacahuate es una de las legumbres más importantes a nivel mundial, además de reunir varios nutrientes se considera la botana más conocida y consumida, pero su importancia no solo radica en el valor nutricional, también existe una relación con la agricultura, economía y gastronomía de nuestro país.",
            "pars":[
                {"text":"El cacahuate es una de las legumbres más importantes a nivel mundial, además de reunir varios nutrientes se considera la botana más conocida y consumida, pero su importancia no solo radica en el valor nutricional, también existe una relación con la agricultura, economía y gastronomía de nuestro país.", className:"blog-page-par-first"},
                {"text":"El cacahuate es originario del continente americano y se cultiva desde la época prehispánica. Existen registros de su domesticación y producción en el territorio que actualmente ocupan los estados de Puebla y Morelos. Después de la llegada de los españoles, estos se encargaron de llevarlo a Europa y África, lo que contribuyó a su distribución y consumo a nivel mundial."},
                {"text":"En México conviven varios tipos de cultivo; uno de ellos es el de temporal que no requiere infraestructura de riego. La ventaja de este sistema es que se utiliza en suelos de poca fertilidad, que reciben lluvias inconstantes y poco abundantes. Se sigue este modelo en Morelos, Guerrero y Oaxaca. Con este sistema se produce más de un tercio de la producción nacional de cacahuate."},
                {"text":"También encontramos el caso de Yucatán en donde la producción es menor y el consumo es esencialmente local. Una característica importante de la producción de este estado es que se siembra junto al maíz, calabaza o frijol blanco, lo que evita el desgaste del terreno de siembra. En ese estado se pueden cosechar poco más de 300 toneladas de cacahuate al año."},
                {"text":"Sin embargo, la mayor producción la encontramos en los estados de Chihuahua, Sinaloa, Chiapas y Puebla, pues en conjunto superan las 71 mil toneladas de producción anual que representan cerca del 70% de la producción nacional según la Secretaría de Agricultura. Aquí se realiza una rotación de cultivo para evitar el desgaste de las zonas agrícolas y se trabaja continuamente para mejorar la calidad del producto."},
                {"text":"Se calcula que se siembra cacahuate en más de 55 mil hectáreas y el área de cultivo aumenta, en promedio, poco más del 1.3% anualmente. Tan sólo en el año 2021 se calculó que la producción nacional superaría las 100 mil toneladas que equivalen a 1,261 millones de pesos. Además, podemos destacar que se cultivan variedades locales y que se han registrado tres variedades mejoradas y dos están en proceso de registro. "},
                {"text":"Por último, su consumo está relacionado directamente con la gastronomía nacional en recetas como moles, salsas, galletas, pan, dulces, ensaladas, etc., así como de las diferentes botanas que se comercializan. También encontramos que hay distintas industrias que producen aceites, harina, tinta, entre otros productos. Esto nos permite ver que en México el cacahuate tiene una gran importancia. "},
                {"text":"Es por eso que en Galdisa nos enorgullece formar parte de esta importante industria desde la siembra, su producción y hasta su distribución."},
            ],
            "refs":[]            
        },      
        "historia-crema-cacahuate":{
            "image": blog26,
            "title":["Historia de la crema de cacahuate"],
            "desc": "La crema de cacahuate es una de las formas más deliciosas y fáciles para integrar este superalimento en nuestra dieta diaria. Ya sea complementando alguna colación o como parte del desayuno.",
            "pars":[
                {"text":["La crema de cacahuate es una de las formas más deliciosas y fáciles para integrar este superalimento en nuestra dieta diaria. Ya sea complementando alguna colación o como parte del desayuno. En nuestro blog podrás encontrar muchas recetas saludables y deliciosas. En este nuevo artículo te platicaremos todo sobre su historia. ¿Ya la conoces? Aunque se tiene evidencia de que los incas tenían un alimento a base de cacahuates molidosiguiendo los registros históricos, fue en Estados Unidos en donde por primera vez se utilizó la crema de cacahuate. Al principio, el cacahuate no era un producto tan importante para los norteamericanos, pues únicamente se empleaba para la producción de aceites e incluso que gran parte de lo que se cosechaba se utilizaba para alimentar al ganado."], className:"blog-page-par-first"},
                {"text":"Fue hasta la Guerra Civil cuando se le comenzó a dar mayor importancia a este superalimento, debido a que la comunidad se percató de que esta pequeña semilla brindaba muchísimos beneficios a los soldados. Ambos bandos alimentaban a los combatientes con cacahuates. Después de eso, se industrializó tanto la siembra como la cosecha, por lo que la población de las ciudades pudo consumirlos con regularidad."},
                {"text":"Si bien a finales del siglo XIX encontramos productos con cacahuates molidos, el primer anuncio de la venta de crema de cacahuete al público fue en la Feria Mundial de San Luis, EEUU en 1904. Antes de dicho evento, únicamente se tienen apuntes de productos que usaban cacahuetes triturados como proteína para pacientes con dificultad para masticar alimentos sólidos."},
                {"text":"Finalmente, durante las dos Guerras Mundiales, los cacahuates enteros y la crema de cacahuate fueron parte esencial de la alimentación de los aliados. De hecho, históricamente, se relaciona la popularización del sándwich con crema de cacahuate por imágenes de las fuerzas armadas comiéndolos. Lo que sí podemos asegurar es que es innegable que a partir de la segunda mitad del siglo XX, este alimento se popularizó a nivel mundial."},
                {"text":"La mejor forma de preparar este alimento es triturando cacahuates enteros hasta conseguir una pasta uniforme. Mientras menos ingredientes añadidos contenga, se tendrá un alimento más puro, completo y delicioso."},
            ],
            "refs":[]
        },
        "helado-de-cacahuate":{
            "image": blog26,
            "title":["Helado de cacahuate"],
            "desc": "Si al iniciar la época de calor buscas opciones saludables, deliciosas y refrescantes, tenemos la receta ideal para ti",
            "pars":[
                {"text":["Si al iniciar la época de calor buscas opciones saludables, deliciosas y refrescantes, tenemos la receta ideal para ti. Como te hemos comentado en nuestro blog, la crema de cacahuate es una de las formas ideales para incluir en nuestra dieta esta importante leguminosa y todos sus beneficios para la salud. "], className:"blog-page-par-first"},
                {"text":"Es por ello que te traemos esta receta para que puedas disfrutar de la crema de cacahuate de una forma más fresca. Ya sea que prefieras las paletas heladas o los conos, estamos seguros que lo disfrutarás."},
                {"text":"¡A refrescarnos!"},
                {"title":"Ingredientes",
                    "list":[
                        "400 g de leche condensada",
                        "400 ml de leche entera",
                        "250 g de crema de cacahuate",
                        "70 g de cacahuates tostados",
                        "½ plátano (opcional)"
                    ]
                },
                {"title":"Preparación", 
                    "list":[
                        "1. Corta o tritura los cacahuates tostados al tamaño que prefieras, si hay niños pequeños se recomienda añadir cacahuete triturado.",
                        "2. En un recipiente añade ambas leches (en caso de que prefieras una versión vegetariana puedes sustituirlas por leche de coco y cualquier otra leche vegetal). Mezcla hasta incorporarlas.",
                        "3. Añade lentamente la crema de cacahuate e incorpora con una batidora a la mezcla de leches. Puedes añadir la mitad de los cacahuates si disfrutas esa combinación de texturas. Si te gusta el sabor más dulce puedes agregar una o dos cucharadas de azúcar o la mitad de un plátano maduro.",
                        "4. Puedes elegir recipientes para paletas o un bowl si prefieres servir en conos de galleta. Mete el recipiente al congelador por al menos 6 horas.",
                        "5. Al momento de servir,  calienta en el microondas un poco de crema de cacahuate, añade los trozos de cacahuates y cubre tu helado para darle aún más  sabor."
                    ]
                },
                {"text":"Esperamos que esta rica receta te encante. Recuerda que en Galdisa puedes encontrar más recetas deliciosas e información sobre los cacahuates y sus múltiples beneficios. "},
                {"text":"¡Que lo disfrutes!"}
            ],
            "refs":[]
        },
        "wrap-pollo-cacahuate":{
            "image": blog28,
            "title":["Wrap de pollo con cacahuate"],
            "desc": "Si disfrutas de probar alimentos frescos y nutritivos, te traemos una deliciosa receta de nuestros amigos: Ponle energía a tu día.",
            "pars":[
                {"text":["Si disfrutas de probar alimentos frescos y nutritivos, te traemos una deliciosa receta de nuestros amigos: Ponle energía a tu día. Ya sea para un día caluroso o para un picnic, este wrap te encantará. Además, es ideal para cualquier dieta ya que incluye proteínas, verduras y una deliciosa salsa cuyo ingrediente estelar es el cacahuate.  Recuerda que incluirlo en tu dieta ayuda a mejorar la digestión y a aumentar la saciedad. ¡Esperamos que lo disfrutes!"], className:"blog-page-par-first"},
                {"title":"Ingredientes",
                    "list":[
                        "2 tazas de coles cortadas",
                        "1 taza de zanahoria rallada",
                        "½ pechuga de pollo frita cortada en tiras de pollo",
                        "½ taza de cacahuates",
                        "¼ de taza de miel",
                        "¼ de taza de aceite de oliva",
                        "¼ de taza de crema de cacahuate",
                        "6 tortillas para burrito",
                        "3 cucharadas de vinagre de arroz ",
                        "3 cucharadas de cilantro fresco picado",
                        "2 chiles serranos picados",
                        "1 diente de ajo picado",
                        "Sal y pimienta"
                    ]
                },
                {"title":"Preparación", 
                    "list":[
                        "1. Para preparar la salsa de cacahuate, en un bowl vierte la crema de cacahuate, aceite de oliva, miel, vinagre, chiles serranos, ajo y mezcla hasta integrar todos los ingredientes. Agrega la sal y pimienta antes de terminar.",
                        "2. En un segundo bowl mezcla el mix de coles, zanahoria, cacahuates, cilantro y una o dos cucharadas de la salsa que ya está preparada. ",
                        "3. Para servir toma una de las tortillas para burrito, coloca 2 o 3 cucharadas del mix de verduras, una cama de tiras de pechuga, dobla la tortilla hasta cerrar el wrap.",
                        "4. Corta el wrap a la mitad. Puedes mojar la punta en la salsa para aumentar su sabor o bañarlo directamente.",
                    ]
                },
                {"text":"Esperamos que disfrutes de esta deliciosa receta. Recuerda que en nuestro blog puedes encontrar mucho contenido y recetas fáciles de preparar en casa al igual que información relevante  sobre los cacahuates y otros de nuestros productos."},
            ],
            "refs":[]
        },
        "mitos-sobre-cacahuate":{
            "image": blog29,
            "title":["4 mitos sobre los cacahuates"],
            "desc": "En diversas ocasiones te hemos compartido las increíbles propiedades de los cacahuates y por qué deberías incluirlos en tu dieta",
            "pars":[
                {"text":["En diversas ocasiones te hemos compartido las increíbles propiedades de los cacahuates y por qué deberías incluirlos en tu dieta, pero aún es común escuchar a personas que desaconsejan su consumo, principalmente por desinformación o por confundir términos. Es por eso que te compartiremos algunos mitos y datos reales que te ayudarán a comprender mejor las propiedades de este superalimento."], className:"blog-page-par-first"},
                {"text":"Recuerda que hay una cantidad recomendada para que aproveches todos sus beneficios: aproximadamente 2 cucharadas al día o 30 piezas completas. Si combinas esta cantidad con una dieta balanceada, tu alimentación se verá beneficiada."},
                {"title":"Mito 1: No son un alimento completo", "text":"Debido a que la mayoría come cacahuates como botana, se puede asociar con dulces o comida poco nutritiva, pero no hay nada más alejado de la realidad: este alimento destaca por su alto contenido en proteínas, cerca de un 27%, y por su alto contenido en fibra que combinados dan energía y facilitan la digestión. Por lo que sus componentes lo hacen ideal para la colación o el desayuno."},
                {"title":"Mito 2: Aumenta el peso", "text":"Otro mito común es el de pensar que los cacahuates tienen un alto contenido de calorías y lo relacionan con el aumento de peso. Si bien todo alimento en exceso puede generar problemas para la salud, los cacahuates destacan por contener diversos tipos de grasas sanas sumado a su alto índice de vitaminas y minerales. Es por eso que se recomienda a personas vegetarianas o deportistas, pues aportan energía y ayudan a las defensas del cuerpo; también se ha descubierto que el consumo de cacahuates tiene un impacto favorable en la prevención de enfermedades del corazón."},
                {"title":"Mito 3: Sólo los cacahuates naturales son recomendables", "text":"Si bien es cierto que consumir cacahuates con el menor proceso posible es lo más recomendable, la crema de cacahuate es un excelente aliado para incluir a esta importante leguminosa en nuestra dieta. Se puede preparar de forma natural y sin ingredientes añadidos lo que permite que se conserven la mayoría de los nutrientes. Este aliado te dará los mismos beneficios que consumirlos sin procesar. "},
                {"title":"Mito 4: Sólo son aptos para los jóvenes o adultos", "text":"Relacionado con el tema anterior, muchos asocian este alimento con la niñez o la juventud, la realidad es que cualquier persona puede incorporarlos a su dieta. Incluso las personas con diabetes debido a sus múltiples ventajas, siempre siguiendo las recomendaciones de un especialista de la salud. Ya sea que se busque un alimento completo o que aporte los beneficios antes mencionados. "},
                {"text":"Esperamos que al derribar estos cuatro mitos, te animes a incluir a los cacahuates en tu dieta. Recuerda que en nuestro blog puedes encontrar recetas y datos importantes sobre este superalimento. "},
            ],
            "refs":[]
        },
        "historia-cacahuates-japoneses":{
            "image": blog30,
            "title":["Historia de los cacahuates japoneses Realmente ¿de dónde vienen?"],
            "desc": "¿Sabías que esta variedad de cacahuates en realidad no provienen de un país oriental?",
            "pars":[
                {"text":["Seguramente has disfrutado muchas veces de los cacahuates como dulce o botana. Ya sean salados, con caramelo, enchilados, con cáscara o los famosos cacahuates japoneses; pero ¿sabías que esta variedad de cacahuates en realidad no provienen de un país oriental? La historia de cómo surgió es un ejemplo de la versatilidad y adaptabilidad de esta legumbre, junto con la relación gastronómica de dos países."], className:"blog-page-par-first"},
                {"text":"Esta deliciosa botana tiene su origen en una receta llamada “mamekashi” que se prepara con semillas cubiertas por una capa de harina de arroz, condimentada con diferentes ingredientes. La preparación se originó en China y se popularizó en Japón."},
                {"text":"¿Quién fue el creador de esta deliciosa botana? Su nombre era Yoshihei Nakatani Moriguchi, un japonés que emigró a México para trabajar en un almacén llamado El Nuevo Japón, sin embargo, tuvo que cerrar debido a que el dueño fue deportado a su país."},
                {"text":"Yoshihei había formado una familia con una mujer llamada Emma que vivía en el barrio de La Merced. La pérdida de sus empleos los impulsó a crear un negocio de muéganos para sacar adelante a sus hijos; lamentablemente, no eran los únicos vendedores de la zona, por lo que comenzaron a comercializar una fritura de trigo, intentando ganar más clientes."},
                {"text":"Al poco tiempo se dieron cuenta de que el negocio necesitaba una receta diferente. Eso fue lo que motivó a Yoshihei para probar con el mamekashi, pero uno de los ingredientes más importantes no era fácil de conseguir: harina de arroz. Fue por eso que la sustituyó por harina de trigo sazonada con salsa de soya."},
                {"text":"Estos cambios impulsaron el negocio que se encontraba en la vecindad de la calle Carretones. Muchas personas hacían fila para comprar esta rica botana y no tardaron mucho en bautizarlos como “cacahuates japoneses”. El éxito de la botana llevó al matrimonio a prepararla en casa y salir a vender su producto envuelto en bolsas de celofán. Poco tiempo después, las dulcerías del mercado de La Merced comenzaron a pedirles grandes cantidades para revender."},
                {"text":"Debido a que la familia no patentó la receta, muchos comerciantes empezaron a realizar su producción de forma independiente. Es por eso que en los años 80 se dio un aumento en el consumo y venta del cacahuate japonés; curiosamente, a nivel mundial suele nombrarse como cacahuate mexicano ya que no se le relaciona con el país nipón."},
                {"text":"Así que la próxima vez que disfrutes de esta deliciosa botana no olvides agradecer a su creador que logró la fusión de dos culturas por su gastronomía. Pero al fin de cuentas, se puede decir que los cacahuates japoneses fueron creados en tierra mexicana. "},
            ],
            "refs":[]
        },
        "cacahuate-mexicano-mundo":{
            "image": blog31,
            "title":["El papel del cacahuate mexicano en el mundo"],
            "desc": "Es innegable que el cacahuate ocupa un lugar muy importante en la gastronomía mundial",
            "pars":[
                {"text":["Es innegable que el cacahuate ocupa un lugar muy importante en la gastronomía mundial. Si bien lo asociamos mayormente con los snacks, su importancia va más allá pues esta deliciosa leguminosa se consume en un sin fin de variedades y platillos. Entender su importancia y conocer en dónde se ubica México al hablar de cacahuates nos permitirá saber porqué es tan importante este fruto seco para el país."], className:"blog-page-par-first"},
                {"text":"En el mundo se producen cerca de 25.7 millones de toneladas de cacahuates sin cáscara y, en promedio, se exporta cerca del 11% ya que cada región cuenta con diferentes variedades de este producto. Esto propicia el intercambio comercial y mejora las relaciones económicas. "},
                {"text":"Desde hace algunos años, el mayor productor de cacahuate es China con cerca del 39% de la producción mundial; seguido de la India y Nigeria con el 20% y Estados Unidos con el 5%. Si bien, la producción mexicana no significa un número grande, es destacable que se ubica entre los mayores productores y exportadores del mundo."},
                {"text":"México producía cerca de 60 mil toneladas de cacahuates anualmente hace una década y se exportaban 11 mil toneladas mientras que se importaban 111 mil toneladas, aproximadamente. Esto cambió en los últimos años debido a la implementación de mejoras en la siembra y en la recolección."},
                {"text":"Actualmente, México supera la producción de las 100 mil toneladas anuales. Si bien puede parecer que la diferencia es muy grande, debemos entender que México es un país que cada año aumenta su producción y mejora la calidad de sus cacahuates. Esto propicia que los productores obtengan un mayor ingreso y la industria cacahuatera crezca a la par de la demanda de cacahuate mexicano."},
                {"text":"Otra buena noticia es que en el país se cultivan variedades locales de las cuatro botánicas presentes. Por lo que se pueden encontrar tanto cacahuates con características diferentes como aquellos que cumplen con normas internacionales. Además, hoy día se han encontrado tres variedades mejoradas y dos están en proceso de registro."},
                {"text":"Aunque el cacahuate transformado en botanas tiene un lugar importante, el de origen mexicano se utiliza para producir mantequilla, harina y aceite. Estos productos cada año son más solicitados por sus beneficios y por usuarios que buscan opciones más saludables. Es por eso que también se importa una gran cantidad de cacahuates de todo el mundo. La obtención de productos procesados también impacta satisfactoriamente a la industria."},
                {"text":"Esperamos que este panorama de la industria te invite a saber más y a disfrutar orgullosamente del cacahuate de nuestro país. Recuerda que Galdisa se ocupa de los cacahuates desde su siembra hasta que llegan a las manos de sus clientes y consumidores."},
            ],
            "refs":[]
        },
        "principales-consumidores-cacahuate":{
            "image": blog32,
            "title":["Principales países consumidores de cacahuate"],
            "desc": "El cacahuate es muy popular prácticamente en todo el mundo",
            "pars":[
                {"text":["El cacahuete, a veces conocido como maní, se considera el décimo tercer cultivo alimenticio más importante del mundo y la cuarta fuente de aceite comestible. También es una importante fuente de proteínas vegetales. Aunque son de pequeño tamaño, los cacahuetes desempeñan un papel nutricional vital e importante y tienen increíbles beneficios para la salud."], className:"blog-page-par-first"},
                {"text":"El cacahuate es muy popular prácticamente en todo el mundo. Su consumo es muy popular en países como China, Estados Unidos e India, destacan también países como Israel, Arabia Saudita y Malasia con los mejores rendimientos productivos 6.85, 4.00, 3.50 toneladas por hectárea, respectivamente y por ello son los países donde más se consume y produce cacahuate en el mundo."},
                {"title":"Top 10 de paises con mayor consumo de cacahuate", 
                "text":<table style={{width:'80%'}}>
                    <tr>
                        <th style={{backgroundColor:"green", color:"white"}}>Ranking</th>
                        <th style={{backgroundColor:"green", color:"white"}}>País</th>
                        <th style={{backgroundColor:"green", color:"white"}}>Consumo anual en toneladas</th>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>China</td>
                        <td>17,371,242</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>India</td>
                        <td>5,627,940</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Nigeria</td>
                        <td>3,000,025</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>Estados Unidos</td>
                        <td>2,313,684</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>Indonesia</td>
                        <td>1,310,520</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>Vietnam</td>
                        <td>669,618</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>Brasil</td>
                        <td>329,803</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>México</td>
                        <td>203,355</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>Sudáfrica</td>
                        <td>136,168</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>Canadá</td>
                        <td>95,797</td>
                    </tr>
                </table>},
                {"text":"En México, el cacahuate se siembra principalmente en alrededor de 62,000 hectáreas, con una producción promedio anual de 60,000 toneladas, y con un valor de 653 millones de pesos. Los estados de Sinaloa, Puebla, Chiapas, Oaxaca, Chihuahua y Guerrero, en conjunto acumulan 84% de la superficie nacional dedicada a este cultivo. El consumo de cacahuate en México es de alrededor de 160,000 toneladas anualmente: 145,000 toneladas se destinan al consumo humano; 6,000 toneladas, al procesamiento industrial; 8,000 toneladas, a otros usos y 1,000 toneladas, a semilla para siembra."},
                {"text":["Si eres de la industria o eres distribuidor de cacahuate te invitamos a  conocer los productos de Galdisa. Para más información consulta:",<a className="blog-link" rel="noopener noreferrer" href="https://www.galdisa.com/">https://www.galdisa.com</a>]},
            ],
            "refs":[]
        },
        "datos-curiosos-jamaica":{
            "image": blog33,
            "title":["Datos curiosos de la jamaica"],
            "desc": "Alguna vez te has preguntado, ¿cómo una flor puede proveernos de una de las bebidas más refrescantes y tradicionales?",
            "pars":[
                {"text":["Alguna vez te has preguntado, ¿cómo una flor puede proveernos de una de las bebidas más refrescantes y tradicionales?"], className:"blog-page-par-first"},
                {"text":"La jamaica es una flor con cinco pétalos, de sabor ácido y que, una vez hervida, desprende un color rojo intenso. En México se utiliza principalmente para elaborar infusiones."},
                {"text":"Desde la antigüedad, diversas culturas como los incas y egipcios la han empleado por sus propiedades benéficas para la salud, lo cual ha sido corroborado por la ciencia en los últimos años."},
                {"text":"Si al igual que nosotros te encanta el agua de la flor de jamaica, no te pierdas estos datos curiosos:"},
                {"list": [
                    "1. Su origen es Africano.",
                    "2. Contiene unos pigmentos denominados antocianinas, que por su actividad antioxidante, ayudan a mejorar el organismo contra la oxidación celular, además, tienen efectos anticancerígenos.",
                    "3. Ayuda en la limpieza de órganos como el hígado y los riñones.",
                    "4. Investigaciones científicas han comprobado que la flor de Jamaica tiene un contenido significativo de las vitaminas A y C, una gran cantidad de minerales, ácido cítrico y málico, entre muchos otros componentes.",
                    "5. El tallo de la planta de jamaica es tan resistente que en algunas regiones del mundo sustituyen al yute con las fibras del tallo, esto para crear artesanías.",
                    "6. Las infusiones de la flor ayudan a disminuir considerablemente las inflamaciones de varias partes de nuestro cuerpo por lo cual es muy recomendada sobre todo para las relacionadas con el ciclo menstrual femenino.",
                    "7. Se utiliza para combatir desde problemas de la piel como el acné hasta anemia e infecciones respiratorias."
                ]},
                {"text":"¿Verdad que es asombrosa? Además, es deliciosa. Te invitamos a conocer éste y más productos que puedes adquirir con Galdisa."},
            ],
            "refs":[]
        },
        "rosca-reyes-almendras":{
            "image": blog34,
            "title":["Rosca de Reyes con almendras, nueces y arándanos"],
            "desc": "En este blog te compartiremos una deliciosa receta para que sorprendas a tus invitados y deleiten su paladar",
            "pars":[
                {"text":["¿Estamos de acuerdo en que uno de los días más mágicos del inicio de año es el 6 de enero? La llegada de los Reyes Magos es la ocasión perfecta para reunir de nuevo a la familia, contar sobre nuestros regalos, vacaciones y sobre todo disfrutar. En este blog te compartiremos una deliciosa receta para que sorprendas a tus invitados y deleiten su paladar con la combinación perfecta de sabor y dulzura."], className:"blog-page-par-first"},
                {"text":"Aquí la receta. ¡Esperamos que la disfrutes!"},
                {"title":"Ingredientes",
                "list":[
                    "450 g de harina",
                    "80 g mantequilla",
                    "150 g de azúcar",
                    "2 huevos",
                    "150 ml de agua templada",
                    "35 ml de leche",
                    "2 cáscaras de naranja en ralladura",
                    "30 g de levadura",
                    "2 cucharaditas de agua de azahar",
                    "Sal",
                    "Arándanos deshidratados",
                    "Almendras",
                    "Nueces",
                    "Azúcar granulada"
                ]},
                {"title":"Preparación:",
                "list":[
                    "Agrega en un bowl grande 400 g de harina y en el centro realiza  un pequeño hueco para agregar el azúcar",
                    "Disuelve la levadura en el agua templada y viértela con la leche, la ralladura de naranja, el agua de azahar y la mantequilla. Mézclalo todo.",
                    "Añade uno de los huevos. Después una pizca de sal y amasa.",
                    "Una vez mezclados todos los ingredientes, coloca la masa sobre una superficie en la que previamente hayas esparcido harina. Sigue amasando hasta que consigas que te quede una masa elástica y no pegajosa. Con esa masa forma una bola grande.",
                    "Cuando la tengas, haz un agujero en el centro. Estira la masa poco a poco hasta conseguir la forma de la rosca.",
                    "Precalienta el horno a 200º.",
                    "Coloca la rosca sobre la bandeja del horno y déjala aproximadamente unos 30 min.",
                    "Bate el otro huevo y con éste, pinta la rosca poco a poco.",
                    "Decora tu rosca con las almendras, nueces, arándanos y el azúcar granulada.",
                    ""
                ]},
                {"text":"Esperamos que te deleites con esta deliciosa receta."},
                {"text":"Recuerda que en nuestro blog puedes encontrar mucho contenido y recetas fáciles de preparar en casa, al igual que información relevante sobre cacahuate y otros de nuestros productos."},
                {"text":"¡Felices fiestas y Día de Reyes Magos!"}
            ],
            "refs":[]
        },
        "maiz-economia-nutricion":{
            "image": blog35,
            "title":["El maíz, su impacto económico y valor nutricional."],
            "desc": "Fue cultivado por primera vez por los pueblos originarios del Eje Neovolcánico Mexicano (Tehuacán) hace aproximadamente 10.000 años",
            "pars":[
                {"text":["El maíz es el tercer cereal más cultivado en el mundo, y después del trigo y el arroz es la base de la alimentación de muchos países. Fue cultivado por primera vez por los pueblos originarios del Eje Neovolcánico Mexicano (Tehuacán) hace aproximadamente 10.000 años. Los olmecas y los mayas también lo utilizaban."], className:"blog-page-par-first"},
                {"text":"México se ha convertido en el principal importador de maíz en el mundo, ya que del total del consumo nacional que es de casi 45 millones de toneladas al año, en el país se producen entre 27 y 28 millones de toneladas, el resto se importa a países como EEUU y Argentina principalmente."},
                {
                    "title":"Impacto económico:", 
                    "text": "Para México, el maíz es el cultivo de mayor importancia, ya que constituye una de las actividades económicas más importantes del sector rural, no sólo en términos de uso de suelo, sino también en el empleo y en el suministro de alimentos a la población rural y urbana de bajos recursos. A nivel nacional se identifican aproximadamente 2 millones de productores dedicados al cultivo de maíz y el 85% de ellos lleva a cabo su labor en predios cuya extensión es menor o igual a 5 hectáreas."
                },
                {
                    "text":"En México se consume más maíz del que se produce, el déficit estimado es de 43% en la producción, esto a pesar de ser el grano más importante para la alimentación de los mexicanos. Con la implementación del Tratado de Libre Comercio de América del Norte (TLCAN) la dependencia de maíz en México creció del 7% en 1990 al 34% en recientes años."
                },
                {
                    "text":"El maíz, como todos los alimentos, debe consumirse en cantidades adecuadas y como parte de una dieta equilibrada. Este cereal es una opción ideal para los vegetarianos, no contiene gluten y tiene otras propiedades y beneficios para la salud:",
                    "list": [
                        "Antioxidantes: El maíz es rico en antioxidantes, los cuales ayudan a combatir a los radicales libres y previenen el envejecimiento celular.",
                        "Saciante: Rico en fibra e hidratos de carbono por lo que ayuda a controlar el apetito.",
                        "Bueno para el cerebro: Rico en ácido fólico y otras vitaminas, el maíz es un aliado de diversas funciones cerebrales.",
                        "Cuida la salud cardiovascular: La vitamina B1 que contiene el maíz disminuye la homocisteína y, por lo tanto, reduce también el riesgo a sufrir un ataque cardiovascular.",
                        "Indicado para las mujeres embarazadas: Rico en ácido fólico, es muy recomendable si tienes planes para embarazarte o incluso si te encuentras  en la etapa de lactancia.",
                        "Mantiene los huesos fuertes: Gracias a la presencia de calcio entre sus nutrientes."
                    ],

                },
                {
                    "text":"Las variedades de maíz rojo, naranja y púrpura contienen potentes antioxidantes conocidos como carotenoides. Los carotenoides son populares por su contribución a la lucha contra los radicales libres, que pueden ser responsables del daño celular."
                },
                {"title":"Valor nutricional de 100 gramos de maíz:",
                "list":[
                    "Calorías: 86 g",
                    "Carbohidratos: 19 g",
                    "Grasas: 1,2 g",
                    "Proteínas: 3,2 g",
                    "Ácido fólico (vitamina B9): 46 μg",
                    "Potasio: 270 mg",
                ]},
                {"text":"El maíz es un alimento muy completo que contiene muchas vitaminas y minerales que favorecen nuestro metabolismo y a su vez es parte fundamental para la alimentación de los mexicanos. Además de que tiene gran valor cultural, social y económico para nuestro país.  Actualmente contamos con un total de 59 variedades de maíz criollo, es decir, variedades nativas y el estado de Sinaloa es el líder en su producción aportando más de 5 millones de toneladas anuales."},
                {"text":["Puedes adquirirlo en: ",<a className='blog-link' rel="noopener noreferrer" target="_blank" href="/">https://www.galdisa.com/</a>,". Esperamos que hayas disfrutado de este blog y te animes a saber más y disfrutar orgullosamente del maíz que nos brindan las tierras de nuestro país."]},
            ],
            "refs":[]
        },
        "ventajas-maquilar-productos":{
            "image": blog36,
            "title":["Ventajas maquilar tus productos con una empresa especializada que te ayudarán al crecimiento y rentabilidad de tu negocio"],
            "desc": "",
            "pars":[
                {"text":["Actualmente, para lograr operar y triunfar en los mercados altamente competitivos (como lo son México y Estados Unidos), es necesario reducir costos y aumentar la productividad. Una de las  alternativas más efectivas para lograrlo es contratar un servicio de maquila y aquí te dejaremos algunas ventajas y tips que sin duda te ayudarán en este proceso."], className:"blog-page-par-first"},
                {"text":"Desde los años ochenta, la industria de la maquila en México presentó gran evolución y un acelerado crecimiento. Ésta industria se convirtió en uno de los principales medios de inserción internacional de México y además se utilizó como medio motor para modernizar la producción."},
                {
                    "text": "La cantidad de empresas maquiladoras en México ha permanecido en constante crecimiento, pues al contar con costos de mano de obra bastante competitivos, las industrias internacionales han migrado sus producciones a nuestro país. En la industria alimenticia, las empresas maquiladoras se encargan de participar en la selección, preparación, empaque, enlatado y distribución de los productos."
                },
                {
                    "text":"Algunas ventajas a destacar  para contratar un maquilador son:"
                },
                {
                    "list": [
                        "No necesitas invertir en maquinaria especializada, permitiéndote ahorrar grandes cantidades de recursos.",
                        "Las empresas maquiladoras, generalmente cuentan con la experiencia necesaria en el tratamiento de los productos, así como certificaciones que avalan todos sus procesos de seguridad e inocuidad. En especial cuando se trata de productos alimenticios.",
                        "Los permisos y licencias de elaboración de algunos tipos de productos (alimentos, bebidas, medicinas…) ya las tiene el maquilador por lo que no hay que tramitar estos permisos.",
                    ],

                },
                {
                    "title":"5 Aspectos importantes a considerar antes de contratar una maquiladora.",
                    "list":[
                        "1. Busca a un fabricante que invierta continuamente en nuevos equipos y tecnología. En otras palabras, busca a un fabricante que tenga una buena salud financiera y esté dispuesto a mejorar sus equipos para brindarte un servicio eficiente y de la más alta calidad.",
                        "2. Antes de firmar, asegúrate de que el contrato contenga cláusulas específicas de propiedad intelectual. Un buen maquillador tendrá procedimientos instaurados y velará  para evitar el robo o la fuga de cualquier secreto industrial.",
                        "3. Asegúrate de que el acuerdo de suministro incluya las necesidades y requisitos de tu negocio ampliamente detallados, así como los del fabricante. Asegúrate de colocar cláusulas de incumplimiento.",
                        "4. Busca una empresa seria que tenga una historia, valores y personal altamente comprometido que la respalde."
                    ]
                },
                {"text":"Considerando la globalización y la especialización que cada vez se da más en la industria alimenticia, es muy probable que la maquila siga siendo una participante activa e importante en los procesos productivos."},
                {"text":["Maquila con Galdisa, contamos con experiencia, conocimiento y calidad para tus productos. Visita nuestro sitio web y conoce más sobre nosotros.",<a className='blog-link' rel="noopener noreferrer" target="_blank" href="/">https://www.galdisa.com/</a>]},
            ],
            "refs":[]
        },
        "ventajas-granel-emprendimiento":{
            "image": blog37,
            "title":["Ventajas de comprar a granel para tu emprendimiento."],
            "desc": "Comprar a granel con Galdisa te garantiza el mejor sabor y calidad en todos y cada uno de nuestros productos",
            "pars":[
                {"text":["Galdisa es una empresa internacional del sector agroalimentario que se dedica a la siembra, fabricación y distribución de productos como ingredientes, botanas, derivados del cacahuate, granos y semillas para compañías/empresarios que producen y comercializan alimentos. Comprar a granel con Galdisa te garantiza el mejor sabor y calidad en todos y cada uno de nuestros productos."], className:"blog-page-par-first"},
                {"title":"4 ventajas principales de comprar granel para tu emprendimiento ",
                    "list": [
                        [<span className='texto-bold texto-green'>1. Las compras a granel nos permiten reducir muchísimos envases y empaques:</span>," El 39.9% del plástico que se genera en el mundo va destinado a la fabricación de envases. Por ello, comprar a granel ayuda al ahorro de los recursos."],
                        [<span className='texto-bold texto-green'>2. Abasto garantizado:</span>," Una ventaja sólida es el hecho de que al comprar tus productos a granel con Galdisa siempre tendrás abasto y productos en tu inventario con la seguridad de que son productos frescos, de calidad y duraderos."],
                        [<span className='texto-bold texto-green'>3. Ahorras económicamente:</span>," Al comprar a mayor volumen , se obtiene un beneficio económico que te permitirá dar un mejor precio a tus clientes, además cuando compras a granel, únicamente compras el producto sin marca ni empaques."],
                    ],
                },
                {"text":"Actualmente las compras a granel han cobrado relevancia entre las familias mexicanas derivado de la situación económica que se vive en donde el aumento de precios en los productos de consumo masivo ha sido mayor que la inflación. El 63 % de las personas declaran haber adquirido productos a granel principalmente por el precio. Lo anterior pone una gran oportunidad sobre la mesa , pues tu negocio puede adquirir los productos de Galdisa para revenderlos en las tiendas de abarrotes de tu comunidad o incluso para generar productos con tu marca que sean más competitivos que los de la competencia en tema de precio/calidad."},
                {
                    "title": "¿Cómo vender a granel?",
                    "list":[
                        [<span className='texto-bold texto-green'>1. Encuentra a tus consumidores :</span>," Busca  dentro de tu comunidad a quién podrías vender el producto, genera acuerdos y brinda un excelente servicio. Esto te permitirá siempre tener una alta rotación de tu inventario."],
                        [<span className='texto-bold texto-green'>2. Ofrece una ficha técnica:</span>," El consumidor debe tener a la mano y en todo momento la información referente a ellos, como si tuviera la etiqueta de un producto envasado."],
                        [<span className='texto-bold texto-green'>3. Ofrece recomendaciones al cliente:</span>," Aconseja a tus clientes sobre los productos que puede comprar y saca productos especiales de temporada. Intenta hacer diferentes mezclas de semillas."],
                        [<span className='texto-bold texto-green'>4. Abre tu tienda en línea :</span>," El hecho de que ahora se puedan comprar productos a granel por Internet supone un incentivo para todos aquellos que buscan productos a precios accesibles sin tener que desplazarse de casa."],
                    ]
                },
                {"text":"Considerando la globalización y la especialización que cada vez se da más en la industria alimenticia, es muy probable que la maquila siga siendo una participante activa e importante en los procesos productivos."},
                {"text":["Compra tus productos a granel con Galdisa, contamos con experiencia, conocimiento y calidad para los productos. Hoy es un buen día para empezar tu negocio. Visita nuestro sitio web y conoce más sobre nosotros. ",<a className='blog-link' rel="noopener noreferrer" target="_blank" href="/">https://www.galdisa.com/</a>]},
            ],
            "refs":[]
        },                        
        "cacahuate-alimento-futuro":{
            "image": blog38,
            "title":["Los Cacahuates como el alimento del futuro."],
            "desc": "",
            "pars":[
                {"text":["Se prevé que la población del planeta alcanzará los nueve mil millones de habitantes en 2050. Por lo que la incorporación de prácticas agrícolas más eficientes y alimentos de origen sostenible es cada día más vital. Tenemos que comenzar a pensar sobre las necesidades de una población en crecimiento, cómo podemos mejorar la nutrición a través de prácticas de reproducción avanzadas, y cómo podemos evitar que los alimentos se desperdicien. Es justo ahí en dónde entra nuestro mejor aliado para la alimentación del futuro: El cacahuate."], className:"blog-page-par-first"},
                {"text":"Esta semilla es uno de los alimentos más populares del mundo, fácil de encontrar en Asia, África, Sudamérica y en muchos otros territorios. También es uno de los alimentos más ricos en nutrientes, ya que es alto en  proteínas (7 gramos por onza) y es una excelente fuente de fibra, grasas y más de 30 vitaminas y minerales."},
                {"text":"Nuestro organismo necesita las proteínas de los alimentos que ingerimos para fortalecer y mantener los huesos, los músculos y la piel,  éstas son esenciales para el crecimiento y reparación de los tejidos. Si se agregarán más cacahuates y leguminosas a las dietas de las personas de países subdesarrollados podríamos mejorar la alimentación y combatir la hambruna y desnutrición desde otro ángulo."},
                {"text":"Un mapa de la composición genética del cacahuate podría conducir a la producción de uno más perfecto, con mayor potencial de crecimiento y mejor perfil nutricional para alimentar a la creciente población mundial."},
                {"text":"Desde el punto de vista de la sustentabilidad, los cacahuates también son un excelente aliado ya que consumen mucha menos agua que otros frutos secos. Tienen una huella hídrica reducida debido a sus regiones de cultivo específicas (más de la mitad de los campos de cacahuetes dependen sólo del agua de lluvia), su pequeña estructura y su crecimiento subterráneo."},
                {"text":"Los cacahuates tienen un impacto ambiental mucho menor que otros cultivos, como la carne de res y la soja. Esto se debe a que su producción requiere de menos fertilizantes, lo que a su vez ayuda a mejorar la calidad del suelo."},
                {"text":"Se espera que los cultivadores de produzcan más en la misma superficie de tierra, creando así eficiencia y sostenibilidad para las próximas décadas. Gracias a un conocimiento claro de su ADN, los científicos creen que los cacahuates del mañana podrán ser aún más nutritivos y más resistentes a las sequías y a las condiciones climáticas extremas."},
                {"text":["En GALDISA puedes encontrar este alimento en diferentes presentaciones desde crema de cacahuate, cacahuate blanqueado, triturado, con chile y más. Conoce todo sobre nosotros en: ",<a className='blog-link' rel="noopener noreferrer" target="_blank" href="/">https://www.galdisa.com/</a>]},
            ],
            "refs":[{
                "text":"FDA. Guía de alimentación saludable:",
                "link":"https://www.fao.org/newsroom/detail/un-report-global-hunger-SOFI-2022-FAO/es"
            },
            {
                "text":"OMS. Alimentación saludable",
                "link":"https://www.who.int/es/news-room/fact-sheets/detail/healthy-diet"
            },
            {
                "text":"Estudio sobre el impacto ambiental de los cultivos de cacahuates:",
                "link":"https://www.sciencedirect.com/science/article/pii/S030691921830174X"
            },
            {
                "text":"FAO. Cultivo de cacahuates sostenibles:",
                "link":"http://www.fao.org/3/y5032s/y5032s05.htm"
            }
            ]
        },        
    },
    en:{
        "beneficios-cacahuate":{
            "image": blog1,
            "title":"Peanut’s main benefits",
            "desc":"Peanuts are plants that form part of the legume family",
            "pars":[
                {"text":"Peanuts are plants that form part of the legume family, as chickpeas, lentils, beans and peas. These plants are native from South America but were introduced to Mexico during the pre-Hispanic period.  ", className:"blog-page-par-first"},
                {"text":"The name “peanut” comes from the Nahuatl word tlacahuatl, composed of the words tlalli, earth, and cacahuatl, cocoa, which together make up the word, cocoa from the earth."},
                {"text":"The peanut plant is a small bush between 10 to 30 inches high. The interesting thing is that its fruits grow below the ground, between the roots, in contrast to nuts and almonds that grow on trees. The seeds of this legume have a hard, rough brown coat, within which 1 to 4 seeds can be found. The seeds are 0.19-0.39 inches in diameter and are usually brown in color. "},
                {"text":"This is an annual plant that is sown in spring and harvested in late fall. Its production is very important for Mexico. In 2018, 91 thousand tons were cultivated in this country, which represented $1.1 billion Mexican pesos."},
                {"text":["Peanuts have two classifications: botanically they are grouped within legumes, but, for culinary, research and nutritional purposes, peanuts are considered nuts.  As a group, these seeds provide the best source of concentrated proteins in the plant kingdom. While their physical structure and nutritional benefits are more similar to those of other legumes, the use of peanuts in diets and cooking is similar to the ones used for ", <a className="blog-link" href="http://www.goldfritz.com" target="_blank" rel="noreferrer noopener">nuts.</a>,"."]},
                {"text":"Health benefits of peanuts: ", "list":[
                    "Managing low levels of cholesterol and triglycerides",
                    "They contain bioactive compounds that help prevent the growth and spread of cancer cells",
                    "They are rich in resveratrol, a powerful antioxidant that protects against heart disease",
                    "They contain P-coumaric acid, known for its protective properties against cell damage",
                    "They are an excellent source of niacin, an ingredient that in good quantities can reduce the risk of Alzheimer's disease by up to 70%"
                ]},
                {"text":"These are just some of the benefits that peanuts offer, without a doubt, many people could consider them to be miraculous due to their multiple properties. Include them in your daily diet and enjoy their delicious taste."},
                {"text":["At Galdisa, we are experts in the process of growing peanuts in each stage of their process, from the plating to the harvest. That’s why we have the authority to recommend ", <a className="blog-link" href="/products" target="_blank" rel="noopener noreferrer" >peanuts</a>, " as the best option."]}
            ],
            "refs":[]
        },
        "regresando-a-compras-granel":{
            "image": blog2,
            "title":"Bulk product purchases",
            "desc":"This buying way is perhaps one of the most ancient ones",
            "pars":[
                {"text":"Bulk buying is not a new topic at all, this way of buying is perhaps one of the most ancient ones, in which not only merchants were involved, very probably, our grandmothers and great-grandmothers chose to make bulk purchases in markets.  However, even today, although there are already price clubs offering packaged products for wholesale, foods purchased in bulk are known to be somewhat \"healthier\" and \"fresher\" because they do not contain preservatives used to prolong their shelf life. ", className:"blog-page-par-first"},
                {"text":["It is indeed because of foods’ quality and competitive pricing strategies that in the second half of the 20th century the biggest market in Mexico, ",<a className="blog-link" href="http://www.goldfritz.com" target="_blank" rel="noreferrer noopener">Central de Abastos</a>," was opened in Mexico City with at least 2 miles of length. Nowadays, it manages a flow of $9 billion Mexican pesos per year, making it the second point of operations in the country, after the Mexican Stock Exchange Market."]},
                {"text":["We at Galdisa recommend you to adopt ",<a className="blog-link" href="http://www.goldfritz.com" target="_blank" rel="noreferrer noopener">bulk shopping</a>," which not only turns out to be healthier, but also cheaper by not paying extra expenses (like packaging, for example), also it’s more natural and eco-friendly!  Here are a few tips to make your next purchases even more efficient:"],"list":[
                    "Don’t forget your own reusable bag. You will end shopping just what you need without producing garbage ",
                    "Go to an specific place for bulk purchases, from the markets near to your house up to the big national markets such as “Central de Abastos” in Mexico",
                    "Schedule your visit early in the morning, that way you’ll find fresh products and be able to finish earlier!",
                    "Compare prices between each business so, you will find the best deals and variety of products" 
                ]},
                {"text":"The smartest purchases are not only the cheaper, also the healthier. Betting for bulk purchases is certainly an excellent decision."},
                {"text":["We invite you to check out the ",<a className="blog-link" href="/products" target="_blank" rel="noreferrer noopener">Products</a>," section in our webpage to discover our catalogue. Do not hesitate to contact us."]}
            ],
            "refs":[]
        },
        "alimentacion-grasas-sanas-beneficios":{
            "image": blog3,
            "title":"Keto diet and its benefits",
            "desc":"Eating well includes making healthy and tasty food choices",
            "pars":[
                {"text":"Eating well includes making healthy and tasty food choices. Peanuts make healthy eating easy, since they are a very complete food that contain multiple nutrients. Their flavor is enjoyed by all age groups and studies have shown that everyone, regardless of age, benefits from eating them.", className:"blog-page-par-first"},
                {"text":"Living a healthy lifestyle or trying to lose weight can seem difficult; restricting certain foods or fats is usually believed to be the best approach to reach the ideal weight, however, it has been proved, that numerous diets that incorporate healthy fats, such as peanuts, deliver better results. "},
                {"text":"Fats are one of the types of nutrients that are obtained from food and that are essential for the correct performance of our body. There are 2 kinds of fats: saturated (bad and trans-fats) and unsaturated (considered as healthy fats). "},
                {"text":"Saturated fats: this kind of fats raise the levels of bad cholesterol in the human body. They can be found in foods such as bacon, butter, dairy products, sausages, and others. Nutritionists believe that they should be avoided. "},
                {"text":"Unsaturated fats: they are commonly known as oils. They raise the levels of good cholesterol and triglycerides in the blood. You can find them in fish, olive oil, oil seeds such as sunflowers, peanuts, nuts, walnuts and almonds."},
                {"text":"More than 80% of the fats that come from peanuts are unsaturated and therefore, healthy for our bodies. This type of fat provides additional benefits such as: ","list":[
                    "Energy",
                    "Encourage brain activity",
                    "Regulate temperature and inflammation",
                    "They keep skin and hair in good condition",
                ]},
                {"text":"Bonus: keep in mind the following percentages of nutrients, recommended in professional food pyramids:  30% fats, 30% proteins and 40% carbohydrates."},
            ],
            "refs":[]
        },
        "ideas-lunch":{
            "image": blog4,
            "title":"Simple lunch recipes ",
            "desc":"Lunchtime becomes more fun and nutritious, if you add ¡peanuts!",
            "pars":[
                {"text":"Lunchtime becomes more fun and nutritious, if you add ¡peanuts! Here are some simple lunch ideas that you can do in less than 5 minutes", className:"blog-page-par-first"},
                {"title":"Spicy salad", "text":"Ingredients:", "list":[
                    "Cucumbers",
                    "Carrots",
                    "Peanuts",
                    "Lemon",
                    "Salt",
                    "Spicy sauce"
                ], "gallery":[{src:pepinos, alt:'Spicy salad'}]},
                {"text":"Peel the cucumbers and cut them in the middle, first half widthwise and then again lengthwise. With the help of a spoon, remove the seeds and leave the center of the cucumbers clean. Shred the carrots, place them in a bowl and stir with peanuts, lemon, salt and sauce. Finally add this fabulous mix to the cucumbers. "},
                {"title":"Banana and peanut butter sandwich", "text":"Ingredients:", "list":[
                    "Bread",
                    "Bananas",
                    "Peanut butter",
                    "Amaranth",
                ], "gallery":[{src:platanos, alt:'Banana and peanut butter sandwich'}]},
                {"text":"Toast the bread and spread the peanut butter, on top, place some banana slices and amaranth. Yummy!"},
                {"title":"Crunchy salad", "text":"Ingredients:", "list":[
                    "Lettuce",
                    "Roasted chicken breast",
                    "Panela or basket cheese",
                    "Mango",
                    "Peanuts"
                ], "gallery":[{src:ensalada, alt:'Crunchy'}]},
                {"text":"Disinfect the lettuce. Add it to a bowl with the roasted breast, cheese, mango and peanuts."},
                {"text":"It’s a practical, simple and nutritious salad, plus you can add your favorite dressing."},
            ],
            "refs":[]
        },
        "programa-campo":{
            "image": blog5,
            "title":"Fields program",
            "pars":[
                {"title":"Agricultural Program","text":"Galdisa has been maintaining agricultural programs dedicated to peanuts with farmers in the north of the country for more than 15 years. Our programs, in general, consist of providing certified seeds, technical assistance and approved agrochemicals, to ensure from the beginning of the supply chain, the quality of its associated finished products.", className:"blog-page-par-first"},
                {"text":"All the peanuts associated to our programs are received in our points of purchase, to be later shelled, classified and selected for their commercialization or processing."},
                {"title":"Peanut cultivation stages:"},
                {"text":<div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}><img src={ciclo_cultivo_en} style={{width:'70%', margin:'2rem auto'}} alt="Cycle"/></div>}
            ],
            "refs":[]
        },
        "beneficios-cacahuate-embarazo":{
            "image": blog6,
            "title":"Benefits of peanuts during pregnancy ",
            "desc":"Many are unaware of the benefits and nutrients peanuts provide during pregnancy",
            "pars":[
                {"text":"Most people consider peanuts as a perfect snack to satisfy  their  hunger , but many are unaware of  the great  benefits and nutrients they provide during pregnancy.", className:"blog-page-par-first"},
                {"text":"The enormous amount of folic acid, amino acids, vitamins and minerals, make it the perfet snack to contribute to good development  of the baby´s cells during gestation, and not only to this one, but also to the mother. A double win!"},
                {"text":<div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}><img src={infografia_embarazo_en} style={{width:'100%', margin:'2rem auto'}} alt="Infografía Embarazo"/></div>}
            ],
            "refs":[]
        },
        "snack-ideal-diabetes":{
            "image": blog7,
            "title":"The ideal snack for diabetic patients",
            "desc":"If you have been diagnosed with diabetes, forbidden foods become a central issue every time .",
            "pars":[
                {"text":"We know that if you have been diagnosed with diabetes, forbidden foods become a central issue every time you go shopping or eat somewhere else outside your home, it is very important to learn to choose foods that can help you control the disease, peanut are ideal: This famous legume helps us maintain stable levels of glucose , in addition to releasing insulin into our body, avoids risks of gangrene linked to diabetic foot and other infections. Learn more about the benefits that peanuts provide to stick to our dietary treatment and remember to eat some peanuts every morning ", className:"blog-page-par-first"},
                {"text":<div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}><img src={infografia_diabetes_en} style={{width:'100%', margin:'2rem auto'}} alt="Infografía Diabetes"/></div>}
            ],
            "refs":[]
        },
        "leche-cacahuate":{
            "image": blog8,
            "title":"How to prepare peanut milk?",
            "desc": "An increasingly popular alternative (vegetable, vegan milk)",
            "pars":[
                {"text":"As we have discussed in previous articles, the peanut is a legume that contains high quantity and variety of proteins, fats, carbohydrates and vitamins. ", className:"blog-page-par-first"},
                {"text":"Peanuts are also a very versatile food that can be used in endless recipes, whether as the main ingredient,  as a snack or even as a drink!"},
                {"text":"Yes, peanut milk is becoming more and more known because it is very healthy but also quite economical because its preparation can be done from the comfort of our homes. Have you tried it?  If not, give it a try."},
                {"title":"Peanut milk ingredients : ", "list":[
                    "1 cup of peanuts (peeled, raw and unsalted)",
                    "3 cups of water",
                    "Optional  (honey, brown or agave sugar)",
                    "1 teaspoon of cinnamon and vanilla",
                ]},
                {"title":"Steps to follow: ", "list":[
                    "1.	Place a cup of peanuts and pour 3 cups of water in a bowl ",
                    "2.	Let it rest for 8 hours.",
                    "3.	After 8 hours, drain the peanuts and rinse them",
                    "4.	Blend the peanuts until you get a paste",
                    "5.	 Add 3 cups of water and beat for 5 minutes.",
                    "6.	If you want the milk to be sweet, pour it into the blender and add 1-2 teaspoon of the sweetener of your choice, as well as vanilla and cinnamon. ",
                    "7.	Keep the milk in a jar, cover it and store it into the refrigerator for 3 days."
                ]},
                {"text":"It will be worth the wait as its delicious taste will definitely remind you of a good cold afternoon. This milk is 100% vegan, vegetable and free of animal husbandry. "},
                {"text":"Vegetable milks are a very good option for those who are lactose intolerant, for people on a vegan diet and for those looking to maintain a balanced nutrition. Among its benefits are: ", "list":[
                    "They are easily digestible ",
                    "They help the skin to look younger and brighter",
                    "They encourage the release of stress and therefore help to sleep better."
                ]}
            ],
            "refs":[]
        },
        "nutricion-deportiva":{
            "image": blog9,
            "title":"Sports Nutrition ",
            "desc": "The human body is designed to keep itself in motion, for thousands of years, our genes have been coded for this. ",
            "pars":[
                {"text":"The human body is designed to keep itself in motion, for thousands of years, our genes have been coded for this. ", className:"blog-page-par-first"},
                {"text":"Today the trends towards a healthy and active lifestyle are stronger than ever. More and more people are looking to eat a balanced diet, use natural products,  get informed or read articles about stress release and balanced living. "},
                {"text":"In line with those trends, the number of people seeking physical activity has grown, perhaps not in a professional or high performance way, but in a constant and intense practice. "},
                {"text":"All these changes in our lifestyles must be followed by modifications/adjustments in our diet. Physical activity causes wear and tear on our bodies, so joining a sports diet will provide the following benefits:", "list":[
                    "1. More energy during workout.",
                    "2. Improve recovery to prevent injuries.",
                    "3. Achieve results such as muscle building and/or physical condition.",
                ]},
                {"text":"After finishing our exercise routine it is important to recover the lost nutrients. According to Beatriz Boullosa, president of the Mexican Federation of Sports Nutrition, in order to boost glycogen synthesis in the muscle it is recommended to ingest carbohydrates and combine them with a protein."},
                {"text":"A balanced meal to stay in shape must contain: carbs to feed the cells, proteins to build and repair the cells, and fats to protect the cells."},
                {"text":"Where to find healthy carbohydrates and fats? ", "list":[
                    "Nuts",
                    "Peanuts",
                    "Peanut cream",
                    "Dried fruit",
                    "Cashews",
                    "Chickpeas"
                ] },
                {"text":"Peanuts contain 7 grams of protein per ounce, more than any other nut, so they provide long-lasting energy and help to build up muscle. Peanuts are a rich source of fiber and contain good fats, which are important for heart health."},
            ],
            "refs":[]            
        },
        "lomo-salsa-cacahuate":{
            "image": blog10,
            "title":["Pork loin with peanut sauce"],
            "desc": "December is a very special season because it is a time to share and reflect...",
            "pars":[
                {"text":"December is a very special season because it is a time to share and reflect on what we have experienced during the year. This awesome season brings many emotions, feelings and flavors that translate into happiness, peace and harmony. ", className:"blog-page-par-first"},
                {"text":"Each family throws different celebrations according to their traditions and beliefs, but without a doubt  when talking about holidays family and food are certainly the most important thing. "},
                {"text":"Today we are shearing a delicious recipe to surprise your loved ones: Pork loin with peanut sauce"},
                {"title":"Ingredients", "list":[
                    "200 gr. of peeled and unsalted peanuts",
                    "4 chipotle peppers",
                    "1/2 sliced onion",
                    "2  garlic cloves",
                    "2.2 lb of pork loin",
                    "2 chicken soup cups",
                    "5 tomatoes"
                ]},
                {"title":"Preparation:", "list":[
                    "1. Fry: Fry the peanuts in butter and remove them when they take on a golden hue.",
                    "2. Blend and cook: Blend together 5 tomatoes, the onion, garlic, chicken soup and peanuts.",
                    "3. Cut: Cut the pork in equal pieces.",
                    "4. Bake: Wrap in aluminum foil and bake at 180 degrees for 1 ½ hours or until cooked.",
                    "5. Take out: Take out the pork loin and raise the oven temperature to 200 degrees.",
                    "6. Remove: Remove the aluminum foil and add the juice that came off while baking. Return to the oven and bake for another 25 minutes.",
                    "7. Let it rest: Let it rest outside the oven for 10 minutes to be able to serve.",
                    "8. Enjoy: Enjoy and celebrate the holidays!"
                ]},                

                {"text":"Tip: If you wish to add chopped peanuts to increase the flavor, add them at the moment of serving."},
            ],
            "refs":[]            
        },
        "tendencias-alimentarias-pandemia":{
            "image": blog11,
            "title":["Food trends emerging from a pandemic"],
            "desc": "Due to the pandemic, never before have we seen such significant sweeps of change within the food industry...",
            "pars":[
                {"text":"With the world in various states of lockdown due to the pandemic, never before have we seen such significant sweeps of change within the food industry. The pandemic presents a challenge to those in foodservice especially, yet also a big opportunity to reimagine dining hospitality.", className:"blog-page-par-first"},
                {"text":"Food suppliers and brands have had to adapt their offerings to a consumer who is mostly at home. As take-out have become more relevant and taken on a new meaning, diners are now creating their own experiences and moments of enjoyment from self-prepared or ordered food."},
                {"text":"Taking into account all of the above, during the health emergency generated by COVID- 19, five trends that will impact the eating habits of our consumers during 2021,  have emerged:"},
                {"title":"1) IMMUNITY", "text":"Without a doubt, the demand for products and ingredients that help strengthen the immune system has grown. The \"immunity\" trend is here to stay. Now consumers are basing their purchases on foods or beverages that carry an immunity claim.  Vitamin boosted offerings such as beverages or snacks have been the big winners of this trend."},
                {"title":"2) BREAD", "text":"Bread, because of its versatility, comfort and ease is the key ingredient in almost everything.  Now a new concept of entertainment is emerging, which consists of baking bread at home. These breads are created from an endless number of extravagant recipes that include everything from pistachios, sunflower seeds, blueberries, to amaranth and peanuts. A delicious, homemade bread is the basis for trend #2."},
                {"title":"3) GOURMET SANDWICH", "text":"Old school deli sandwiches are being elevated to a new level of sophistication. We can find sweet sandwiches with peanut butter or salted sandwiches that besides ham and cheese can be enriched with seeds to increase their nutritional value."},
                {"title":"4) GRILL FRENZY", "text":"Barbecue lovers have been able to take out the most of their grill. Being at home has awakened our chef's instinct and more than one of us has put our barbecue to the test with delicious new recipes."},
                {"title":"5) VEGETABLE DAIRY PRODUCTS", "text":"Alternative products made from peanuts, nuts or dried fruits have become part of the daily diet of people looking for an alternative to milk, even cafeterias include it as a milk option within their drinks, attracting younger consumers with ecological benefits and healthier alternatives."},
                {"text":["Galdisa offers to the food industry a wide range of seed related products from baking and confectionery. We invite you to visit our ", <a className="blog-link" href="/products" target="_blank" rel="noreferrer noopener">products section.</a>]}
            ],
            "refs":[]            
        },
        "sindrome-metabolico":{
            "image": blog12,
            "title":["Peanut Consumption Can Help Reverse Metabolic Syndrome"],
            "desc": "Metabolic syndrome is the name for a group of risk factors that raises the risk of heart disease...",
            "pars":[
                {"text":"Metabolic syndrome is the name for a group of risk factors that raises the risk of heart disease, diabetes and other health problems that often occur simultaneously. People with metabolic syndrome have at least 3 of the following risk factors:", className:"blog-page-par-first",
                    "list":[
                        "\"Apple-shaped\" body, also known as abdominal obesity.",
                        "High triglycerides, a type of bad fat found in the blood.",
                        "Low HDL cholesterol. HDL is known as \"good\" cholesterol because it helps remove \"bad\" cholesterol from the arteries.",
                        "High blood pressure. Blood pressure is the force of blood pushing against the walls of the arteries as our heart pumps blood. If this pressure rises and stays high over time, it can damage your heart and lead to plaque buildup.",
                        "High fasting blood sugar: High blood sugar can be an early sign of diabetes."
                    ]
                },
                {"text":"The more of these factors, the greater the risk of heart disease, diabetes and stroke."},
                {"text":"Metabolic syndrome has several causes, some of which are obesity or being overweight, accompanied by a sedentary lifestyle and insulin resistance."},
                {"text":"The treatment for metabolic syndrome consists of adequately treating each of the underlying diseases (diabetes, high cholesterol levels or high blood pressure)."},
                {"text":"Exercise and a proper weight loss diet are recommended as a complement for the treatment, which will help to improve insulin sensitivity, reduce blood pressure as well as cholesterol levels."},
                {"title":"A great ally: The peanut", "text":"A study conducted by the Peanut Institute, led by Harvard researchers, showed that eating two ounces of lightly salted peanuts daily for 12 weeks may help reverse a medical condition known as metabolic syndrome. The study shows that those who ate peanuts had a two times higher likelihood of reversing their metabolic syndrome than those in a control group"},
                {"text":[<i>“This study is unique because it added just one new element to participants’ diets with the introduction of two servings of peanuts each day,” says Dr. Samara Sterling, director of research for The Peanut Institute. “The research showed the impact of nutrient-dense peanuts and how they positively affected participants’ health outcomes.”</i>]},
                {"text":["The study also found that eating two servings of peanuts daily for 12 weeks did not cause participants to gain weight.", <i>“It’s exciting research because it supports the approach that food can be thought of as medicine in the fight against chronic diseases,” says Sterling. “Peanuts are packed with nutrients and are able to deliver health benefits in a small and affordable serving.”</i>]},
                {"text":"Two servings or approximately 70 peanuts cost less than 30 cents, are about 170 calories and contain 14 grams of plant protein plus 19 vitamins and minerals. Peanuts are foods that have antioxidants, vegetable substances and good quality fats that help reduce the so called \"bad cholesterol\" and increase the good cholesterol."},
                {"text":"Who does not know peanuts? This small food is worldwide known mainly in cuisine, making it the protagonist of delicious dishes or for being a healthy snack. Now you also know that peanuts can also be incredibly beneficial for the health of those who suffer from metabolic syndrome."},
            ],
            "refs":[]            
        },
        "cafe-cacahuate":{
            "image": blog13,
            "title":["Peanut coffee, the new alternative for coffee lovers"],
            "desc": "In order not to give up the delicious morning coffee, human beings have looked for alternatives...",
            "pars":[
                {"text":"The smell of coffee is one of our brain's favorites, because without a doubt, there is no richer smell than the one of a freshly brewed coffee. To feel how its aroma wakes you up in the morning and helps you to enter the world little by little, is priceless.", className:"blog-page-par-first"},
                {"text":"However, it is very common for it to have negative effects on people, which is why some of us are forced to limit our intake. Insomnia, tachycardia, headaches and even anxiety are just some of the ailments of caffeine, which is found in coffee as well as in some teas. "},
                {"text":"In order not to give up the delicious morning coffee, human beings have looked for alternatives that help us avoid these symptoms, but at the same time are just as delicious and pleasant. "},
                {"text":["If you are a fan of ",<a className="blog-link" href="http://www.galdisa.com/static/media/RoastedPeanuts.c448623f.pdf" target="_blank" rel="noopener noreferrer">peanuts</a>," in all their presentations, from a sandwich with ",<a className="blog-link" href="http://www.galdisa.com/static/media/PeanutButter.448a949c.pdf" target="_blank" rel="noopener noreferrer">peanut butter</a>," or simply eating some of them for a healthy snack, we have the best news for you: Thanks to the discovery made by farmers in Suffolk, Virginia, peanuts could change the lives of coffee lovers. "]},
                {"text":"Its characteristics allow it to serve as an excellent coffee bean. Peanut coffee is a natural beverage without caffeine and has a series of benefits: low acidity, does not generate dependency and is not diuretic."},
                {"title":"What is peanut coffee?", "text":"John Harrel, owner and creator of peanut coffee, tells us that the process consists of extracting the peanut oil, \"the extraction leaves a fine, powdery substance that is very similar to espresso in appearance\", the fine powder is then blended with a coarser particle size to achieve a consistency similar to that of traditional ground coffee."},
                {"text":"Peanut coffee surprisingly tastes very similar to traditional coffee. It is smooth and has a roasted touch. It can be easily prepared at home in the same way you prepare your morning coffee or using a French press. "},
                {"text":"Undoubtedly, peanuts are a well-known food in Mexican gastronomy, recognized for the multiple nutrients and benefits they provide to the body. Now, it also becomes our faithful companion to wake up in the morning. "},
            ],
            "refs":[]            
        },
        "consumo-cacahuate-pandemia": {
            "image": blog14,
            "title":["Due the pandemic peanut consumption has increased"],
            "desc": "The pandemic has altered many aspects of our daily lives, Peanut consumption has increased exponentially...",
            "pars":[
                {"text":["The pandemic has altered many aspects of our daily lives, mainly the way we consume. New trends in purchasing products and services play an important role in our daily lives. ", <a href="/products" className="blog-link" rel="noopener noreferrer" target="_blank">Peanut</a> ," consumption has increased exponentially and thanks to its different presentations and benefits it has become one of the favorite snacks!"], className:"blog-page-par-first"},
                {"text":["Since the onset of the ",<a href="/blog/tendencias-alimentarias-pandemia" className="blog-link" rel="noopener noreferrer" target="_blank">pandemic</a>,", consumer purchasing behaviors have changed to quickly adapt to the new normal. Every day more and more people buy their food through mobile apps or prepare it at home. Because of this, parents and home office workers are now looking for alternatives that provide nutrients, rich flavor and ease of preparation. What better than a fistful of peanuts or a slice of bread with ",<a href="/products" className="blog-link" rel="noopener noreferrer" target="_blank">peanut butter</a>," with jam or fruit!"]},
                {"title":"Peanut butter Frenezzi","text":"With this new lifestyle,  children and adults have discovered their love for peanut butter as it is an explosion of flavor, nutritious, versatile and also accessible to all budgets. It provides comfort and satiety at any time of the day."},
                {"text":"A recent consumer behavior report by J.M. Smucker Company states that the pandemic accelerated peanut butter sales growth by 7.1% during the 52 weeks ending November 1, 2020."},
                {"title":"How has it increased?","text":["Consumers are dining on ", <a href="/blog/beneficios-cacahuate" className="blog-link" rel="noopener noreferrer" target="_blank">peanut butter</a>," and jelly 64% more, up from 49% in both mornings and evenings. Younger generations are adapting it as a snack."]},
                {"text":"It is not surprising that sales have increased in the last year by 95%, according to Golin, because of the multiple combinations related to recipes and nutrition tips on social networks 55% mention peanut butter as the protagonist of their dishes. And other communication channels with 414% as a non-perishable product. "},
                {"title":["Peanuts as a ", <a href="/products#botanas" className="blog-link" rel="noopener noreferrer" target="_blank">snack</a>], "text":"2020 was an exceptional year for peanuts as a snack, as its sales increased by 7.25% reaching the best displacement volumes since 2016. The increase was higher than 7.2% in the nuts category compared to others. "},
                {"text":"Peanuts have been our faithful companions in times of uncertainty, in other words, it is comforting for people to share peanuts in all their presentations and causes them joy in difficult times. Above all, we have discovered a love for peanuts in all of their formats."}
            ],
            "refs":[]            
        },
        "mindfulness-salud-mental-2021": {
            "image": blog15,
            "title":["4 ways to use mindfulness to take care of your mental health in 2021"],
            "desc": "Last year we changed our daily routines drastically and thought that 2021 would be better....",
            "pars":[
                {"text":["Last year we changed our daily routines drastically and thought that 2021 would be better. Despite being in the vaccination stage, it is recommended to stay at home and follow the recommended hygiene measures and, therefore, for those of us who work at home office or are parents, this year has changed very little."], className:"blog-page-par-first"},
                {"text":["Staying at home during the pandemic can cause stress, anxiety and lack of concentration. Don't despair, just as you take care of the coronavirus you should take care of your mental health. You deserve a time to be free of thoughts and judgments towards yourself and your surroundings. That is why we recommend using mindfulness techniques:"], "list":[
                    "Enjoy the little things: Every day we ignore to do or see small things and shores , like: having breakfast, watching the sunrise, walking your pet, etc. We suggest you pay attention and find a way to enjoy them. You can write them down on your smartphone or in a notebook to come back to them when you need to. ",
                    "Breathe: Take a few minutes a day to be aware of your breath, be present and not overwhelmed by an uncertain future. Concentrate on your inhalation and exhalation, feel the air going in and out of your nose. Little by little you will get better, try it.",
                    "Move your body: Focus your energy on one hour daily workout to help you relax and de-stress after a day of work. We recommend practicing yoga, jumping rope or another inhouse activity. This will also release endorphins, known as happiness hormones.",
                    "Connect with nature: We mean go for a walk, sit on your patio in the sun or enjoy the day and your plants from the window. If your mind keeps wandering, focus on a sound, such as birdsong or the wind. Concentrate on what it is producing to you, what the rhythm is like, if it is pleasant to you, etc. Enjoy your surroundings."
                ]},
                {"text":["Mindfulness can be understood as being in balance with you and your surroundings, without pressure. And one way to complement being well with your body is eating ", <a className="blog-link" href="/blog/ideas-lunch" target="_blank" rel="noopener noreferrer">healthy recipes</a>, ". We recommend the banana and peanut butter sandwich or the crunchy salad." ]},
            ],
            "refs":[]            
        },
        "palanquetas-distintas-semillas": {
            "image": blog16,
            "title":["Palanquetas recipe with different types of seeds."],
            "desc": "In these times of home office we are constantly looking for nutritious snacks that are easy to prepare at home. The palanqueta is one of the best options ...",
            "pars":[
                {"text":["In these times of home office we are constantly looking for nutritious snacks that are easy to prepare at home. The palanqueta is one of the best options for being high in protein, fiber and vegetable fat; it also benefits in lowering bad cholesterol and is delicious!"], className:"blog-page-par-first"},
                {"text":["Did you know that palanqueta comes from the word 'papaquili' which means happy or cheerful? This typical Mexican sweet is perfect to boost your energy between meals. We show you that when preparing it, it should not only contain peanuts, but you can complement it with different seeds, blueberries and other high quality ", <a className="blog-link" href="/products" target="_blank" rel="noopener noreferrer" >bulk ingredients</a> ," offered by ", <a className="blog-link" href="/aboutus" target="_blank" rel="noopener noreferrer" >Galdisa</a> ,"."]},
                {"text":["There's no excuse to have this tasty snack on hand!"]},
                {"title":"You need:", "list":[
                    "2 cups of natural roasted peanuts.",
                    "¼ cup cashews.",
                    "¼ cup cranberries. ",
                    "2 cups sugar.",
                    "½ cup corn syrup.",
                    "60 gr. butter.",
                    "¼ cup water.",
                ]},
                {"title":"Proceso:", "list":[
                    "1. In a frying pan toast the peanut, the  nut and the Indian nut until they release their aroma and set them aside.",
                    "2. Put the water over high heat and gradually add the corn syrup, butter and sugar until it starts to boil. Stir with a stirring stick constantly to form a caramel texture.",
                    "3. Add the toasted seeds, cranberries, butter and stir until everything is coated. Remove from heat.",
                    "4. Spread the mixture in a greased buttered bowl and cut rectangles with a wet knife.",
                    "5. Wait for it to cool and enjoy some crunchy palanquetas!",
                ]},
                {"text":["A tip to make your palanquetas taste better is to buy your seeds in bulk, as they do not contain preservatives that prolong their shelf life. Always choose the healthiest, most nutritious and tasty options."]},
                {"text":["We invite you to review the ", <a className="blog-link" href="/products" target="_blank" rel="noopener noreferrer">productos</a>, " section to see our catalog. Do not hesitate to contact us." ]},
            ],
            "refs":[]            
        },
        "preparar-salsa-cacahuate": {
            "image": blog17,
            "title":["How to prepare peanut sauce?"],
            "desc": "Peanuts, besides being delicious and extremely healthy, are well known for providing proteins, fats and nutrients that are beneficial for the human body ...",
            "pars":[
                {"text":"Peanuts, besides being delicious and extremely healthy, are well known for providing proteins, fats and nutrients that are beneficial for the human body. That's why at Galdisa we recommend including them in your daily diet, but can they only be consumed as a snack?", className:"blog-page-par-first"},
                {"text":["Of course not!  Nowadays there are several ways to consume them, ranging from eating them raw, roasted, as a drink, peanut butter and now even salsa!"]},
                {"text":"Here's a simple recipe to prepare peanut sauce at home. A delicious way to spice up your food!"},
                {"title":"You need:", "list":[
                    "1 cup of shelled natural peanuts.",
                    "5 chiles de árbol",
                    "¼ onion.",
                    "Olive oil.",
                    "Salt",
                    "Water",
                ]},
                {"title":"Preparation:", "list":[
                    "1. Place a frying pan over medium heat and pour olive oil. Wait for it to heat up.",
                    "2. Carefully add chile de árbol and garlic. Brown and set aside.",
                    "3. In that same pan roast the peanuts with the onion until they are also golden brown.",
                    "4. Add the peanuts, chiles, garlic, onion and salt into the blender.  Gradually add water for smooth consistency",
                    "5. Ready! If you want to give more texture you can add some peanuts.",
                ]},
                {"text":["Don't forget to buy your products in bulk to keep them fresh and tasty. Enjoy! "]},
            ],
            "refs":[]            
        },
        "incluir-cacahuate-dieta": {
            "image": blog18,
            "title":["Sneak 'Superfoods' Into Your Diet"],
            "desc": "When we say that eating a handful of seeds daily will make a difference in your health, attitude and energy, it's no lie!  ...",
            "pars":[
                {"text":"When we say that eating a handful of seeds daily will make a difference in your health, attitude and energy, it's no lie! These little foods are a very important source of nutrients to prevent diseases, promote skin and improve cholesterol levels, so don't leave them out of your diet!", className:"blog-page-par-first"},
                {"text":["Here we will show you the benefits of peanuts, blueberries, cashews and pumpkin seeds. Remember that buying them raw and in bulk is a healthier, more nutritious and environmentally friendly option."]},
                {"title":"Peanut","text":"It is considered a superfood because of all its healthy benefits. For example: prevent heart diseases, combat anemia, help the nervous system, prevent the development of diabetes type 2, and more!"},
                {"text":"But to get all these benefits it is important to consume up to 25 - 30 grams a day, depending on your muscle mass. You can eat them in salads, snacks, meals, and more."},
                {"title":"Cranberry", "text":"The cranberry is very well known for being an antioxidant and having a high vitamin content. It helps prevent urinary tract infections, improves intestinal health, reduces the risk of cardiovascular diseases and is ideal in low-fat diets. The recommended amount to consume is 90 to 100 grams per day. You can find them in snacks, drinks, breads, salads, etc. You won't regret it!"},
                {"title":"Indian walnuts", "text":"Nuts in general are famous for accompanying all kinds of desserts or meals, but sometimes we forget all the benefits they bring to our health. The Indian nut is an aid in lowering cholesterol, helps to have healthier hair, skin and nails; it helps to reduce inflammation of the digestive tract and, if you exercise, it helps to tone muscles. With 28 grams a day you can notice a big difference. You can add it to pasta, breads, salads, etc."},
                {"title":"Pumpkin seeds", "text":"Pumpkin seeds cannot be left behind if you want to improve your health, as they are rich in magnesium, fatty acids, omega 3 and fiber. For all this, Pumpkin seeds benefits cardiovascular health, lowers high blood pressure, helps the proper functioning of the heart and brain, among other functions. Consuming 10 to 15 grams daily is a very good option. Remember that, like other seeds, you can prepare them in different ways. Your creativity is the limit!"},
                {"text":["It is very important that you do not exceed the recommended amounts of these superfoods, because the results will not be as expected."]},
                {"text":["We invite you to visit our ", <a className="blog-link" href="/products" target="_blank" rel="noopener noreferrer">catalog</a>, " in ", <a className="blog-link" href="/" target="_blank" rel="noopener noreferrer">Galdisa</a>, " where each product has its nutritional information, its different uses and some benefits. Taking care of your diet is a fundamental part of everyday life." ]},
            ],
            "refs":[]            
        },                          
        "leche-mantequilla-cacahuate": {
            "image": blog19,
            "title":["Peanut milk, butter, flour and oil?"],
            "desc": "Did you know that peanuts are more than just a snack? Every day more people recognize peanut´s properties... ",
            "pars":[
                {"text":"Did you know that peanuts are more than just a snack? Every day more people recognize peanut´s properties and seek to consume them in their different presentations. ", className:"blog-page-par-first"},
                {"text":["If you are bored of only eating them as seeds; butter, milk, flour and oil are healthy alternatives, since even modifying their presentation, the original properties of this incredible food are preserved. Do you dare to try them?"]},
                {"title":"PEANUT MILK","text":"Peanut milk is an ideal option for those who are lactose intolerant, want to change their diet or try the rich flavor of peanuts in their favorite beverages. It contains protein, monounsaturated fats, vitamin E and B3, magnesium and phosphorus. What are you waiting for to include this delicious milk in your daily diet and improve your health?"},
                {"title":"FLOUR", "text":"Flour is an excellent gluten-free option to give great flavor to bakery, pastries and even energy bars. In addition, transforming peanuts into flour reduces the amount of fat by 12% to 18%, a drastic change if we consider that its normal percentage is 48%. However, if the flour is defatted, its protein content doubles from 25% to 50%, an excellent option if you are looking to increase your protein intake and build more muscle mass!"},
                {"title":"OIL", "text":"Peanut oil is more used in Asian gastronomy for the rich flavor it provides when cooking or seasoning.  We can add it to our meals to sauté vegetables or to accompany  salads.  It should be taken into account that due to its high percentage of fats, it is not recommended to use it at high temperatures or for frying."},
                {"text":"Because it is rich in vitamin 3, it is also advisable to apply it in face or body massages and lip moisturizer."},
                {"title":"PEANUT BUTTER", "text":"Peanut butter is one of the most famous and favorite presentations thanks to its flavor, benefits and versatility; you can make some toast, accompany a dessert, add it to a smoothie, etc. And, if you are a person who is constantly physically active, consuming it as a snack can be very beneficial because of its high caloric content (2 teaspoons are 200 calories) and protein (25%). Cheer up!"},
                {"text":["Which is your favorite presentation? Each of them has its own thing to add a peanutty touch to your day! All these presentations, with the exception of the oil, are very easy to make at home. In our ",<a className="blog-link" href="/blog" target="_blank" rel="noopener noreferrer">blog</a>," section you can find \"",<a className="blog-link" href="/blog/leche-cacahuate" target="_blank" rel="noopener noreferrer">How to prepare peanut milk?</a>,"\", recipes and more information!"]},
            ],
            "refs":[]            
        },                
        "galletas-arana-cacahuate": {
            "image": blog20,
            "title":["Spider cookies with peanut butter.", "Happy Halloween!"],
            "desc": "Add a little terror to your Halloween night! Spider cookies with peanut butter will give your guests a lovely scare",
            "pars":[
                {"text":"Add a little terror to your Halloween night! Spider cookies with peanut butter will give your guests a lovely scare. Also, you can´t forget peanuts provide important fatty acids and minerals to strengthen our immune system – try out this easy and healthy recipe!", className:"blog-page-par-first"},
                {"title":"Ingredients",
                    "list":[
                        "½ cup butter",
                        "½ cup of peanut butter",
                        "½ cup brown sugar",
                        "1 egg",
                        "2 tablespoons of milk",
                        "1 teaspoon of baking soda",
                        "1 teaspoon of salt",
                        "1 ¾ cups of flour",
                        "18 chocolate-covered bonbons",
                        "36 candy eyes",
                        "100 g. of melted dark chocolate "
                    ]
                },
                {"title":"Step by step",
                    "list":[
                        "Preheat the oven to 160° C.",
                        "In a bowl, mix the butter and peanut butter until incorporated. Then add the brown sugar, egg and 2 tablespoons of milk.",
                        "In another bowl mix the salt, baking soda and flour. Add the dry ingredients into the first bowl until you get a dough. ",
                        "Form the dough into balls and coat the pan with a little butter. ",
                        "Bake for 12 minutes. Take the cookies out, let them cool a little. Flatten them slightly and with your thumb make a space for the chocolate.",
                        "Place the chocolate-covered marshmallow on top of the cookies.",
                        "Refrigerate for 20 minutes. ",
                        "Finally, glue the eyes and draw the paws with the melted chocolate."
                    ]},
                {"text":["Remember that you can know the nutritional information of Galdisa´s peanut butter in our ",<a className="blog-link" href="/products" target="_blank" rel="noopener noreferrer">product catalog</a>," and thus, have a better control of your diet. Don't forget that at  your spooky parties you can also enjoy our peanuts, pepitas, cranberries and more."]},
                {"text":"Check out our wide variety of products and enjoy them!"}
            ],
            "refs":[]            
        },                
        "ensalada-navidena-posadas": {
            "image": blog21,
            "title":["JOJOJO Christmas salad "],
            "desc": "Would you like to start this Christmas with a super tasty dish? We have the perfect recipe for you to enjoy all the flavor and color we can have in this season.",
            "pars":[
                {"text":"Would you like to start this Christmas with a super tasty dish? We have the perfect recipe for you to enjoy all the flavor and color we can have in this season. If you haven't tried this traditional recipe, we hope you enjoy it. ", className:"blog-page-par-first"},
                {"text":"Remember that to keep your defenses high and withstand the low temperatures of winter you must consume vitamin C, carbohydrates and fats; with this dish you will have it all in one. Your body will thank you for this delicious salad.  So you must prepare it!"},
                {"title":"Ingredients",
                    "list":[
                        "2 beets",
                        "1 jicama",
                        "2 pomegranates",
                        "2 oranges",
                        "1 small lettuce",
                        "½ cup of shelled peanuts",
                    ]
                },
                {"title":"Preparation",
                    "list":[
                        "1. Remove the peel from the beets and jicama. Cut into small pieces.",
                        "2. Cook the beets in a pot with enough water and keep one cup of the cooking water.",
                        "3. Open the pomegranate, remove the seeds and place them in a baking dish; then chop into small pieces some lettuce leaves, wash and disinfect them. Remove the peel from the oranges and cut into slices.",
                        "4. In a bowl mix all the chopped fruit. Add the water from cooking the beets to keep the fruit hydrated.",
                        "5. When serving, place the lettuce leaves first, then the fruits. Garnish with pomegranate seeds and peanuts.",
                    ]},
                {"text":"With this delicious salad you will have everything ready for this christmas eve."},
                {"text":["Remember that in ",<a className="blog-link" href="/blog" target="_blank" rel="noopener noreferrer">our blog</a>," you will find more recipes to receive the advantages of peanuts and other seeds during the winter."]},
            ],
            "refs":[]            
        },
        "desayuno-muy-navideno": {
            "image": blog22,
            "title":["A very Christmassy breakfast: Oatmeal, banana and peanut butter hot cakes"],
            "desc": "It's beginning to look a lot like christmas! Even in the smallest details you should notice that we are in Christmas time and, why not, make breakfast a festive treat for the whole family.",
            "pars":[
                {"text":"It's beginning to look a lot like christmas! Even in the smallest details you should notice that we are in Christmas time and, why not, make breakfast a festive treat for the whole family. You decide: it can be breakfast on the 24th or 25th after opening the presents, the idea is to make it a different breakfast according to the celebrations but also a simple one so that it does not take too much time, because the main focus will be the gifts and sharing with the family.", className:"blog-page-par-first"},
                {"text":"We propose this different recipe to give an interesting twist to the preparation of delicious hot cakes more natural and healthy.  You will find a homemade flavor, accompanied by a good amount of proteins, vitamins and minerals. It is the ideal combination to accompany with a hot drink such as fruit punch or hot chocolate."},
                {"text":"We hope you enjoy them!"},
                {"title":"Ingredients",
                    "list":[
                        "½ banana",
                        "1 egg",
                        "½ cup of oatmeal",
                        "1 butter tablespoon",
                        "5 peanut butter tablespoons",
                    ]
                },
                {"title":"Preparation",
                    "list":[
                        "1. Grind the oats in a blender until you get a powder without lumps. Reserve the oat flour.",
                        "2. In the same blender mix the egg, the banana and two tablespoons of peanut butter.",
                        "3. Gradually add the flour to the mixture until all the ingredients are blended.",
                        "4. Heat a frying pan over low heat, draw a circle with the butter and add a little of the mixture you prepared.",
                        "5. When the top is dry, turn it with a spatula and remove it 45 seconds later. ",
                        "6. When serving, cover the hot cake with a layer of peanut butter. You can add red fruits, nuts, seeds and enjoy!"
                    ]},
                {"text":"Remember that eating peanuts in the winter strengthens your immune system and is a good source of healthy fats. "},
                {"text":["Happy holidays!"]},
            ],
            "refs":[]            
        },
        "razones-cacahuate-superalimento": {
            "image": blog23,
            "title":["A verPeanuts Rise to Superfood Status"],
            "desc": "When it comes to the peanut, it's true that big things come in small packages.",
            "pars":[
                {"text":"When it comes to the peanut, it's true that big things come in small packages. The peanut is a nutrient-rich powerhouse. In fact, based on a mountain of research, this mighty legume deserves superfood status.", className:"blog-page-par-first"},
                {"text":"What is a superfood? It is a term used to indicate foods that are rich in nutrients: fiber, vitamins, fatty acids, ando more. This helps to recognize which foods stand out in their composition."},
                {"text":"Numerous studies have found that the consumption of small amounts of peanuts or peanut butter has been associated with a reduced risk of cancer, heart disease and diabetes. In fact in just the past year alone, new research has shown that those who regularly consumed peanuts had a lower risk for four kinds of cancer – breast, colorectal, esophageal and pancreatic cancers"},
                {"text":"The indicated amount to obtain the benefits that we are going to share in this blog is 30g. a day, about 14 whole peanuts or a tablespoon of peanut butter. "},
                {"text":"Let's start by talking about its energy intake, as it contains 27% protein and 8% fiber, which when combined favor intestinal motility and combat constipation. It also strengthens the immune system, provides energy to the body, increases the level of satiety and helps the growth and development of bones. "},
                {"text":"Secondly, fatty acids make up 50% of its composition and, of these, we can highlight that they help reduce LDL cholesterol (\"bad\" cholesterol) and increase HDL (\"good\" cholesterol), which is related to good cardiovascular health. In addition, they help to keep blood sugar levels constant, which is why they are recommended to prevent type 2 diabetes and can be consumed by those who suffer from the disease."},
                {"text":"Finally, peanuts provide a large amount of vitamins and minerals. For example, it contains both vitamin E and a large amount of B vitamins, which help to improve the quality of the skin, to take care of the nervous system and memory. In addition, we find magnesium and potassium, essential elements for muscle health, ideal for those who practice sports."},
                {"text":"These are some of the most important benefits that we can find in peanuts, but they are not all, since studies are still being carried out to discover more about this tiny but awesome seed."},
            ],
            "refs":[]            
        },
        "cacahuate-alimento-diabeticos": {
            "image": blog24,
            "title":["Peanuts as a buffer food for diabetics"],
            "desc": "When a person is diagnosed with diabetes, he or she begins to change their routine and diet, all with the purpose of improving his or her quality of life, since there is more than one concern when living with this condition",
            "pars":[
                {"text":"When a person is diagnosed with diabetes, he or she begins to change their routine and diet, all with the purpose of improving his or her quality of life, since there is more than one concern when living with this condition. For example, one recommendation that patients receive is to modify their diet and limit the consumption of carbohydrates to avoid the increase of glucose in the blood; another recommendation is to increase the consumption of foods rich in fiber. But the question arises, are there foods that can combine both characteristics? Studies have shown that the consumption of peanuts can be an ally in the diabetic diet. We will analyze its main advantages: ", className:"blog-page-par-first"},
                {"text":"The content of vegetable protein and healthy fats are the two main components that allow recommending the consumption of peanuts in the diet of diabetic people, as they allow controlling the level of glucose in the blood, that is, \"cushions\" or prevents sudden increases."},
                {"text":"Another reason why it is recommended to eat peanuts or peanut butter is because the amount of carbohydrates is mainly fiber. In addition, it has been found to help decrease appetite and food cravings. This gets the pancreas to have rest periods and decreases its deterioration, as it secretes hormones that promote satiety. Another important benefit can be seen in the amount of healthy fats in the body. This is interpreted by measuring the level of cholesterol in the blood. LDL cholesterol, which we commonly call \"bad\", decreases while HDL cholesterol, which we call \"good\", remains healthy. The antioxidant effect of adequate peanut consumption is currently being studied. This is important for patients with both type 1 and type 2 diabetes."},
                {"text":"Finally, incorporating peanuts into the diet can help prevent low levels of vitamins and minerals, including magnesium, a common problem among patients who have not been able to control their disease. They also contain a natural source of arginine, an amino acid essential for the formation of proteins and which facilitates the absorption of cholesterol in the intestine."},
                {"text":["Remember that the recommended amount of consumption is ⅓ cups of roasted peanuts or two tablespoons of peanut butter a day. Learn more about this at ", <a className="blog-link" rel="noopener noreferrer" href="https://www.galdisa.com/products">https://www.galdisa.com/products</a>]},
            ],
            "refs":[]            
        },            
        "relacion-cacahuate-mexico": {
            "image": blog25,
            "title":["Peanuts, a very Mexican product"],
            "desc": "Peanuts are one of the most important legumes worldwide, besides gathering several nutrients it is considered the most known and consumed snack, but its importance not only lies in its nutritional value, there is also a relationship with the agriculture, economy and gastronomy of Mexico's territory",
            "pars":[
                {"text":"Peanuts are one of the most important legumes worldwide, besides gathering several nutrients it is considered the most known and consumed snack, but its importance not only lies in its nutritional value, there is also a relationship with the agriculture, economy and gastronomy of Mexico's territory.", className:"blog-page-par-first"},
                {"text":"Peanuts are  native to the American continent and have been cultivated since pre-Hispanic times. There are records of its domestication and production in the territory currently occupied by the states of Puebla and Morelos. After the arrival of the Spaniards, they took it to Europe and Africa, which contributed to its worldwide distribution and consumption."},
                {"text":"In Mexico there are several types of cultivation; one of them is rainfed, which does not require irrigation infrastructure. The advantage of this system is that it is used in soils of low fertility, which receive inconstant and not very abundant rainfall. This model is used in Morelos, Guerrero and Oaxaca. This system produces more than one third of the national peanut production."},
                {"text":"There is also the case of Yucatan, where production is lower and consumption is essentially local. An important characteristic of the production in this state is that it is planted together with corn, squash or white beans, which avoids the wear of the land for planting. In this state, just over 300 tons of peanuts can be harvested per year. "},
                {"text":"However, the largest production is found in the states of Chihuahua, Sinaloa, Chiapas and Puebla, which together exceed 71 thousand tons of annual production, representing about 70% of national production according to the Ministry of Agriculture. Here, crop rotation is carried out to avoid the wear and tear of agricultural areas and work is continually being done to improve the quality of the product."},
                {"text":"It is estimated that peanuts are planted on more than 55 thousand hectares and the cultivation area increases, on average, a little more than 1.3% annually. In 2021 alone, it was estimated that national production would exceed 100 thousand tons, equivalent to 1,261 million pesos. In addition, we can highlight that local varieties are grown and that three improved varieties have been registered and two are in the process of being registered."},
                {"text":" Finally, its consumption is directly related to national gastronomy in recipes such as moles, sauces, cookies, bread, sweets, salads, etc., as well as in the different snacks that are marketed. We also found that there are different industries that produce oils, flour, ink, among other products. This allows us to see that peanuts are of great importance in Mexico. "},
                {"text":"That is why in Galdisa we are proud to be part of this important industry from the planting, production and distribution. "},
            ],
            "refs":[]            
        },            
        "historia-crema-cacahuate":{
            "image": blog26,
            "title":["Historia de la crema de cacahuate"],
            "desc": "La crema de cacahuate es una de las formas más deliciosas y fáciles para integrar este superalimento en nuestra dieta diaria. Ya sea complementando alguna colación o como parte del desayuno.",
            "pars":[
                {"text":["La crema de cacahuate es una de las formas más deliciosas y fáciles para integrar este superalimento en nuestra dieta diaria. Ya sea complementando alguna colación o como parte del desayuno. En nuestro blog podrás encontrar muchas recetas saludables y deliciosas. En este nuevo artículo te platicaremos todo sobre su historia. ¿Ya la conoces? Aunque se tiene evidencia de que los incas tenían un alimento a base de cacahuates molidosiguiendo los registros históricos, fue en Estados Unidos en donde por primera vez se utilizó la crema de cacahuate. Al principio, el cacahuate no era un producto tan importante para los norteamericanos, pues únicamente se empleaba para la producción de aceites e incluso que gran parte de lo que se cosechaba se utilizaba para alimentar al ganado."], className:"blog-page-par-first"},
                {"text":"Fue hasta la Guerra Civil cuando se le comenzó a dar mayor importancia a este superalimento, debido a que la comunidad se percató de que esta pequeña semilla brindaba muchísimos beneficios a los soldados. Ambos bandos alimentaban a los combatientes con cacahuates. Después de eso, se industrializó tanto la siembra como la cosecha, por lo que la población de las ciudades pudo consumirlos con regularidad."},
                {"text":"Si bien a finales del siglo XIX encontramos productos con cacahuates molidos, el primer anuncio de la venta de crema de cacahuete al público fue en la Feria Mundial de San Luis, EEUU en 1904. Antes de dicho evento, únicamente se tienen apuntes de productos que usaban cacahuetes triturados como proteína para pacientes con dificultad para masticar alimentos sólidos."},
                {"text":"Finalmente, durante las dos Guerras Mundiales, los cacahuates enteros y la crema de cacahuate fueron parte esencial de la alimentación de los aliados. De hecho, históricamente, se relaciona la popularización del sándwich con crema de cacahuate por imágenes de las fuerzas armadas comiéndolos. Lo que sí podemos asegurar es que es innegable que a partir de la segunda mitad del siglo XX, este alimento se popularizó a nivel mundial."},
                {"text":"La mejor forma de preparar este alimento es triturando cacahuates enteros hasta conseguir una pasta uniforme. Mientras menos ingredientes añadidos contenga, se tendrá un alimento más puro, completo y delicioso."},
            ],
            "refs":[]
        },
        "helado-de-cacahuate":{
            "image": blog27,
            "title":["Helado de cacahuate"],
            "desc": "Si al iniciar la época de calor buscas opciones saludables, deliciosas y refrescantes, tenemos la receta ideal para ti",
            "pars":[
                {"text":["Si al iniciar la época de calor buscas opciones saludables, deliciosas y refrescantes, tenemos la receta ideal para ti. Como te hemos comentado en nuestro blog, la crema de cacahuate es una de las formas ideales para incluir en nuestra dieta esta importante leguminosa y todos sus beneficios para la salud. "], className:"blog-page-par-first"},
                {"text":"Es por ello que te traemos esta receta para que puedas disfrutar de la crema de cacahuate de una forma más fresca. Ya sea que prefieras las paletas heladas o los conos, estamos seguros que lo disfrutarás."},
                {"text":"¡A refrescarnos!"},
                {"title":"Ingredientes",
                    "list":[
                        "400 g de leche condensada",
                        "400 ml de leche entera",
                        "250 g de crema de cacahuate",
                        "70 g de cacahuates tostados",
                        "½ plátano (opcional)"
                    ]
                },
                {"title":"Preparación", 
                    "list":[
                        "1. Corta o tritura los cacahuates tostados al tamaño que prefieras, si hay niños pequeños se recomienda añadir cacahuete triturado.",
                        "2. En un recipiente añade ambas leches (en caso de que prefieras una versión vegetariana puedes sustituirlas por leche de coco y cualquier otra leche vegetal). Mezcla hasta incorporarlas.",
                        "3. Añade lentamente la crema de cacahuate e incorpora con una batidora a la mezcla de leches. Puedes añadir la mitad de los cacahuates si disfrutas esa combinación de texturas. Si te gusta el sabor más dulce puedes agregar una o dos cucharadas de azúcar o la mitad de un plátano maduro.",
                        "4. Puedes elegir recipientes para paletas o un bowl si prefieres servir en conos de galleta. Mete el recipiente al congelador por al menos 6 horas.",
                        "5. Al momento de servir,  calienta en el microondas un poco de crema de cacahuate, añade los trozos de cacahuates y cubre tu helado para darle aún más  sabor."
                    ]
                },
                {"text":"Esperamos que esta rica receta te encante. Recuerda que en Galdisa puedes encontrar más recetas deliciosas e información sobre los cacahuates y sus múltiples beneficios. "},
                {"text":"¡Que lo disfrutes!"}
            ],
            "refs":[]
        },
        "wrap-pollo-cacahuate":{
            "image": blog28,
            "title":["Wrap de pollo con cacahuate"],
            "desc": "Si disfrutas de probar alimentos frescos y nutritivos, te traemos una deliciosa receta de nuestros amigos: Ponle energía a tu día.",
            "pars":[
                {"text":["Si disfrutas de probar alimentos frescos y nutritivos, te traemos una deliciosa receta de nuestros amigos: Ponle energía a tu día. Ya sea para un día caluroso o para un picnic, este wrap te encantará. Además, es ideal para cualquier dieta ya que incluye proteínas, verduras y una deliciosa salsa cuyo ingrediente estelar es el cacahuate.  Recuerda que incluirlo en tu dieta ayuda a mejorar la digestión y a aumentar la saciedad. ¡Esperamos que lo disfrutes!"], className:"blog-page-par-first"},
                {"title":"Ingredientes",
                    "list":[
                        "2 tazas de coles cortadas",
                        "1 taza de zanahoria rallada",
                        "½ pechuga de pollo frita cortada en tiras de pollo",
                        "½ taza de cacahuates",
                        "¼ de taza de miel",
                        "¼ de taza de aceite de oliva",
                        "¼ de taza de crema de cacahuate",
                        "6 tortillas para burrito",
                        "3 cucharadas de vinagre de arroz ",
                        "3 cucharadas de cilantro fresco picado",
                        "2 chiles serranos picados",
                        "1 diente de ajo picado",
                        "Sal y pimienta"
                    ]
                },
                {"title":"Preparación", 
                    "list":[
                        "1. Para preparar la salsa de cacahuate, en un bowl vierte la crema de cacahuate, aceite de oliva, miel, vinagre, chiles serranos, ajo y mezcla hasta integrar todos los ingredientes. Agrega la sal y pimienta antes de terminar.",
                        "2. En un segundo bowl mezcla el mix de coles, zanahoria, cacahuates, cilantro y una o dos cucharadas de la salsa que ya está preparada. ",
                        "3. Para servir toma una de las tortillas para burrito, coloca 2 o 3 cucharadas del mix de verduras, una cama de tiras de pechuga, dobla la tortilla hasta cerrar el wrap.",
                        "4. Corta el wrap a la mitad. Puedes mojar la punta en la salsa para aumentar su sabor o bañarlo directamente.",
                    ]
                },
                {"text":"Esperamos que disfrutes de esta deliciosa receta. Recuerda que en nuestro blog puedes encontrar mucho contenido y recetas fáciles de preparar en casa al igual que información relevante  sobre los cacahuates y otros de nuestros productos."},
            ],
            "refs":[]
        },
        "mitos-sobre-cacahuate":{
            "image": blog29,
            "title":["4 mitos sobre los cacahuates"],
            "desc": "En diversas ocasiones te hemos compartido las increíbles propiedades de los cacahuates y por qué deberías incluirlos en tu dieta",
            "pars":[
                {"text":["En diversas ocasiones te hemos compartido las increíbles propiedades de los cacahuates y por qué deberías incluirlos en tu dieta, pero aún es común escuchar a personas que desaconsejan su consumo, principalmente por desinformación o por confundir términos. Es por eso que te compartiremos algunos mitos y datos reales que te ayudarán a comprender mejor las propiedades de este superalimento."], className:"blog-page-par-first"},
                {"text":"Recuerda que hay una cantidad recomendada para que aproveches todos sus beneficios: aproximadamente 2 cucharadas al día o 30 piezas completas. Si combinas esta cantidad con una dieta balanceada, tu alimentación se verá beneficiada."},
                {"title":"Mito 1: No son un alimento completo", "text":"Debido a que la mayoría come cacahuates como botana, se puede asociar con dulces o comida poco nutritiva, pero no hay nada más alejado de la realidad: este alimento destaca por su alto contenido en proteínas, cerca de un 27%, y por su alto contenido en fibra que combinados dan energía y facilitan la digestión. Por lo que sus componentes lo hacen ideal para la colación o el desayuno."},
                {"title":"Mito 2: Aumenta el peso", "text":"Otro mito común es el de pensar que los cacahuates tienen un alto contenido de calorías y lo relacionan con el aumento de peso. Si bien todo alimento en exceso puede generar problemas para la salud, los cacahuates destacan por contener diversos tipos de grasas sanas sumado a su alto índice de vitaminas y minerales. Es por eso que se recomienda a personas vegetarianas o deportistas, pues aportan energía y ayudan a las defensas del cuerpo; también se ha descubierto que el consumo de cacahuates tiene un impacto favorable en la prevención de enfermedades del corazón."},
                {"title":"Mito 3: Sólo los cacahuates naturales son recomendables", "text":"Si bien es cierto que consumir cacahuates con el menor proceso posible es lo más recomendable, la crema de cacahuate es un excelente aliado para incluir a esta importante leguminosa en nuestra dieta. Se puede preparar de forma natural y sin ingredientes añadidos lo que permite que se conserven la mayoría de los nutrientes. Este aliado te dará los mismos beneficios que consumirlos sin procesar. "},
                {"title":"Mito 4: Sólo son aptos para los jóvenes o adultos", "text":"Relacionado con el tema anterior, muchos asocian este alimento con la niñez o la juventud, la realidad es que cualquier persona puede incorporarlos a su dieta. Incluso las personas con diabetes debido a sus múltiples ventajas, siempre siguiendo las recomendaciones de un especialista de la salud. Ya sea que se busque un alimento completo o que aporte los beneficios antes mencionados. "},
                {"text":"Esperamos que al derribar estos cuatro mitos, te animes a incluir a los cacahuates en tu dieta. Recuerda que en nuestro blog puedes encontrar recetas y datos importantes sobre este superalimento. "},
            ],
            "refs":[]
        },
        "historia-cacahuates-japoneses":{
            "image": blog30,
            "title":["Historia de los cacahuates japoneses Realmente ¿de dónde vienen?"],
            "desc": "¿Sabías que esta variedad de cacahuates en realidad no provienen de un país oriental?",
            "pars":[
                {"text":["Seguramente has disfrutado muchas veces de los cacahuates como dulce o botana. Ya sean salados, con caramelo, enchilados, con cáscara o los famosos cacahuates japoneses; pero ¿sabías que esta variedad de cacahuates en realidad no provienen de un país oriental? La historia de cómo surgió es un ejemplo de la versatilidad y adaptabilidad de esta legumbre, junto con la relación gastronómica de dos países."], className:"blog-page-par-first"},
                {"text":"Esta deliciosa botana tiene su origen en una receta llamada “mamekashi” que se prepara con semillas cubiertas por una capa de harina de arroz, condimentada con diferentes ingredientes. La preparación se originó en China y se popularizó en Japón."},
                {"text":"¿Quién fue el creador de esta deliciosa botana? Su nombre era Yoshihei Nakatani Moriguchi, un japonés que emigró a México para trabajar en un almacén llamado El Nuevo Japón, sin embargo, tuvo que cerrar debido a que el dueño fue deportado a su país."},
                {"text":"Yoshihei había formado una familia con una mujer llamada Emma que vivía en el barrio de La Merced. La pérdida de sus empleos los impulsó a crear un negocio de muéganos para sacar adelante a sus hijos; lamentablemente, no eran los únicos vendedores de la zona, por lo que comenzaron a comercializar una fritura de trigo, intentando ganar más clientes."},
                {"text":"Al poco tiempo se dieron cuenta de que el negocio necesitaba una receta diferente. Eso fue lo que motivó a Yoshihei para probar con el mamekashi, pero uno de los ingredientes más importantes no era fácil de conseguir: harina de arroz. Fue por eso que la sustituyó por harina de trigo sazonada con salsa de soya."},
                {"text":"Estos cambios impulsaron el negocio que se encontraba en la vecindad de la calle Carretones. Muchas personas hacían fila para comprar esta rica botana y no tardaron mucho en bautizarlos como “cacahuates japoneses”. El éxito de la botana llevó al matrimonio a prepararla en casa y salir a vender su producto envuelto en bolsas de celofán. Poco tiempo después, las dulcerías del mercado de La Merced comenzaron a pedirles grandes cantidades para revender."},
                {"text":"Debido a que la familia no patentó la receta, muchos comerciantes empezaron a realizar su producción de forma independiente. Es por eso que en los años 80 se dio un aumento en el consumo y venta del cacahuate japonés; curiosamente, a nivel mundial suele nombrarse como cacahuate mexicano ya que no se le relaciona con el país nipón."},
                {"text":"Así que la próxima vez que disfrutes de esta deliciosa botana no olvides agradecer a su creador que logró la fusión de dos culturas por su gastronomía. Pero al fin de cuentas, se puede decir que los cacahuates japoneses fueron creados en tierra mexicana. "},
            ],
            "refs":[]
        },
        "cacahuate-mexicano-mundo":{
            "image": blog31,
            "title":["El papel del cacahuate mexicano en el mundo"],
            "desc": "Es innegable que el cacahuate ocupa un lugar muy importante en la gastronomía mundial",
            "pars":[
                {"text":["Es innegable que el cacahuate ocupa un lugar muy importante en la gastronomía mundial. Si bien lo asociamos mayormente con los snacks, su importancia va más allá pues esta deliciosa leguminosa se consume en un sin fin de variedades y platillos. Entender su importancia y conocer en dónde se ubica México al hablar de cacahuates nos permitirá saber porqué es tan importante este fruto seco para el país."], className:"blog-page-par-first"},
                {"text":"En el mundo se producen cerca de 25.7 millones de toneladas de cacahuates sin cáscara y, en promedio, se exporta cerca del 11% ya que cada región cuenta con diferentes variedades de este producto. Esto propicia el intercambio comercial y mejora las relaciones económicas. "},
                {"text":"Desde hace algunos años, el mayor productor de cacahuate es China con cerca del 39% de la producción mundial; seguido de la India y Nigeria con el 20% y Estados Unidos con el 5%. Si bien, la producción mexicana no significa un número grande, es destacable que se ubica entre los mayores productores y exportadores del mundo."},
                {"text":"México producía cerca de 60 mil toneladas de cacahuates anualmente hace una década y se exportaban 11 mil toneladas mientras que se importaban 111 mil toneladas, aproximadamente. Esto cambió en los últimos años debido a la implementación de mejoras en la siembra y en la recolección."},
                {"text":"Actualmente, México supera la producción de las 100 mil toneladas anuales. Si bien puede parecer que la diferencia es muy grande, debemos entender que México es un país que cada año aumenta su producción y mejora la calidad de sus cacahuates. Esto propicia que los productores obtengan un mayor ingreso y la industria cacahuatera crezca a la par de la demanda de cacahuate mexicano."},
                {"text":"Otra buena noticia es que en el país se cultivan variedades locales de las cuatro botánicas presentes. Por lo que se pueden encontrar tanto cacahuates con características diferentes como aquellos que cumplen con normas internacionales. Además, hoy día se han encontrado tres variedades mejoradas y dos están en proceso de registro."},
                {"text":"Aunque el cacahuate transformado en botanas tiene un lugar importante, el de origen mexicano se utiliza para producir mantequilla, harina y aceite. Estos productos cada año son más solicitados por sus beneficios y por usuarios que buscan opciones más saludables. Es por eso que también se importa una gran cantidad de cacahuates de todo el mundo. La obtención de productos procesados también impacta satisfactoriamente a la industria."},
                {"text":"Esperamos que este panorama de la industria te invite a saber más y a disfrutar orgullosamente del cacahuate de nuestro país. Recuerda que Galdisa se ocupa de los cacahuates desde su siembra hasta que llegan a las manos de sus clientes y consumidores."},
            ],
            "refs":[]
        },
        "principales-consumidores-cacahuate":{
            "image": blog32,
            "title":["Principales países consumidores de cacahuate"],
            "desc": "El cacahuate es muy popular prácticamente en todo el mundo",
            "pars":[
                {"text":["El cacahuete, a veces conocido como maní, se considera el décimo tercer cultivo alimenticio más importante del mundo y la cuarta fuente de aceite comestible. También es una importante fuente de proteínas vegetales. Aunque son de pequeño tamaño, los cacahuetes desempeñan un papel nutricional vital e importante y tienen increíbles beneficios para la salud."], className:"blog-page-par-first"},
                {"text":"El cacahuate es muy popular prácticamente en todo el mundo. Su consumo es muy popular en países como China, Estados Unidos e India, destacan también países como Israel, Arabia Saudita y Malasia con los mejores rendimientos productivos 6.85, 4.00, 3.50 toneladas por hectárea, respectivamente y por ello son los países donde más se consume y produce cacahuate en el mundo."},
                {"title":"Top 10 de paises con mayor consumo de cacahuate", 
                "text":<table style={{width:'80%'}}>
                    <tr>
                        <th style={{backgroundColor:"green", color:"white"}}>Ranking</th>
                        <th style={{backgroundColor:"green", color:"white"}}>País</th>
                        <th style={{backgroundColor:"green", color:"white"}}>Consumo anual en toneladas</th>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>China</td>
                        <td>17,371,242</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>India</td>
                        <td>5,627,940</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Nigeria</td>
                        <td>3,000,025</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>Estados Unidos</td>
                        <td>2,313,684</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>Indonesia</td>
                        <td>1,310,520</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>Vietnam</td>
                        <td>669,618</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>Brasil</td>
                        <td>329,803</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>México</td>
                        <td>203,355</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>Sudáfrica</td>
                        <td>136,168</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>Canadá</td>
                        <td>95,797</td>
                    </tr>
                </table>},
                {"text":"En México, el cacahuate se siembra principalmente en alrededor de 62,000 hectáreas, con una producción promedio anual de 60,000 toneladas, y con un valor de 653 millones de pesos. Los estados de Sinaloa, Puebla, Chiapas, Oaxaca, Chihuahua y Guerrero, en conjunto acumulan 84% de la superficie nacional dedicada a este cultivo. El consumo de cacahuate en México es de alrededor de 160,000 toneladas anualmente: 145,000 toneladas se destinan al consumo humano; 6,000 toneladas, al procesamiento industrial; 8,000 toneladas, a otros usos y 1,000 toneladas, a semilla para siembra."},
                {"text":["Si eres de la industria o eres distribuidor de cacahuate te invitamos a  conocer los productos de Galdisa. Para más información consulta:",<a className="blog-link" rel="noopener noreferrer" href="https://www.galdisa.com/">https://www.galdisa.com</a>]},
            ],
            "refs":[]
        },
        "datos-curiosos-jamaica":{
            "image": blog33,
            "title":["Datos curiosos de la jamaica"],
            "desc": "Alguna vez te has preguntado, ¿cómo una flor puede proveernos de una de las bebidas más refrescantes y tradicionales?",
            "pars":[
                {"text":["Alguna vez te has preguntado, ¿cómo una flor puede proveernos de una de las bebidas más refrescantes y tradicionales?"], className:"blog-page-par-first"},
                {"text":"La jamaica es una flor con cinco pétalos, de sabor ácido y que, una vez hervida, desprende un color rojo intenso. En México se utiliza principalmente para elaborar infusiones."},
                {"text":"Desde la antigüedad, diversas culturas como los incas y egipcios la han empleado por sus propiedades benéficas para la salud, lo cual ha sido corroborado por la ciencia en los últimos años."},
                {"text":"Si al igual que nosotros te encanta el agua de la flor de jamaica, no te pierdas estos datos curiosos:"},
                {"list": [
                    "1. Su origen es Africano.",
                    "2. Contiene unos pigmentos denominados antocianinas, que por su actividad antioxidante, ayudan a mejorar el organismo contra la oxidación celular, además, tienen efectos anticancerígenos.",
                    "3. Ayuda en la limpieza de órganos como el hígado y los riñones.",
                    "4. Investigaciones científicas han comprobado que la flor de Jamaica tiene un contenido significativo de las vitaminas A y C, una gran cantidad de minerales, ácido cítrico y málico, entre muchos otros componentes.",
                    "5. El tallo de la planta de jamaica es tan resistente que en algunas regiones del mundo sustituyen al yute con las fibras del tallo, esto para crear artesanías.",
                    "6. Las infusiones de la flor ayudan a disminuir considerablemente las inflamaciones de varias partes de nuestro cuerpo por lo cual es muy recomendada sobre todo para las relacionadas con el ciclo menstrual femenino.",
                    "7. Se utiliza para combatir desde problemas de la piel como el acné hasta anemia e infecciones respiratorias."
                ]},
                {"text":"¿Verdad que es asombrosa? Además, es deliciosa. Te invitamos a conocer éste y más productos que puedes adquirir con Galdisa."},
            ],
            "refs":[]
        },
        "rosca-reyes-almendras":{
            "image": blog34,
            "title":["Rosca de Reyes con almendras, nueces y arándanos"],
            "desc": "En este blog te compartiremos una deliciosa receta para que sorprendas a tus invitados y deleiten su paladar",
            "pars":[
                {"text":["¿Estamos de acuerdo en que uno de los días más mágicos del inicio de año es el 6 de enero? La llegada de los Reyes Magos es la ocasión perfecta para reunir de nuevo a la familia, contar sobre nuestros regalos, vacaciones y sobre todo disfrutar. En este blog te compartiremos una deliciosa receta para que sorprendas a tus invitados y deleiten su paladar con la combinación perfecta de sabor y dulzura."], className:"blog-page-par-first"},
                {"text":"Aquí la receta. ¡Esperamos que la disfrutes!"},
                {"title":"Ingredientes",
                "list":[
                    "450 g de harina",
                    "80 g mantequilla",
                    "150 g de azúcar",
                    "2 huevos",
                    "150 ml de agua templada",
                    "35 ml de leche",
                    "2 cáscaras de naranja en ralladura",
                    "30 g de levadura",
                    "2 cucharaditas de agua de azahar",
                    "Sal",
                    "Arándanos deshidratados",
                    "Almendras",
                    "Nueces",
                    "Azúcar granulada"
                ]},
                {"title":"Preparación:",
                "list":[
                    "Agrega en un bowl grande 400 g de harina y en el centro realiza  un pequeño hueco para agregar el azúcar",
                    "Disuelve la levadura en el agua templada y viértela con la leche, la ralladura de naranja, el agua de azahar y la mantequilla. Mézclalo todo.",
                    "Añade uno de los huevos. Después una pizca de sal y amasa.",
                    "Una vez mezclados todos los ingredientes, coloca la masa sobre una superficie en la que previamente hayas esparcido harina. Sigue amasando hasta que consigas que te quede una masa elástica y no pegajosa. Con esa masa forma una bola grande.",
                    "Cuando la tengas, haz un agujero en el centro. Estira la masa poco a poco hasta conseguir la forma de la rosca.",
                    "Precalienta el horno a 200º.",
                    "Coloca la rosca sobre la bandeja del horno y déjala aproximadamente unos 30 min.",
                    "Bate el otro huevo y con éste, pinta la rosca poco a poco.",
                    "Decora tu rosca con las almendras, nueces, arándanos y el azúcar granulada.",
                    ""
                ]},
                {"text":"Esperamos que te deleites con esta deliciosa receta."},
                {"text":"Recuerda que en nuestro blog puedes encontrar mucho contenido y recetas fáciles de preparar en casa, al igual que información relevante sobre cacahuate y otros de nuestros productos."},
                {"text":"¡Felices fiestas y Día de Reyes Magos!"}
            ],
            "refs":[]
        },
        "maiz-economia-nutricion":{
            "image": blog35,
            "title":["El maíz, su impacto económico y valor nutricional."],
            "desc": "Fue cultivado por primera vez por los pueblos originarios del Eje Neovolcánico Mexicano (Tehuacán) hace aproximadamente 10.000 años",
            "pars":[
                {"text":["El maíz es el tercer cereal más cultivado en el mundo, y después del trigo y el arroz es la base de la alimentación de muchos países. Fue cultivado por primera vez por los pueblos originarios del Eje Neovolcánico Mexicano (Tehuacán) hace aproximadamente 10.000 años. Los olmecas y los mayas también lo utilizaban."], className:"blog-page-par-first"},
                {"text":"México se ha convertido en el principal importador de maíz en el mundo, ya que del total del consumo nacional que es de casi 45 millones de toneladas al año, en el país se producen entre 27 y 28 millones de toneladas, el resto se importa a países como EEUU y Argentina principalmente."},
                {
                    "title":"Impacto económico:", 
                    "text": "Para México, el maíz es el cultivo de mayor importancia, ya que constituye una de las actividades económicas más importantes del sector rural, no sólo en términos de uso de suelo, sino también en el empleo y en el suministro de alimentos a la población rural y urbana de bajos recursos. A nivel nacional se identifican aproximadamente 2 millones de productores dedicados al cultivo de maíz y el 85% de ellos lleva a cabo su labor en predios cuya extensión es menor o igual a 5 hectáreas."
                },
                {
                    "text":"En México se consume más maíz del que se produce, el déficit estimado es de 43% en la producción, esto a pesar de ser el grano más importante para la alimentación de los mexicanos. Con la implementación del Tratado de Libre Comercio de América del Norte (TLCAN) la dependencia de maíz en México creció del 7% en 1990 al 34% en recientes años."
                },
                {
                    "text":"El maíz, como todos los alimentos, debe consumirse en cantidades adecuadas y como parte de una dieta equilibrada. Este cereal es una opción ideal para los vegetarianos, no contiene gluten y tiene otras propiedades y beneficios para la salud:",
                    "list": [
                        "Antioxidantes: El maíz es rico en antioxidantes, los cuales ayudan a combatir a los radicales libres y previenen el envejecimiento celular.",
                        "Saciante: Rico en fibra e hidratos de carbono por lo que ayuda a controlar el apetito.",
                        "Bueno para el cerebro: Rico en ácido fólico y otras vitaminas, el maíz es un aliado de diversas funciones cerebrales.",
                        "Cuida la salud cardiovascular: La vitamina B1 que contiene el maíz disminuye la homocisteína y, por lo tanto, reduce también el riesgo a sufrir un ataque cardiovascular.",
                        "Indicado para las mujeres embarazadas: Rico en ácido fólico, es muy recomendable si tienes planes para embarazarte o incluso si te encuentras  en la etapa de lactancia.",
                        "Mantiene los huesos fuertes: Gracias a la presencia de calcio entre sus nutrientes."
                    ],

                },
                {
                    "text":"Las variedades de maíz rojo, naranja y púrpura contienen potentes antioxidantes conocidos como carotenoides. Los carotenoides son populares por su contribución a la lucha contra los radicales libres, que pueden ser responsables del daño celular."
                },
                {"title":"Valor nutricional de 100 gramos de maíz:",
                "list":[
                    "Calorías: 86 g",
                    "Carbohidratos: 19 g",
                    "Grasas: 1,2 g",
                    "Proteínas: 3,2 g",
                    "Ácido fólico (vitamina B9): 46 μg",
                    "Potasio: 270 mg",
                ]},
                {"text":"El maíz es un alimento muy completo que contiene muchas vitaminas y minerales que favorecen nuestro metabolismo y a su vez es parte fundamental para la alimentación de los mexicanos. Además de que tiene gran valor cultural, social y económico para nuestro país.  Actualmente contamos con un total de 59 variedades de maíz criollo, es decir, variedades nativas y el estado de Sinaloa es el líder en su producción aportando más de 5 millones de toneladas anuales."},
                {"text":["Puedes adquirirlo en: ",<a className='blog-link' rel="noopener noreferrer" target="_blank" href="/">https://www.galdisa.com/</a>,". Esperamos que hayas disfrutado de este blog y te animes a saber más y disfrutar orgullosamente del maíz que nos brindan las tierras de nuestro país."]},
            ],
            "refs":[]
        },
        "ventajas-maquilar-productos":{
            "image": blog36,
            "title":["Ventajas maquilar tus productos con una empresa especializada que te ayudarán al crecimiento y rentabilidad de tu negocio"],
            "desc": "",
            "pars":[
                {"text":["Actualmente, para lograr operar y triunfar en los mercados altamente competitivos (como lo son México y Estados Unidos), es necesario reducir costos y aumentar la productividad. Una de las  alternativas más efectivas para lograrlo es contratar un servicio de maquila y aquí te dejaremos algunas ventajas y tips que sin duda te ayudarán en este proceso."], className:"blog-page-par-first"},
                {"text":"Desde los años ochenta, la industria de la maquila en México presentó gran evolución y un acelerado crecimiento. Ésta industria se convirtió en uno de los principales medios de inserción internacional de México y además se utilizó como medio motor para modernizar la producción."},
                {
                    "text": "La cantidad de empresas maquiladoras en México ha permanecido en constante crecimiento, pues al contar con costos de mano de obra bastante competitivos, las industrias internacionales han migrado sus producciones a nuestro país. En la industria alimenticia, las empresas maquiladoras se encargan de participar en la selección, preparación, empaque, enlatado y distribución de los productos."
                },
                {
                    "text":"Algunas ventajas a destacar  para contratar un maquilador son:"
                },
                {
                    "list": [
                        "No necesitas invertir en maquinaria especializada, permitiéndote ahorrar grandes cantidades de recursos.",
                        "Las empresas maquiladoras, generalmente cuentan con la experiencia necesaria en el tratamiento de los productos, así como certificaciones que avalan todos sus procesos de seguridad e inocuidad. En especial cuando se trata de productos alimenticios.",
                        "Los permisos y licencias de elaboración de algunos tipos de productos (alimentos, bebidas, medicinas…) ya las tiene el maquilador por lo que no hay que tramitar estos permisos.",
                    ],

                },
                {
                    "title":"5 Aspectos importantes a considerar antes de contratar una maquiladora.",
                    "list":[
                        "1. Busca a un fabricante que invierta continuamente en nuevos equipos y tecnología. En otras palabras, busca a un fabricante que tenga una buena salud financiera y esté dispuesto a mejorar sus equipos para brindarte un servicio eficiente y de la más alta calidad.",
                        "2. Antes de firmar, asegúrate de que el contrato contenga cláusulas específicas de propiedad intelectual. Un buen maquillador tendrá procedimientos instaurados y velará  para evitar el robo o la fuga de cualquier secreto industrial.",
                        "3. Asegúrate de que el acuerdo de suministro incluya las necesidades y requisitos de tu negocio ampliamente detallados, así como los del fabricante. Asegúrate de colocar cláusulas de incumplimiento.",
                        "4. Busca una empresa seria que tenga una historia, valores y personal altamente comprometido que la respalde."
                    ]
                },
                {"text":"Considerando la globalización y la especialización que cada vez se da más en la industria alimenticia, es muy probable que la maquila siga siendo una participante activa e importante en los procesos productivos."},
                {"text":["Maquila con Galdisa, contamos con experiencia, conocimiento y calidad para tus productos. Visita nuestro sitio web y conoce más sobre nosotros.",<a className='blog-link' rel="noopener noreferrer" target="_blank" href="/">https://www.galdisa.com/</a>]},
            ],
            "refs":[]
        },
        "ventajas-granel-emprendimiento":{
            "image": blog37,
            "title":["Ventajas de comprar a granel para tu emprendimiento."],
            "desc": "",
            "pars":[
                {"text":["Galdisa es una empresa internacional del sector agroalimentario que se dedica a la siembra, fabricación y distribución de productos como ingredientes, botanas, derivados del cacahuate, granos y semillas para compañías/empresarios que producen y comercializan alimentos. Comprar a granel con Galdisa te garantiza el mejor sabor y calidad en todos y cada uno de nuestros productos."], className:"blog-page-par-first"},
                {"title":"4 ventajas principales de comprar granel para tu emprendimiento ",
                    "list": [
                        [<span className='texto-bold texto-green'>1. Las compras a granel nos permiten reducir muchísimos envases y empaques:</span>," El 39.9% del plástico que se genera en el mundo va destinado a la fabricación de envases. Por ello, comprar a granel ayuda al ahorro de los recursos."],
                        [<span className='texto-bold texto-green'>2. Abasto garantizado:</span>," Una ventaja sólida es el hecho de que al comprar tus productos a granel con Galdisa siempre tendrás abasto y productos en tu inventario con la seguridad de que son productos frescos, de calidad y duraderos."],
                        [<span className='texto-bold texto-green'>3. Ahorras económicamente:</span>," Al comprar a mayor volumen , se obtiene un beneficio económico que te permitirá dar un mejor precio a tus clientes, además cuando compras a granel, únicamente compras el producto sin marca ni empaques."],
                    ],
                },
                {"text":"Actualmente las compras a granel han cobrado relevancia entre las familias mexicanas derivado de la situación económica que se vive en donde el aumento de precios en los productos de consumo masivo ha sido mayor que la inflación. El 63 % de las personas declaran haber adquirido productos a granel principalmente por el precio. Lo anterior pone una gran oportunidad sobre la mesa , pues tu negocio puede adquirir los productos de Galdisa para revenderlos en las tiendas de abarrotes de tu comunidad o incluso para generar productos con tu marca que sean más competitivos que los de la competencia en tema de precio/calidad."},
                {
                    "title": "¿Cómo vender a granel?",
                    "list":[
                        [<span className='texto-bold texto-green'>1. Encuentra a tus consumidores :</span>," Busca  dentro de tu comunidad a quién podrías vender el producto, genera acuerdos y brinda un excelente servicio. Esto te permitirá siempre tener una alta rotación de tu inventario."],
                        [<span className='texto-bold texto-green'>2. Ofrece una ficha técnica:</span>," El consumidor debe tener a la mano y en todo momento la información referente a ellos, como si tuviera la etiqueta de un producto envasado."],
                        [<span className='texto-bold texto-green'>3. Ofrece recomendaciones al cliente:</span>," Aconseja a tus clientes sobre los productos que puede comprar y saca productos especiales de temporada. Intenta hacer diferentes mezclas de semillas."],
                        [<span className='texto-bold texto-green'>4. Abre tu tienda en línea :</span>," El hecho de que ahora se puedan comprar productos a granel por Internet supone un incentivo para todos aquellos que buscan productos a precios accesibles sin tener que desplazarse de casa."],
                    ]
                },
                {"text":"Considerando la globalización y la especialización que cada vez se da más en la industria alimenticia, es muy probable que la maquila siga siendo una participante activa e importante en los procesos productivos."},
                {"text":["Compra tus productos a granel con Galdisa, contamos con experiencia, conocimiento y calidad para los productos. Hoy es un buen día para empezar tu negocio. Visita nuestro sitio web y conoce más sobre nosotros. ",<a className='blog-link' rel="noopener noreferrer" target="_blank" href="/">https://www.galdisa.com/</a>]},
            ],
            "refs":[]
        },
        "cacahuate-alimento-futuro":{
            "image": blog38,
            "title":["Los Cacahuates como el alimento del futuro."],
            "desc": "",
            "pars":[
                {"text":["Se prevé que la población del planeta alcanzará los nueve mil millones de habitantes en 2050. Por lo que la incorporación de prácticas agrícolas más eficientes y alimentos de origen sostenible es cada día más vital. Tenemos que comenzar a pensar sobre las necesidades de una población en crecimiento, cómo podemos mejorar la nutrición a través de prácticas de reproducción avanzadas, y cómo podemos evitar que los alimentos se desperdicien. Es justo ahí en dónde entra nuestro mejor aliado para la alimentación del futuro: El cacahuate."], className:"blog-page-par-first"},
                {"text":"Esta semilla es uno de los alimentos más populares del mundo, fácil de encontrar en Asia, África, Sudamérica y en muchos otros territorios. También es uno de los alimentos más ricos en nutrientes, ya que es alto en  proteínas (7 gramos por onza) y es una excelente fuente de fibra, grasas y más de 30 vitaminas y minerales."},
                {"text":"Nuestro organismo necesita las proteínas de los alimentos que ingerimos para fortalecer y mantener los huesos, los músculos y la piel,  éstas son esenciales para el crecimiento y reparación de los tejidos. Si se agregarán más cacahuates y leguminosas a las dietas de las personas de países subdesarrollados podríamos mejorar la alimentación y combatir la hambruna y desnutrición desde otro ángulo."},
                {"text":"Un mapa de la composición genética del cacahuate podría conducir a la producción de uno más perfecto, con mayor potencial de crecimiento y mejor perfil nutricional para alimentar a la creciente población mundial."},
                {"text":"Desde el punto de vista de la sustentabilidad, los cacahuates también son un excelente aliado ya que consumen mucha menos agua que otros frutos secos. Tienen una huella hídrica reducida debido a sus regiones de cultivo específicas (más de la mitad de los campos de cacahuetes dependen sólo del agua de lluvia), su pequeña estructura y su crecimiento subterráneo."},
                {"text":"Los cacahuates tienen un impacto ambiental mucho menor que otros cultivos, como la carne de res y la soja. Esto se debe a que su producción requiere de menos fertilizantes, lo que a su vez ayuda a mejorar la calidad del suelo."},
                {"text":"Se espera que los cultivadores de produzcan más en la misma superficie de tierra, creando así eficiencia y sostenibilidad para las próximas décadas. Gracias a un conocimiento claro de su ADN, los científicos creen que los cacahuates del mañana podrán ser aún más nutritivos y más resistentes a las sequías y a las condiciones climáticas extremas."},
                {"text":["En GALDISA puedes encontrar este alimento en diferentes presentaciones desde crema de cacahuate, cacahuate blanqueado, triturado, con chile y más. Conoce todo sobre nosotros en: ",<a className='blog-link' rel="noopener noreferrer" target="_blank" href="/">https://www.galdisa.com/</a>]},
            ],
            "refs":[{
                "text":"FDA. Guía de alimentación saludable:",
                "link":"https://www.fao.org/newsroom/detail/un-report-global-hunger-SOFI-2022-FAO/es"
            },
            {
                "text":"OMS. Alimentación saludable",
                "link":"https://www.who.int/es/news-room/fact-sheets/detail/healthy-diet"
            },
            {
                "text":"Estudio sobre el impacto ambiental de los cultivos de cacahuates:",
                "link":"https://www.sciencedirect.com/science/article/pii/S030691921830174X"
            },
            {
                "text":"FAO. Cultivo de cacahuates sostenibles:",
                "link":"http://www.fao.org/3/y5032s/y5032s05.htm"
            }
            ]
        },                                        
    },
}