import React from 'react';
export default {
    en:{
        corporativas: 'Corporate office:',
        quote: 'Request a quote',
        nombre: 'Name',
        industria: 'Industry',
        mail:'E-mail',
        producto:'Product of interest',
        volumen:'Volume and presentation',
        mensaje:'Message',
        telefono:'Phone number',
        enviar:'Send',
        thanks_message:'Thank you for writing to us. We will contact you shortly.',
        error_message:'An error ocurred processing your request. Please, try again later.',
        requiere_certificacion:'Is certification required?',
        yes:'Yes',
        no:'No',
        certificacion:'Specify which:'

    },
    es:{
        corporativas: 'Oficinas corporativas:',
        quote: 'Cotización',
        nombre: 'Nombre',
        industria: 'Industria',
        mail: 'E-mail',
        telefono:'Número telefónico',
        producto: 'Producto de interés',
        volumen: 'Volumen aproximado y presentación',
        mensaje: 'Mensaje',
        enviar: 'Enviar',
        thanks_message:'Gracias por escribirnos. Te contactaremos en breve.',
        error_message:'Ocurrió un error procesando tu solicitud, por favor intenta de nuevo más tarde.',
        requiere_certificacion: '¿Requiere Certificación?',
        yes:'Si',
        no:'No',
        certificacion:'Especificar cuál:'
    }
};