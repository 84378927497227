import React from 'react';
export default {
    en:{
        banner:`Quality foods that brighten up life`,
        todos_productos:`Discover all of our products`,
        services_title:`Services`,
        our_promise: ['Our', <br></br>, 'promise'],
        promise_text:`At Galdisa we work to offer our customers a differentiated and valuable proposal, based on quality, innovation, efficiency, sustainability and continuous improvement. In such a way that we guarantee the best product and the best flavor which is what our customers expect and deserve.`,
        ventajas_title: `Competitive advantages`,
        goldfritz:['Wholesale and retail sales'],
        more:'More',
        moreProducts:'See products',
        productos_title:`Products`,
        botanas: `Snacks`
    },
    es:{
        banner: `Alimentos de calidad que alegran la vida`,
        todos_productos:`Ver todos los productos `,
        services_title:`Servicios`,
        our_promise:['Nuestra',<br></br>,'promesa'],
        promise_text:`Nos comprometemos a ofrecer el mejor sabor y calidad de todos nuestros productos porque es lo que esperan y merecen nuestros clientes.`,
        ventajas_title:'Ventajas competitivas',
        goldfritz:['Venta' , <br/>, 'mayoreo y menudeo.'],
        more:'Ver más',
        moreProducts:'Ver productos',
        productos_title:`Productos`,
        botanas: `Botanas`
    }
};