import React from 'react';
import './App.css';

class Separador extends React.Component{
    render(){
        return(
            
            <div className="Separador">
<hr></hr>
</div>)}}
export default Separador;