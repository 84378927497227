import React from 'react';
import './App.css';
import {Link} from 'react-router-dom';
class Concepto extends React.Component{
    
    render(){
       if(this.props.estilo!=="cuadrado"){
        return(                        
        <div className="Banner2rows">         
            <div className="Banner2cols" >
                <div className="LineaBlanca"></div>
                <div className="Concepto">
                    <div className="Circulo"style={{backgroundImage:'url('+this.props.imagen+')'}} >{this.props.nombre}</div>                                                        
                </div>
                <div className="LineaBlanca"></div>    
            </div>
            <div>
            {this.props.titulo}
            <p>{this.props.descripcion}<br></br>
                    {this.props.link}</p>
            </div>
        </div>)
         }
         else
         {
             return(            
                 <div className="Concepto">
                     <div className="Cuadrado"><br></br></div>
                         <p key="2"><b>{this.props.nombre}</b><br></br>
                         {this.props.descripcion}<br></br>
                         {this.props.link}</p>
                 </div>)
         }    
    }}

export default Concepto;