import React from 'react';
import './App.css';

let timeout;
class BannerHeader extends React.Component {

    constructor(props){
        super();
        this.state = {
            banners: props.banners,
            selectedBanner: props.selected || 0
        };

    }

    updateSlide(){
        let newSelection = this.state.selectedBanner + 1;
        newSelection %= this.state.banners.length;
        clearTimeout(timeout);
        this.setState({
            selectedBanner: newSelection
        });
        timeout = setTimeout(()=>{
            this.updateSlide();
        }, 10000);
    }

    selectBanner(banner){
        console.log("Selecting banner  " + banner);
        clearTimeout(timeout);
        this.setState({
            selectedBanner: banner
        });
        timeout = setTimeout(()=>{
            this.updateSlide();
        }, 10000);
    }

    componentDidMount(){
        timeout = setTimeout(() => {
            this.updateSlide();
        }, 10000);
    }

    componentWillUnmount(){
        clearTimeout(timeout);
    }
    componentDidUpdate(prevProps, prevState){
        if(this.props.selectedLanguage !== prevProps.selectedLanguage){
            console.log(this.props.banners[0].text)
            this.setState({
                banners: this.props.banners,
            });
        }
    }
    render(){
        let bolitas=<div className="selectors">
            {
                this.state.banners.map((banner, id)=>{
                    return <div key={"selector-"+id} onClick={()=>{this.selectBanner(id)}} className={"selector " + this.state.selectedBanner === id ? "active" : "" }>
                        <button className={"selector-dot " + (this.state.selectedBanner === id ? "dot-active": "")}>&nbsp;</button>
                    </div>
                })
            }
        </div>    
        return(
            <div id={this.props.id} className={"Header " + this.props.className} style={{width:'100%', position:'relative', ...this.props.style}} >
                {this.state.banners[this.state.selectedBanner].overlay && <div style={{width:'100%', height:'100%', position:'absolute',top:'0', left:'0', backgroundColor:'rgba(150,150,150,0.40)'}}></div> }
                <img alt="Banner" style={{width:'100%', height:'100%', objectFit:'cover'}} src={this.state.banners[this.state.selectedBanner].imagen} />
                {this.state.banners[this.state.selectedBanner].text}
                {this.state.banners.length > 1 && bolitas}
            </div>
        )
    }
}
export default BannerHeader;
