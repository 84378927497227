import React from 'react';
import './App.css';


class Boton extends React.Component{
    constructor(props){
        super(props)
        this.handleClick = this.handleClick.bind(this);       
    }
    static defaultProps ={
        texto:"Texto default"        
      }      
      
    handleClick=
      function(texto2) {
        //console.log('boton '+texto2);
        this.props.clickHandler(texto2);}
       
     render(){
        
          if (this.props.posicion==undefined){            
            return(
                <a className={"transparencia " + this.props.classes} href={this.props.link}
            onMouseOver={()=>this.handleClick(this.props.detalle)}
            onMouseOut={()=>this.handleClick(" ")}
                >
                {this.props.texto}</a>
            )}
            else{
                var posicion = "{float:"+this.props.posicion+"}";            
        return(
        <a className="LinkButton"  href={this.props.link} style={posicion}>
        {this.props.texto}</a>
        )}}
      }

export default Boton;