import React from 'react';
import './App.css';


class Certificaciones extends React.Component{
render(){
return(
<div style={{width:"100%"}}>
<br></br><h1>Certifications</h1><br></br>Aqui van logos varios<br></br> <br></br><br></br><br></br><br></br>                  
</div>)
}};

export default Certificaciones;