import React from 'react';
import '../App.css';
import {Link} from 'react-router-dom';
import BannerPrincipal from '../BannerPrincipal.js';
import Cuadricula from '../Cuadricula.js';
import BannerH from '../BannerHorizontal.js';
import Blog from '../Blog.js';
import SoloTexto from '../SoloTexto';
import BarraOpciones from '../BarraOpciones.js';
import Cotizar from '../Cotizar';
import Siembra from '../images/1x/galdisa_icono_siembra.png';
import ProveedorIngredientes from '../images/1x/galdisa_icono_proveedor.png';
import ProdMedida from '../images/1x/galdisa_icono_productos.png';
import MarcaBlanca  from '../images/1x/galdisa_icono_marca.png';

import home_naturales from '../images/1x/productos_home_ingredientes.jpg';
import home_ingredientes from '../images/1x/productos_home_naturales.jpg';
import home_botanas from '../images/1x/productos_home_botanas.jpg';
import home_otros from '../images/1x/productos_home_otros.jpg';

import video_home_high from '../video/galdisa_home.mp4';
import video_home_low from '../video/galdisa_home_small.mp4';

import fondoservicios from '../images/1x/galdisa_servicios_bg.jpg';
import prodvarios from '../img_productosvarios.jpg';
import goldfritz from '../images/1x/img_goldfritz.jpg';

import blog28 from '../images/1x/galdisa_blogImg28.jpg';
import blog29 from '../images/1x/galdisa_blogImg29.jpg';
import blog30 from '../images/1x/galdisa_blogImg30.jpg';
import blog31 from '../images/1x/galdisa_blogImg31.jpg';
import blog32 from '../images/1x/galdisa_blogImg32.jpg';

import ventajasbg from '../images/1x/galdisa_ventajas_bg.jpg';

import dict from '../dict/Home.js';

import blogs from '../data/blogs';

class Home extends React.Component{

    constructor(props){
        super(props);
    }
    
    servicios={
        es: [
            {key:1, nombre:'', titulo:['Programa',<br></br>,'de siembra'],imagen:Siembra},
            {key:2, nombre:'', titulo:['Proveedor',<br></br>,'de ingredientes'],imagen:ProveedorIngredientes},
            {key:3, nombre:'', titulo:['Productos',<br></br>,'a la medida'],imagen:ProdMedida},
            {key:4, nombre:'', titulo:['Marca blanca',<br></br>],imagen:MarcaBlanca}
        ],
        en: [
            {key:1, nombre:'', titulo:['Grower',<br></br>,'program'],imagen:Siembra},
            {key:2, nombre:'', titulo:['Ingredients',<br></br>,'supplier'],imagen:ProveedorIngredientes},
            {key:3, nombre:'', titulo:['Custom made',<br></br>,'products'],imagen:ProdMedida},
            {key:4, nombre:'', titulo:['Private label',<br></br>],imagen:MarcaBlanca}
        ]
    };
    productos={
        es: [
            {key:1, nombre:['Cacahuate',<br></br>,'natural'], imagen:home_naturales, link: '/products#natural'},
            {key:2, nombre:['Ingredientes',<br></br>,'procesados'], imagen:home_ingredientes, link:'/products#procesados'},
            {key:3, nombre:['Botanas y',<br></br>,'snacks'], imagen:home_botanas, link:'/products#botanas'},
            {key:4, nombre:['Otros productos',<br></br>,'a granel'], imagen:home_otros, link:'/products#granel'},
        ],
        en: [
            {key:1, nombre:['Raw',<br></br>,'peanuts'], imagen:home_naturales, link: '/products#natural'},
            {key:2, nombre:['Processed',<br></br>,'ingredients'], imagen:home_ingredientes, link:'/products#procesados'},
            {key:3, nombre:['Snacks'], imagen:home_botanas, link:'/products#botanas'},
            {key:4, nombre:['Other bulk',<br></br>,'products'], imagen:home_otros, link:'/products#granel'},
        ]
    }
    ventajas={
        es: [
            {key:1, texto:'1', detalle:'Experiencia de más de 67 años en la industria del cacahuate'},
            {key:2, texto:'2', detalle:['Integración vertical (del campo hasta tu mesa)']},
            {key:3, texto:'3', detalle:'Infraestructura (contamos con almacenes y plantas en México y Estados Unidos) '},
            {key:4, texto:'4', detalle:['Productos hechos a la medida (Ingredientes, marcas privadas y maquilas)']},
            {key:5, texto:'5', detalle:'Investigación, desarrollo e innovación '},
            {key:6, texto:'6', detalle:'Sistema de aseguramiento de calidad a través de laboratorios equipados con tecnología de punta'},
            {key:7, texto:'7', detalle:'Sanitización efectiva y libre de químicos que aseguran la inocuidad de nuestros productos'},
            {key:8, texto:'8', detalle:'Certificaciones internacionales'}
        ],
        en:[
            {key:1, texto:'1', detalle:'Over 67 years of experience in peanut industry '},
            {key:2, texto:'2', detalle:['Vertical integrated', <br/>, '(from the farm to your table)']},
            {key:3, texto:'3', detalle:'Infrastructure (we have warehouses and processing plants in Mexico and United States) '},
            {key:4, texto:'4', detalle:['Custom made products', <br/>, '(ingredients, private labels, manufacturing)']},
            {key:5, texto:'5', detalle:'Innovation and R&D departments '},
            {key:6, texto:'6', detalle:'Quality assurance programs with fully equipped state of the art laboratories '},
            {key:7, texto:'7', detalle:'Effective sanitation process in order to secure a safe product  '},
            {key:8, texto:'8', detalle:'International certifications'}
        ]
    }


    render(){
        return( 
            <div className="Contenedor">
                <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', position:'relative', boxSizing:'border-box'}}>
                    <video  autoPlay muted loop style={{ width:'100%'}}  >
                        {window.innerWidth <= 711 && <source src={video_home_low} type="video/mp4" media="all and (max-width: 711px)" />}
                        {window.innerWidth > 711 && <source src={video_home_high} type="video/mp4"/>}
                    </video>
                    <div className="textoHeader" style={{position:'absolute', bottom:'15%', left:'50%', display:'flex', 'flex-direction':'column', transform:'translate(-50%,0)'}}>
                    <span className='texto-xl texto-harabara texto-white texto-bolder' style={{textShadow:'.2rem .2rem 2px #6c6c6c'}}>{dict[this.props.selectedLanguage].banner}</span></div>
                </div> 
                <div id="productos" style={{width:'100%', 'padding':'2.5rem 2rem', boxSizing:'border-box'}} >
                    <SoloTexto titulo={dict[this.props.selectedLanguage].productos_title} color='verde'/>
                    <Cuadricula productos={this.productos[this.props.selectedLanguage]} />      
                    <Link className="LinkButton"  style={{width:'40%', margin:'0 auto', height:'2rem', display: 'flex', alignItems:'center', justifyContent:'center'}} to="/products">{dict[this.props.selectedLanguage].todos_productos}</Link>                </div>
                <Cotizar selectedLanguage={this.props.selectedLanguage} />
                <BannerH  conceptos={this.servicios[this.props.selectedLanguage]} imagen={fondoservicios}>          
                    <SoloTexto titulo={dict[this.props.selectedLanguage].services_title} color='blanco' style={{paddingLeft:'2em', paddingTop: '2em'}}/>
                </BannerH>
                <div className="Banner2cols">
                    <SoloTexto titulo={dict[this.props.selectedLanguage].our_promise} texto={dict[this.props.selectedLanguage].promise_text} color='verde' alineacion="center"/>
                    <BannerPrincipal descripcion='' imagen={prodvarios}>
                        <div id="triangle-right" >
                        </div>
                    </BannerPrincipal>
                </div>
                <BarraOpciones textColor='white' altura="250px" alineacion="center" color='blanco' color2='blanco' imagen={ventajasbg} conceptos={this.ventajas[this.props.selectedLanguage]} classes="LinkVentajas" childComponent={SoloTexto} titulo={dict[this.props.selectedLanguage].ventajas_title}/>
                <div style={{margin:'4em auto', display:'flex', flexWrap:'wrap', flexDirection:'row', justifyContent:'space-around', width:'100%'}} >
                    {Object.keys(blogs[this.props.selectedLanguage]).reverse().map((blogKey) => {
                      const blogData = blogs[this.props.selectedLanguage][blogKey];
                      return <Blog selectedLanguage={this.props.selectedLanguage} post={blogKey} imagen={blogData.image} titulo={blogData.title + "..."} descripcion={blogData.desc + '...'} />
                    }).filter((element,index) => index < 4)}
                </div>
                <div className="Banner2cols" style={{boxSizing:'border-box', borderBottom:'10px solid #43B02A'}}>
                    <BannerPrincipal descripcion='' imagen={goldfritz} style={{backgroundPosition:'20%'}} >
                        <div id="triangle-left" >
                        </div>
                    </BannerPrincipal>
                    <SoloTexto titulo={[dict[this.props.selectedLanguage].botanas]} texto={dict[this.props.selectedLanguage].goldfritz} color='verde' alineacion="center">
                        <a className="LinkButton"  style={{ float:"center", paddingLeft:"25px", paddingRight:"25px", width:'30%', margin:'0 auto'}} target="_blank" rel="noreferrer noopener" href="https://www.goldfritz.com/">{dict[this.props.selectedLanguage].moreProducts}</a>
                    </SoloTexto>
                </div>
            </div>
        );
    }
}

export default Home;